import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import dayjs from 'dayjs'
import AuthContext from 'context/AuthContext'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import helpers from 'utils/helpers'

function Wallet () {
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const { t } = useTranslation()
  const [setEditShowModal] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [walletTransactions, setWalletTransactions] = useState([])
  const [page, setPage] = useState(1)
  const [transactionType, setTransactionType] = useState('')
  const [setItem] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [searchkey, setSearhkey] = useState('')
  const [userType, setUserType] = useState('user')
  const [isReset, setIsReset] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })
  // get all walletTransactions function-
  const getWalletTransactions = async () => {
    try {
      const payload = {
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        transaction_type: transactionType,
        userType,
        keyword: searchkey,
        page,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }
      const path = apiPath.getWalletTransactions
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        setWalletTransactions(response?.docs)
        setPaginationObj({
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('getWalletTransactions error :', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  const handelEdit = item => {
    setItem(item)
    setEditShowModal(true)
  }
  useEffect(() => {
    getWalletTransactions()
  }, [transactionType, page, searchkey, endDate, startDate, userType, sort])

  const handleReset = () => {
    setTransactionType('')
    setSearhkey('')
    setStartDate('')
    setEndDate('')
    setUserType('')
    setPage(1)
    setIsReset(true)
    setIsFilterActive(false)
    setSearchTerm('')
  }

  const handleDownload = async () => {
    try {
      const payload = {
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        transaction_type: transactionType,
        userType,
        keyword: searchkey,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }
      const path = apiPath.downloadWalletTransactions
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        helpers.downloadFile(result?.data?.results?.filePath)
      }
    } catch (error) {
      console.log('downloadWalletTransactions error :', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }


  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
    setIsReset(false)
  }
  const transactionTypeOptions = [
    {
      key: <>{t('WALLET_TO_WALLET_TRANSACTION')}</>,
      value: 'wallet_to_wallet'
    },
    {
      key: <>{t('O_WALLET_TO_BANK')}</>,
      value: 'wallet_to_bank'
    },
    {
      key: <>{t('UTILITIES_TRANSACTION')}</>,
      value: 'utilities_through_wallet'
    },
    {
      key: <>{t('ADD_MONEY_TO_WALLET_TRANSACTION')}</>,
      value: 'add_money_to_wallet'
    },
    {
      key: <>{t('CASH_IN')}</>,
      value: 'cash_in'
    },
    {
      key: <>{t('CASH_OUT')}</>,
      value: 'cash_out'
    },
    {
      key: <>{t('GIFT_CARD')}</>,
      value: 'gift_card'
    },
    {
      key: <>{t('REFERRAL_BONUS')}</>,
      value: 'referral_bonus'
    },
    {
      key: <>{t('SIGN_IN_BONUS')}</>,
      value: 'sign_in_bonus'
    },
    {
      key: <>{t('COMMISSION')}</>,
      value: 'commission'
    },
    {
      key: <>{t('SALARY_PAYMENT')}</>,
      value: 'salary_payment'
    },
    {
      key: <>{t('REFUND')}</>,
      value: 'refund'
    },
    {
      key: <>{t('PAYMENT_SETTLEMENT')}</>,
      value: 'payment_settlement'
    },
    {
      key: <>{t('SPLIT_BILL_PAYMENT')}</>,
      value: 'split_bil_payment'
    },
    {
      key: <>{t('ORDER')}</>,
      value: 'order'
    },
    {
      key: <>{t('INSTALLMENT_PAYMENT')}</>,
      value: 'installment_payment'
    },
    {
      key: <>{t('CASHBACK')}</>,
      value: 'cashback'
    },
    {
      key: <>{t('LOYALTY_POINT')}</>,
      value: 'loyaltyPoint'
    },
    {
      key: <>{t('INVOICE')}</>,
      value: 'invoice'
    },
    {
      key: <>{t('LOAN_AMOUNT')}</>,
      value: 'loanAmount'
    },
    {
      key: <>{t('cashToWallet')}</>,
      value: 'cashToWallet'
    }
  ]

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
    setIsFilterActive(true)
    setIsReset(false)
  }
  const statusPage = e => {
    setTransactionType(e.target.value)
    setPage(1)
    setIsFilterActive(true)
    setIsReset(false)
  }
  useEffect(() => {
    setPage(1)
    setSearhkey(debouncedSearchTerm)
    setIsFilterActive(true)
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    updatePageName(t('NAV_WALLET_MANAGER'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='col-span-2 flex flex-wrap  items-center mb-2 2xl:mb-0'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap '>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={isReset}
                    setIsReset={setIsReset}
                  />

                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 md:max-w-[200px]  peer'
                      placeholder=' '
                      value={transactionType}
                      onChange={e => statusPage(e)}
                    >
                      <option value=''>{t('O_SELECT_TRANSACTION_TYPE')}</option>
                      {transactionTypeOptions.map(({ key, value }) => (
                        <option key={key} value={value}>
                          {key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-6 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                  <button
                    type='button'
                    onClick={handleDownload}
                    className='bg-LightBlue text-sm px-6 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_DOWNLOAD')}
                  </button>
                </div>
              </div>
              <div className='flex items-center sm:mb-0'>
                <div className='flex'>
                  <div className='relative '>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      id='default-search'
                      className='block  p-2 pr-6 text-sm outline-none text-gray-900 2xl:min-w-[150px] xl:min-w-[210px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('O_SEARCH_BY_NAME')}
                      required
                      title=''
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <Table
              walletTransactions={walletTransactions}
              getWalletTransactions={getWalletTransactions}
              handelEdit={handelEdit}
              page={paginationObj.page}
              setSort={setSort}
              sort={sort}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isFilterActive={isFilterActive}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wallet
