import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { startCase } from 'lodash'
import classNames from 'classnames'
const View = ({ request, setShowModal }) => {
  const { t } = useTranslation()

  const downloadImage = image => {
    saveAs(image)
  }

  return (
    <>
      <div
        id='defaultModal'
        tabindex='-1'
        aria-hidden='true'
        className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center flex'
      >
        <div className='relative w-full h-full max-w-[1200px] flex items-center justify-center'>
          <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
            <div className='flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 items-center'>
              <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
                {t('VIEW')}
              </h3>
              <h4
                className={classNames('ml-auto pr-4', {
                  'text-yellow-600': request?.status === 'pending',
                  'text-red-600': request?.status === 'cancelled',
                  'text-green-600': request?.status === 'approved'
                })}
              >
                {startCase(request?.status)}
              </h4>
              <button
                type='button'
                className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-hide='defaultModal'
                onClick={() => setShowModal(false)}
                title={t('CLOSE')}
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span className='sr-only'>{t('CLOSE_MODAL')}</span>
              </button>
            </div>

            <div className='p-6 space-y-6'>
              <div className='grid grid-cols-4'>
                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0 w-full group'>
                    <input
                      type='text'
                      id='text'
                      disabled
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      value={request?.firstName + ' ' + request?.lastName}
                    />
                    <label
                      htmlFor='mobile'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('ORDER_CUSTOMER_NAME')}
                    </label>
                  </div>
                </div>

                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0 w-full group'>
                    <input
                      type='text'
                      id='mobile'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={`+${request?.countryCode} ${request?.mobile}`}
                    />
                    <label
                      htmlFor='mobile'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_MOBILE')}
                    </label>
                  </div>
                </div>

                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                      placeholder=' '
                      maxLength={40}
                      disabled
                      value={request?.email}
                    />
                    <label
                      for='email'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_EMAIL_ID')}
                    </label>
                  </div>
                </div>

                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.monthlySalary}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('SALARY_MONTH')}
                    </label>
                  </div>
                </div>

                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.maxLoanAmount}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('MAXIMUM_AMOUNT')}
                    </label>
                  </div>
                </div>

                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.loanAmount}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('LONE_AMOUNT')}
                    </label>
                  </div>
                </div>
                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.loanCreditTitle}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('MICRO_LOAN')}
                    </label>
                  </div>
                </div>
                <div className='md:py-2 sm:px-2 sm:py-4 px-7'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.tenure}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('TENURE')}
                    </label>
                  </div>
                </div>
              </div>

              <div className='grid grid-rows-1 grid-flow-col mt-0 '>
                <div className='md:py-2 sm:px-2 sm:py-4 px-7 col-span-1'>
                  <div className='relative z-0   w-full group'>
                    <input
                      type='text'
                      name='first_name'
                      id='first_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      disabled
                      value={request?.interestRate}
                    />
                    <label
                      for='first_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('MICRO_INTEREST_RATE')}
                    </label>
                  </div>
                </div>

                <div className='md:py-4 sm:px-2 sm:py-8 px-7 col-span-9 flex items-center '>
                  <div class='mr-10'>
                    <label className='mb-2 block'> {t('FRONT_ID')}</label>

                    <div className='flex items-center'>
                      <img
                        src={request?.frontIdImage}
                        alt=''
                        className='w-16 h-16 rounded-full'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src =
                            './images/pngtree-id-card-line-filled-icon-png-image_325456.jpg'
                        }}
                      />
                      <button
                        onClick={() => downloadImage(request?.frontIdImage)}
                      >
                        <img
                          src='./images/download.png'
                          alt=''
                          className='w-6'
                        />
                      </button>
                    </div>
                  </div>

                  <div class='mr-10'>
                    <label className='mb-2 block'>{t('BACK_ID')}</label>

                    <div className='flex items-center'>
                      <img
                        src={request?.backIdImage}
                        alt=''
                        className='w-16 h-16 rounded-full'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src =
                            './images/pngtree-id-card-line-filled-icon-png-image_325456.jpg'
                        }}
                      />
                      <button
                        onClick={() => downloadImage(request?.backIdImage)}
                      >
                        <img
                          src='./images/download.png'
                          alt=''
                          className='w-6'
                        />
                      </button>
                    </div>
                  </div>
                  <div class=''>
                    <label className='mb-2 block'>{t('SALARY_RECEIPT')}</label>

                    <div className='flex items-center'>
                      <img
                        src={request?.salaryReceipt}
                        alt=''
                        className='w-16 h-16 rounded-full'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src =
                            './images/pngtree-id-card-line-filled-icon-png-image_325456.jpg'
                        }}
                      />
                      <button
                        onClick={() => downloadImage(request?.salaryReceipt)}
                      >
                        <img
                          src='./images/download.png'
                          alt=''
                          className='w-6'
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class='grid grid-cols-2'>
                <div className='p-5'>
                  <h2 class='font-bold leading-tight text-xl mt-0 mb-2 '>
                    {t('MICRO_REQUEST_INSTRUCTION')}{' '}
                    <span className='text-base font-light pl-3'>
                      {t('MICRO_REQUEST_DETAILS')}.
                    </span>
                  </h2>
                  <ol className='list-decimal mt-4 pl-4 leading-6'>
                    <li>
                      {t('MICRO_REQUEST_PROCESSING_FEE')}{' '}
                      {request?.processingFees} {t('O_LEK')}
                    </li>
                    <li>
                      {t('MICRO_REQUEST_LOAN_AMOUNT')}{' '}
                      {request?.finalLoanAmount} {t('O_LEK')}
                    </li>
                    <li>
                      {t('MICRO_REQUEST_LOAN_TENURE')}: {request?.tenure} days
                    </li>
                    <li>
                      {t('MICRO_REQUEST_INTEREST_RATE')}:{' '}
                      {request?.interestRate}%
                    </li>
                    <li>
                      {t('MICRO_REQUEST_REPAYABLE_AMOUNT')}:{' '}
                      {request?.repayableAmount} {t('O_LEK')}
                    </li>
                  </ol>
                </div>

                <div class='relative overflow-x-auto mt-4 mw-[800px] max-h-56'>
                  <table class='w-full text-sm text-left text-gray-500 dark:text-gray-400 border-2'>
                    <thead class='text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                      <tr>
                        <th scope='col' class='px-6 py-3'>
                          S.No.
                        </th>
                        <th scope='col' class='px-6 py-3'>
                          {t('INSTALMENT_AMOUNT')}
                        </th>
                        <th scope='col' class='px-6 py-3'>
                          {t('DUE_DATE')}
                        </th>
                        <th scope='col' class='px-6 py-3'>
                          {t('O_STATUS')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {request &&
                        request?.installments.map((item, i) => (
                          // <>
                          <tr class='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                            <th
                              scope='row'
                              class='px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                              width='5%'
                            >
                              {i + 1}
                            </th>
                            <td class='px-6 py-3' width='30%'>
                              {item?.installment.toFixed(2)} {t('O_LEK')}
                            </td>
                            <td class='px-6 py-3' width='30%'>
                              {dayjs(item?.dueDate).format(
                                'DD-MM-YYYY hh:mm A'
                              )}
                            </td>
                            <td class='px-6 py-3'>
                              {item?.status === 'due' ? (
                                <span
                                  style={{ fontSize: '15px', color: 'red' }}
                                >
                                  {startCase(item?.status)}
                                </span>
                              ) : (
                                <span
                                  style={{ fontSize: '15px', color: 'green' }}
                                >
                                  {startCase(item?.status)}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                type='button'
                onClick={() => setShowModal(false)}
              >
                {t('O_BACK')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  )
}

export default View
