import React, { Fragment, useContext } from 'react'
import { Transition, Menu } from '@headlessui/react'
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom'
import OImage from './reusable/OImage'
import { useTranslation } from 'react-i18next'
import helper from '../utils/helpers'

const TopNavBar = () => {
  const {
    logoutUser,
    user,
    handleSidebar,
    pageName,
    updatePageName,
    language,
    setLanguage
  } = useContext(AuthContext)
  const { t, i18n } = useTranslation()

  const changeLanguageHandler = eventKey => {
    setLanguage(eventKey)
    if (eventKey == 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    if (eventKey == 1) {
      i18n.changeLanguage('en')
    } else if (eventKey == 2) {
      i18n.changeLanguage('al')
    }
  }

  const handleLogout = () => {
    logoutUser()
  }

  return (
    <div className='linHeader border-b py-4 px-4 md:px-8'>
      <header className='bg-white items-center flex'>
        <div className='mr-4' onClick={() => handleSidebar()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
            />
          </svg>
        </div>

        <div className='page_heading w-full'>
          <h1 className='flex items-center text-lg text-slate-900 font-medium'>
            {pageName}
          </h1>
        </div>
        <div className='flex items-center w-full'>
          <Menu
            as='div'
            className='relative  flex items-center justify-end mr-3 z-10 ml-auto'
          >
            <Menu.Button className=''>
              <img
                src={
                  language == 1
                    ? './images/usFlag.svg'
                    : './images/albaniaFlag.svg'
                }
                className='h-[26px] w-[26px] rounded-full'
                alt=''
              />
            </Menu.Button>
            <Menu.Button className='flex items-center block pl-3'>
              {language == 1 ? 'En' : 'Al'}
            </Menu.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 top-[40px] w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='px-1 py-1 '>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(1)}
                      >
                        <img
                          src='./images/usFlag.svg'
                          alt=''
                          className='mr-2 h-[26px] w-[26px] rounded-full'
                        />{' '}
                        En
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item className='py-2 flex items-center w-full hover:bg-gray-100'>
                    {({ active }) => (
                      <button
                        className='flex items-center'
                        onClick={() => changeLanguageHandler(2)}
                      >
                        <img
                          src='./images/albaniaFlag.svg'
                          alt=''
                          className='mr-2 rounded-full'
                        />{' '}
                        Al
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu
            as='div'
            className='relative inline-block text-left hover:text-LightBlue sidebar_dropdown'
          >
            <div>
              <Menu.Button className='flex relative items-center w-full text-white active:text-LightBlue hover:bg-white hover:text-LightBlue group'>
                <div className='profile flex items-center ml-auto md:mr-10 mr-2'>
                  <figure className='inline-block overflow-hidden rounded-full'>
                    <OImage
                      src={user?.profile_pic}
                      fallbackUrl='/images/user.png'
                      className='w-[40px] h-[40px] inline'
                      alt=''
                    />
                  </figure>
                  <small className='block text-sm text-slate-900 ml-2 md:block hidden'>
                    {user?.first_name} {user?.last_name}
                  </small>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-10'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute z-10 text-left min-w-[200px]  right-2 content divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className=''>
                  <Menu.Item onClick={() => updatePageName(t('EDIT_PROFILE'))}>
                    {({ active }) => (
                      <Link
                        to='/profile'
                        className={`${
                          active ? 'bg-violet-500 text-white' : 'text-gray-900'
                        } group flex w-full items-center  px-6 py-2 text-sm`}
                      >
                        {t('EDIT_PROFILE')}
                      </Link>
                    )}
                  </Menu.Item>
                  {(user?.permission?.[13]?.view || user?.role === 'admin') && (
                    <Menu.Item
                      onClick={() => updatePageName(t('NAV_SETTINGS'))}
                    >
                      {({ active }) => (
                        <Link
                          to='/settings'
                          className={`${
                            active
                              ? 'bg-violet-500 text-white'
                              : 'text-gray-900'
                          } group flex w-full items-center  px-6 py-2 text-sm`}
                        >
                          {t('NAV_SETTINGS')}
                        </Link>
                      )}
                    </Menu.Item>
                  )}

                  <Menu.Item
                    onClick={() => updatePageName(t('PROFILE_CHANGE_PASSWORD'))}
                  >
                    {({ active }) => (
                      <Link
                        to='/change-password'
                        className={`${
                          active ? 'bg-violet-500 text-white' : 'text-gray-900'
                        } group flex w-full items-center  px-6 py-2 text-sm`}
                      >
                        {t('PROFILE_CHANGE_PASSWORD')}
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className=''>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          helper.alertFunction(
                            `${t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}`,
                            '',
                            handleLogout,
                            '',
                            language
                          )
                        }
                        className={`${
                          active ? 'bg-violet-500 text-white' : 'text-gray-900'
                        } group flex w-full items-center  px-6 py-2 text-sm`}
                      >
                        {t('NAV_LOGOUT')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
    </div>
  )
}

export default TopNavBar
