import React, { createContext, useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { pick } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { apiPost } from '../utils/apiFetch'
import apiPath from '../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation()
  let [user, setUser] = useState(() =>
    localStorage.getItem('token')
      ? jwt_decode(localStorage.getItem('token'))
      : null
  )
  const [sidebarStatus, setSidebarStatus] = useState(
    localStorage.getItem('sidebar')
  )
  const [pageName, setPageName] = useState(
    localStorage.getItem('pageName')
      ? localStorage.getItem('pageName')
      : 'Dashboard'
  )
  const navigate = useNavigate()
  const notification = useToastContext()
  const [loginMessage, setLoginMessage] = useState(
    'Admin details updated successfully.'
  )
  const [lastActivity, setLastActivity] = useState(new Date())
  const [language, setLanguage] = useState(
    localStorage.getItem('language') === 'en' ? 1 : 2
  )
  const updatePageName = name => {
    localStorage.setItem('pageName', name)
    setPageName(name)
  }

  let loginUser = async body => {
    document.getElementById('loader').classList.remove('hidden')
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ['username', 'password'])
    )

    if (status === 200) {
      if (data.success) {
        const token = data?.results?.token || null
        const refresh_token = data?.results?.refresh_token || null
        localStorage.setItem('token', token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('pageName', 'Dashboard')
        setPageName('Dashboard')
        setUser(jwt_decode(token))
        navigate('/dashboard')
      } else {
        notification.error(data.message)
      }
    }
    document.getElementById('loader').classList.add('hidden')
  }

  const updateProfile = async formData => {
    try {
      const res = await apiPost(apiPath.editProfile, formData)
      const token = res?.data?.results?.token || null
      const refresh_token = res?.data?.results?.refresh_token || null
      localStorage.setItem('token', token)
      setLoginMessage(res?.data?.message)
      notification.success(res?.data?.message)
      localStorage.setItem('refresh_token', refresh_token)
      setUser(jwt_decode(token))
    } catch (err) {
      console.log('err:', err)
    }
  }

  const handleSidebar = () => {
    let s1 = localStorage.getItem('sidebar')
    localStorage.setItem('sidebar', s1 == 'open' ? 'close' : 'open')
    setSidebarStatus(s1 == 'open' ? 'close' : 'open')
  }

  let logoutUser = () => {
    setUser(null)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    navigate('/login')
  }

  useEffect(() => {
    if (localStorage.getItem('language') === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('al')
    }
  }, [])

  let contextData = {
    user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    updateProfile: updateProfile,
    sidebarStatus: sidebarStatus,
    handleSidebar,
    pageName,
    updatePageName,
    loginMessage: loginMessage,
    language,
    setLanguage,
    setLastActivity,
    lastActivity
  }
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}
