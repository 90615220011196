import React, { useEffect, useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import dayjs from 'dayjs'
import Pagination from 'pages/Pagination'
import { isEmpty } from 'lodash'

const ViewAgentContent = ({ handleOpen, merchantId }) => {
  const [agents, setAgents] = useState(null)

  const [page, setPage] = useState(1)

  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const [searchKey, setSearchKey] = useState('')
  const size = 10

  const [isDelete] = useState(false)
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const getAgents = async () => {
    try {
      let params = {
        page,
        size,
        keyword: searchKey,
        merchantId: merchantId
      }
      const res = await apiGet(pathObj.getAgents, params)
      setAgents(res?.data?.results?.docs)
      if (res?.data?.results?.docs?.length > 0) {
        setPaginationObj({
          ...paginationObj,
          page: res?.data?.results?.page,
          pageCount: res?.data?.results?.totalPages,
          perPageItem: res?.data?.results?.docs.length,
          totalItems: res?.data?.results?.totalDocs
        })
      } else {
        setPaginationObj({})
      }
    } catch (error) {
      console.error('error:', error)
      setPaginationObj({})
    }
  }

  useEffect(() => {
    getAgents()
  }, [page, searchKey])

  const handleSearchKey = e => {
    setSearchKey(e.target.value)
    setPage(1)
  }

  const { t } = useTranslation()
  return (
    <>
      <div className='p-3 pt-0'>
        <div className='flex justify-end mb-4 '>
          <div className='relative w-60 '>
            <div
              className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'
              style={{ transform: 'translateY(-2px)' }}
            >
              {!searchKey && (
                <svg
                  aria-hidden='true'
                  className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                  />
                </svg>
              )}
            </div>
            <input
              type='search'
              className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[200px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              placeholder={t('O_SEARCH_BY_NAME')}
              value={searchKey}
              title=''
              required
              onChange={e => handleSearchKey(e)}
            />
          </div>
        </div>
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('AGENT_FULL_NAME')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('O_EMAIL_ID')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('O_MOBILE_NUMBER')} (+355)
                </th>

                <th scope='col' className='py-3 px-6'>
                  {t('MERCHANT_DATE_OF_REGISTRATION')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('O_STATUS')}
                </th>
              </tr>
            </thead>
            <tbody>
              {agents?.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  >
                    <th
                      scope='row'
                      className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                    >
                      {i + 1 + 10 * (page - 1)}
                    </th>
                    <td className='py-4 px-6 border-r capitalize'>
                      {item?.full_name}
                    </td>
                    <td className='py-4 px-6 border-r'>{item?.email}</td>
                    <td className='py-4 px-6 border-r'>{item?.mobile}</td>

                    <td className='py-4 px-6 border-r'>
                      {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                    </td>
                    <td className='py-4 px-6 border-r'>
                      {item?.status === 'active' ? (
                        <span className='text-blue-600'> Active </span>
                      ) : (
                        <span className='text-orange-600'>Inactive</span>
                      )}
                    </td>
                  </tr>
                )
              })}

              {isEmpty(agents) ? (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={6}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>

          {paginationObj?.totalItems ? (
            <Pagination
              handlePageClick={handlePageClick}
              options={paginationObj}
              isDelete={isDelete}
              page={page}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

const ViewAgentTrigger = ({ handleOpen }) => {
  const { t } = useTranslation()
  return (
    <a title={t('O_VIEW_AGENTS')} onClick={handleOpen}>
      {' '}
      <FaUsers className='cursor-pointer w-5 h-5 text-slate-600' />
    </a>
  )
}

export { ViewAgentTrigger, ViewAgentContent }
