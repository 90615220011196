import React, {useContext} from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { AiFillEdit } from 'react-icons/ai'
import AuthContext from 'context/AuthContext'

const Table = ({ microLabs, handelEdit }) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('MAX_SALARY')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('MAX_LOAN_AMOUNT')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {microLabs &&
              microLabs?.map((item, i) => (

                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1}
                  </th>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.maxSalary}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.maxLoanAmount}
                  </td>
                  <td className='py-4 px-6 border-r text-center'>
                  {(user?.permission?.[12]?.add ||
                    user?.permission?.length === 0) && (<button className="mr-5" onClick={() =>
                        handelEdit(item)}>
                      <AiFillEdit title={t('EDIT_SLAB')} className='w-5 h-5 text-slate-600' />
                    </button>)}
                  </td>
                </tr>

              ))}
            {isEmpty(microLabs)
              ? (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={6}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
                )
              : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
