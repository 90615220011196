import React, { useContext, useEffect, useState } from 'react'
import Table from './Table'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import AuthContext from 'context/AuthContext'
import EditMicroCreditLoanSlabs from './EditMircoCreditLoadSlabs'
import { useTranslation } from 'react-i18next'

function ManageMicroCreditLoanSlabs () {
  const { t } = useTranslation()
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [microLabs, setMicroLabs] = useState([])
  const [editShowModal, setEditShowModal] = useState(false)
  const [item, setItem] = useState('')

  const getMicroCreditLoanSlabs = async payload => {
    let result
    try {
      const path = apiPath.getMicroCreditLoanSlabs
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setMicroLabs(response?.docs)
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)

      if (error.response.data.message === 'Unauthenticated.') {
        logoutUser()
      }
    }
  }

  const handelEdit = item => {
    setItem(item)
    setEditShowModal(true)
  }

  useEffect(() => {
    getMicroCreditLoanSlabs()
  }, [])

  useEffect(() => {
    updatePageName(t('NAV_MANAGE_MICRO_CREDIT_LOAN_SLABS'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            <Table microLabs={microLabs} handelEdit={handelEdit} />
          </div>
        </div>
      </div>
      {editShowModal ? (
        <EditMicroCreditLoanSlabs
          setEditShowModal={setEditShowModal}
          getMicroCreditLoanSlabs={getMicroCreditLoanSlabs}
          item={item}
        />
      ) : null}
    </div>
  )
}

export default ManageMicroCreditLoanSlabs
