import React from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import ErrorMessage from 'components/ErrorMessage'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'

const SubCategoryView = ({ allSubCategory, currentItem, handleView }) => {
  const notification = useToastContext()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      name: currentItem?.name,
      nameAl: currentItem?.nameAl,
      category_id: currentItem?.category_id?.name
    }
  })

  const onSubmit = async data => {
    try {
      var path = apiPath.subcategoryUpdate + '/' + currentItem._id
      const result = await apiPut(path, data)
      if (result.data.success) {
        allSubCategory()
        handleView(false)
        notification.success(result?.data.message)
      } else {
        notification.error(result?.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error)
      notification.error(error.message)
    }
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          {/*content*/}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='md:py-4 sm:px-2 sm:py-8 px-7'
          >
            <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
                {/*header*/}
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-xl font-semibold'>
                    {t('SUB_VIEW_CATEGORY')}
                  </h3>
                  <button
                    className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                    onClick={() => handleView(false)}
                  >
                    <span
                      className=' text-[#B8BBBF]  text-4xl '
                      onClick={() => handleView()}
                      title={t('CLOSE')}
                    >
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <div className='grid sm:grid-cols-2'>
                    <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                      <div className='relative z-0  w-full group'>
                        <input
                          type='text'
                          name='category_id'
                          id='name'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=''
                          disabled
                          maxLength={20}
                          {...register('category_id', {
                            required: 'Please enter categoryId.'
                          })}
                        />
                        <label
                          for='name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('OFFERS_CATEGORY')}
                        </label>
                        <ErrorMessage message={errors?.category_id?.message} />
                      </div>
                    </div>
                    <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                      <div className='relative z-0   w-full group'>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=''
                          disabled
                          maxLength={20}
                          {...register('name', {
                            required: 'Please enter name.'
                          })}
                        />
                        <label
                          for='name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('SUB_CATEGORY')}
                        </label>
                        <ErrorMessage message={errors?.first_name?.message} />
                      </div>
                    </div>
                    <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type='text'
                        name='nameAl'
                        id='nameAl'
                        inputLabel={
                          <>
                            {t('SUB_CATEGORY')} {t('ALBANIAN')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        placeholder=''
                        maxLength={15}
                        register={register('nameAl', {})}
                        errors={errors}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => handleView()}
                  >
                    {t('O_BACK')}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  )
}

export default SubCategoryView
