const Permission = [
  {
    manager: 'users',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'wallet_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'transaction_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'category_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'subcategory_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'subadmin_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'merchant_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'manage_offers',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'earning_manager',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'logs_managers',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'manage_micro_credit_request',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'manage_micro_credit_loan_slabs',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'micro_credit_request_settings',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'manage_orders',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'reports',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: false,
    shownDelete: false,
    shownAll: false
  },
  {
    manager: 'static_content',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: true,
    shownAll: true
  },
  {
    manager: 'settings',
    add: false,
    view: false,
    delete: false,
    shownView: true,
    shownEdit: true,
    shownDelete: false,
    shownAll: false
  }
]

export default Permission
