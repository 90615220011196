import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
function ODatePicker ({ handleDateChange, value }) {
  const [date, setDate] = useState(value || '')

  const onChange = ([date]) => {
    setDate(date)
    handleDateChange(date)
  }
  return (
    <Flatpickr
      className='bg-calendar bg-[right_10px_top_20px] bg-[length:15px_15px] bg-no-repeat block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
      name='date'
      placeholder=' '
      options={{
        minDate: 'today',
        dateFormat: 'd-m-Y',
        defaultDate: date
      }}
      onChange={onChange}
    />
  )
}

export default ODatePicker
