import './App.css'
import './assets/css/style.css'
import 'react-quill/dist/quill.snow.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AuthProvider } from './context/AuthContext'
import { ToastContextProvider } from './context/ToastContext'
import AuthorizationRoute from './utils/AuthorizationRoute'
import Login from './pages/Login'
import Home from './pages/Home'
import User from './pages/users/User'
import Wallet from './pages/wallet/Wallet'
import SubAdmin from './pages/sub_admin/SubAdmin'
import Merchant from './pages/merchants/Merchant'
import Operator from './pages/operators/Operator'
import Offers from './pages/offers_manager/Offers'
import TransactionManager from './pages/transaction_manager/TransactionManager'
import Settings from './pages/settings/Settings'

import Faq from './pages/static_content/FAQ'
import AboutUs from './pages/static_content/AboutUs'
import Careers from './pages/static_content/Careers'
import ContactUs from './pages/static_content/ContactUs'
import PrivatePolicy from './pages/static_content/PrivatePolicy'
import TermsAndConditions from './pages/static_content/TermsAndConditions'
import Profile from 'pages/profile/Profile'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import ChangePassword from 'pages/auth/ChangePassword'
import EarningManager from 'pages/earning_manager/EarningManager'
import LogsManagement from 'pages/logs_management/LogsManagement'
import ManageMicroCredit from 'pages/manage_micro_credit/ManageMicroCredit'
import MicroCreditRequestSetting from 'pages/micro_credit_request_setting/MicroCreditRequestSetting'
import ManageOrders from 'pages/manage_orders/ManageOrders'
import Reports from 'pages/reports/Reports'
import SharedLayout from 'utils/SharedLayout'
import ProtectedRoute from 'utils/ProtectedRoute'
import ManageMicroCreditLoanSlabs from 'pages/manage_micro_credit_loan_slabs/ManageMicroCreditLoanSlabs'
import Category from 'pages/category_manager/Category'
import SubCategory from 'pages/subcategory_manager/SubCategory'
import NotFound from 'pages/NotFound'
import Aml from 'pages/aml/aml'

function App () {
  return (
    <BrowserRouter>
      <ToastContextProvider>
        <AuthProvider>
          <Routes>
            <Route element={<SharedLayout />}>
              {/* <Route
                exact
                path="/"
                element={
                  <ProtectedRoute>
                  </ProtectedRoute>
                }
              ></Route> */}

              <Route exact path='/dashboard' element={<Home />} />

              <Route
                exact
                path='/users'
                element={
                  <AuthorizationRoute>
                    <User />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/sub-admin-manager'
                element={
                  <AuthorizationRoute>
                    <SubAdmin />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/wallet-manager'
                element={
                  <AuthorizationRoute>
                    <Wallet />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/faqs'
                element={
                  <AuthorizationRoute>
                    <Faq />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/careers'
                element={
                  <AuthorizationRoute>
                    <Careers />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/about-us'
                element={
                  <AuthorizationRoute>
                    <AboutUs />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/contact-us'
                element={
                  <AuthorizationRoute>
                    <ContactUs />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/terms-and-conditions'
                element={
                  <AuthorizationRoute>
                    <TermsAndConditions />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static/privacy-policy'
                element={
                  <AuthorizationRoute>
                    <PrivatePolicy />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/merchants'
                element={
                  <AuthorizationRoute>
                    <Merchant />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/operators'
                element={
                  <AuthorizationRoute>
                    <Operator />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/settings'
                element={
                  <AuthorizationRoute>
                    <Settings />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/transaction-manager'
                element={
                  <AuthorizationRoute>
                    <TransactionManager />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/categories'
                element={
                  <AuthorizationRoute>
                    <Category />
                  </AuthorizationRoute>
                }
                // name='Category manager'
              />

              <Route
                exact
                path='/subCategories'
                element={
                  <AuthorizationRoute>
                    <SubCategory />
                  </AuthorizationRoute>
                }
                // name='Subcategory manager'
              />

              <Route
                exact
                path='/profile'
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path='/offers'
                element={
                  <AuthorizationRoute>
                    <Offers />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/earning-managers'
                element={
                  <AuthorizationRoute>
                    <EarningManager />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/categories'
                element={
                  <AuthorizationRoute>
                    <Category />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/subCategories'
                element={
                  <AuthorizationRoute>
                    <SubCategory />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/logs-management'
                element={
                  <AuthorizationRoute>
                    <LogsManagement />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/manage-micro-credit-request'
                element={
                  <AuthorizationRoute>
                    <ManageMicroCredit />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/micro-credit-request-setting'
                element={
                  <AuthorizationRoute>
                    <MicroCreditRequestSetting />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/manage-orders'
                element={
                  <AuthorizationRoute>
                    <ManageOrders />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/reports'
                element={
                  <AuthorizationRoute>
                    <Reports />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/change-password'
                element={
                  <ProtectedRoute>
                    <ChangePassword />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path='/aml'
                element={
                  <AuthorizationRoute>
                    <Aml />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/manage-micro-credit-loan-slabs'
                element={
                  <AuthorizationRoute>
                    <ManageMicroCreditLoanSlabs />
                  </AuthorizationRoute>
                }
              />
            </Route>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/login' element={<Login />} />

            <Route exact path='/forgot-password' element={<ForgotPassword />} />

            <Route
              exact
              path='/reset-password/:resetToken'
              element={<ResetPassword />}
            />
            <Route exact path='*' element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </ToastContextProvider>
    </BrowserRouter>
  )
}

export default App
