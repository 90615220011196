export const permissionsMap = {
  // dashboard: '/dashboard',
  users: '/users',
  wallet_manager: '/wallet-manager',
  transaction_manager: 'transaction-manager',
  category_manager: '/categories',
  subcategory_manager: 'subCategories',
  subadmin_manager: '/sub-admin-manager',
  merchant_manager: '/merchants',
  manage_offers: '/offers',
  earning_manager: 'earning-managers',
  logs_managers: 'logs-management',
  manage_micro_credit_request: 'manage-micro-credit-request',
  manage_micro_credit_loan_slabs: 'manage-micro-credit-loan-slabs',
  micro_credit_request_settings: 'micro-credit-request-setting',
  manage_orders: 'manage-orders',
  reports: 'reports',
  static_content: '/static',
  settings: '/settings'
}
