import React, { useState } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import formValidation from '../../utils/formValidation'
import { preventMaxInput } from 'utils/validations'

const EditMicroCreditLoanSlabs = ({
  setEditShowModal,
  getMicroCreditLoanSlabs,
  item
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      maxSalary: item?.maxSalary,
      maxLoanAmount: item?.maxLoanAmount
    }
  })

  const [editMicroCreditLoanSlabLoading, setEditMicroCreditLoanSlabLoading] =
    useState(false)

  const handleSubmitForm = async data => {
    try {
      setEditMicroCreditLoanSlabLoading(true)
      const sendData = {
        maxSalary: data?.maxSalary,
        maxLoanAmount: data?.maxLoanAmount
      }

      const res = await apiPut(
        apiPath.getMicroCreditLoanSlabs + '/' + item?._id,
        sendData
      )
      if (res.data.success === true) {
        setEditShowModal(false)
        notification.success(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setEditMicroCreditLoanSlabLoading(false)
      getMicroCreditLoanSlabs()
    }
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          {/* content */}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
            {/* header */}
            <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-xl font-semibold'>{t('SLABS_EDIT_SLAB')}</h3>
              <button
                className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                onClick={() => setEditShowModal(false)}
              >
                <span className=' text-[#B8BBBF]  text-4xl ' title={t('CLOSE')}>
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className='relative p-6 flex-auto'>
              <div className='grid grid-cols-2'>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <OInputField
                    wrapperClassName='relative z-0   w-full group'
                    name='maxSalary'
                    inputLabel={
                      <>
                        {t('MAX_SALARY')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    type='number'
                    maxLength={6}
                    onKeyDown={e => {
                      if (['-', '+', 'e'].includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    onInput={e => preventMaxInput(e, 6)}
                    register={register(
                      'maxSalary',
                      formValidation['maxSalary']
                    )}
                    errors={errors}
                  />
                </div>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <OInputField
                    wrapperClassName='relative z-0   w-full group'
                    name='maxLoanAmount'
                    inputLabel={
                      <>
                        {t('MAXIMUM_AMOUNT')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    type='number'
                    maxLength={6}
                    onKeyDown={e => {
                      if (['-', '+', 'e'].includes(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    onInput={e => preventMaxInput(e, 6)}
                    register={register(
                      'maxLoanAmount',
                      formValidation['maxLoanAmount']
                    )}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
            {/* footer */}
            <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                type='button'
                onClick={() => setEditShowModal(false)}
              >
                {t('O_BACK')}
              </button>
              {editMicroCreditLoanSlabLoading ? (
                <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                  <div className='loading-spinner'></div>
                </div>
              ) : (
                <button
                  className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                  type='submit'
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  {t('O_EDIT')}
                </button>
              )}
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default EditMicroCreditLoanSlabs
