import MainLayout from 'layout/MainLayout'
import React from 'react'
import { Outlet } from 'react-router-dom'

const SharedLayout = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  )
}

export default SharedLayout
