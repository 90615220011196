import React, { useContext } from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { AiFillDelete, AiFillEye } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import {
  BsArrowUpShort,
  BsHandThumbsDown,
  BsHandThumbsUp
} from 'react-icons/bs'
import helper from '../../utils/helpers'
import classNames from 'classnames'
import AuthContext from 'context/AuthContext'

const Table = ({
  users,
  getAllUser,
  handleUserView,
  user,
  page,
  sort,
  setSort,
  setPage
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const { language } = useContext(AuthContext)
  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.changeUserStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllUser({ statusChange: 1 })
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }
  const handleAcceptReject = async item => {
    try {
      const payload = {
        isApprovedStatus: item?.status
      }
      const path = `${apiPath.userApproval}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        setPage(1)
        getAllUser()
      }
    } catch (error) {
      console.error('error in get all sub admin list==>>>>', error.message)
    }
  }
  const handelDelete = async item => {
    try {
      const path = apiPath.getUsers + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        notification.success(result.data.message)
        setPage(1)
        getAllUser()
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'first_name' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'first_name',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'first_name',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex '>
                  <span>{t('USERS_FULL_NAME')} </span>
                  <span>
                    {sort.sort_key === 'first_name' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'first_name' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (sort.sort_key === 'email' && sort.sort_type === 'asc') {
                    setSort({
                      sort_key: 'email',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'email',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-left'>
                  <span>{t('O_EMAIL_ID')}</span>
                  <span>
                    {sort.sort_key === 'email' && sort.sort_type === 'asc' && (
                      <BsArrowUpShort className='w-4 h-4' />
                    )}
                    {sort.sort_key === 'email' && sort.sort_type === 'desc' && (
                      <BsArrowUpShort className='w-4 h-4 rotate-180' />
                    )}
                  </span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div className='text-left'>{t('O_MOBILE_NUMBER')} (+355)</div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('USERS_REFERRAL_CODE')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('USER_TYPE')}
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')}</span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
              {(user?.permission?.[1]?.add ||
                user?.permission?.length === 0) && (
                <>
                  <th scope='col' className='py-3 px-6 text-left'>
                    {t('O_STATUS')}
                  </th>
                  <th scope='col' className='py-3 px-6 text-left'>
                    {t('APPROVED_STATUS')}
                  </th>
                </>
              )}
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>
                    {`${item?.first_name} ${item?.last_name}`}{' '}
                  </td>
                  <td className='py-4 px-6 border-r'>{item?.email}</td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.mobile}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>
                    {item?.referral_code}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>
                    {startCase(item?.userType)}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  {(user?.permission?.[1]?.add ||
                    user?.permission?.length === 0) && (
                    <>
                      <td className='py-4 px-6 border-r text-center'>
                        <label
                          className='inline-flex relative items-center cursor-pointer'
                          title={`${
                            item?.status === 'active'
                              ? t('O_ACTIVE')
                              : t('O_INACTIVE')
                          }`}
                        >
                          <input
                            type='checkbox'
                            className='sr-only peer'
                            checked={item?.status === 'active'}
                            onChange={e =>
                              helper.alertFunction(
                                `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                  e.target.checked ? 'active' : 'inactive'
                                } "${item.first_name} ${item?.last_name}" ${t(
                                  'USER'
                                )}?`,
                                item,
                                handelStatusChange,
                                '',
                                language
                              )
                            }
                          />
                          <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                        </label>
                      </td>
                      <td className='text-center'>
                        {item?.isApprovedStatus === 'pending' && (
                          <>
                            <button
                              title={t('O_ACCEPT')}
                              type='button'
                              onClick={() =>
                                helper.alertFunction(
                                  `${t(
                                    'ARE_YOU_SURE_YOU_WANT_TO_ACCEPT_THE_REQUEST'
                                  )}`,
                                  { _id: item?._id, status: 'accepted' },
                                  handleAcceptReject,
                                  '',
                                  language
                                )
                              }
                              className='animate-bounce text-lg border border-transparent text-green-600 hover:text-green-900'
                            >
                              <BsHandThumbsUp />
                            </button>
                            <button
                              title={t('O_REJECT')}
                              type='button'
                              onClick={() =>
                                helper.alertFunction(
                                  `${t(
                                    'ARE_YOU_SURE_YOU_WANT_TO_REJECT_THE_REQUEST'
                                  )}`,
                                  { _id: item?._id, status: 'rejected' },
                                  handleAcceptReject,
                                  '',
                                  language
                                )
                              }
                              className='animate-bounce text-lg border border-transparent text-red-600 hover:text-red-900'
                            >
                              <BsHandThumbsDown />
                            </button>
                          </>
                        )}
                        {item?.isApprovedStatus !== 'pending' && (
                          <span
                            className={classNames('', {
                              'text-green-600':
                                item?.isApprovedStatus === 'accepted',
                              'text-red-600':
                                item?.isApprovedStatus === 'rejected'
                            })}
                          >
                            {startCase(item?.isApprovedStatus)}
                          </span>
                        )}
                      </td>
                    </>
                  )}
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(user?.permission?.[1]?.view ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handleUserView(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('VIEW_USER')}>
                              {' '}
                              <AiFillEye className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                            </a>
                          </li>
                        )}
                        {/* {(user?.permission?.[1]?.delete ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={e =>
                              helper.alertFunction(
                                `${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} "${
                                  item.first_name
                                } ${item?.last_name}" ${t('USER')}?`,
                                item,
                                handelDelete,
                                true,
                                language
                              )
                            }
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a
                              title={t('DELETE_USER')}
                              className='hover:text-blue-700 transition duration-150 ease-in-out'
                              data-bs-toggle='tooltip'
                            >
                              {' '}
                              <AiFillDelete className='cursor-pointer w-5 h-5 text-red-600' />{' '}
                            </a>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(users) ? (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={10}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
