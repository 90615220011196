import { validationRules } from './constants'
import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}

const formValidation = {
  first_name: {
    required: <>{<UseChange data='PLEASE_ENTER_FIRST_NAME' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  last_name: {
    required: <>{<UseChange data='PLEASE_ENTER_LAST_NAME' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  niptNo: {
    required: <>{<UseChange data='PLEASE_ENTER_NIPT_NO' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  nid: {
    required: <>{<UseChange data='PLEASE_ENTER_NID' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  business_name: {
    required: <>{<UseChange data='PLEASE_ENTER_BUSINESS_NAME' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  email: {
    required: <>{<UseChange data='PLEASE_ENTER_EMAIL_ID' />}</>,
    pattern: {
      value: validationRules.email,
      message: <>{<UseChange data='PLEASE_ENTER_VALID_EMAIL_ID_AS' />}</>
    }
  },
  mobile: {
    required: <>{<UseChange data='PLEASE_ENTER_MOBILE_NUMBER' />}</>,
    minLength: {
      value: 9,
      message: <>{<UseChange data='MINIMUM_LENGTH_SHOULD_BE_9_DIGITS' />}</>
    },
    min: {
      value: 0,
      message: <>{<UseChange data='MINIMUM_VALUE_MUST_IS_0' />}</>
    },
    maxLength: {
      value: 9,
      message: <>{<UseChange data='MAXIMUM_LENGTH_SHOULD_BE_9_DIGITS' />}</>
    }
  },
  refCode: {},
  name: {
    required: <>{<UseChange data='PLEASE_ENTER_NAME' />}</>,
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    validate: value => {
      return !!value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  nameAl: {
    required: <>{<UseChange data='PLEASE_ENTER_NAME_IN_ALBANIAN' />}</>,
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    validate: value => {
      return !!value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  username: {
    required: <>{<UseChange data='PLEASE_ENTER_USER_NAME' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  business_address: {
    required: <>{<UseChange data='PLEASE_ENTER_BUSINESS_ADDRESS' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    maxLength: {
      value: 200,
      message: <>{<UseChange data='MAXIMUM_CHARACTER_CAN_BE_200' />}</>
    },
    minLength: {
      value: 10,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_10' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  title: {
    required: <>{<UseChange data='PLEASE_ENTER_TITLE' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  titleAl: {
    required: <>{<UseChange data='PLEASE_ENTER_TITLE_IN_ALBANIAN' />}</>,
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    minLength: {
      value: 2,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_2' />}</>
    },
    validate: value => {
      return value.trim() ? (
        true
      ) : (
        <>{<UseChange data='WHITE_APCE_NOT_ALLOWED' />}</>
      )
    }
  },
  code: {
    required: <>{<UseChange data='PLEASE_ENTER_CODE' />}</>,

    maxLength: 40,
    minLength: {
      value: 3,
      message: <>{<UseChange data='MINIMUM_LENGTH_MUST_BE_3' />}</>
    }
    // pattern: {
    //   value: validationRules.removeWhitespace,
    //   message:
    //     'White space and special character not allowed.'
    // }
  },
  maxSalary: {
    required: <>{<UseChange data='PLEASE_ENTER_MAXIMUM_SALARY' />}</>,
    minLength: {
      value: 1,
      message: <>{<UseChange data='MINIMUM_LENGTH_SHOULD_BE_1_DIGITS' />}</>
    },
    maxLength: {
      value: 6,
      message: <>{<UseChange data='MAXIMUM_LENGTH_SHOULD_BE_6_DIGITS' />}</>
    },
    pattern: {
      value: validationRules.numbers,
      message: <>{<UseChange data='INVALID_MAXIMUM_SALARY' />}</>
    },
    min: {
      value: 0,
      message: <>{<UseChange data='VALUE_CAN_NOT_BE_LESS_THAN_0' />}</>
    }
  },
  maxLoanAmount: {
    required: <>{<UseChange data='PLEASE_ENTER_MAXIMUM_LOAN_AMOUNT' />}</>,
    minLength: {
      value: 1,
      message: <>{<UseChange data='MINIMUM_LENGTH_SHOULD_BE_1_DIGITS' />}</>
    },
    maxLength: {
      value: 6,
      message: <>{<UseChange data='MAXIMUM_LENGTH_SHOULD_BE_6_DIGITS' />}</>
    },
    pattern: {
      value: /^[^\s].*/,
      message: <>{<UseChange data='CANNOT_START_WITH_A_SPACE' />}</>
    },
    min: {
      value: 0,
      message: <>{<UseChange data='VALUE_CAN_NOT_BE_LESS_THAN_0' />}</>
    }
  },
  minimum_withdrawal_request: {
    required: {
      value: true,
      message: <>{<UseChange data='PLEASE_ENTER_MINIMUM_WITHDARW_REQUET' />}</>
    },
    pattern: {
      value: /^\d+$/,
      message: <>{<UseChange data='DECIMALS_NOT_ALLOWED' />}</>
    },
    maxLength: {
      value: 10,
      message: <>{<UseChange data='MAXIMUM_LENGTH_IS_10_CHARACTER' />}</>
    },
    min: {
      value: 1,
      message: <>{<UseChange data='MINIMUM_VALUE_IS_MUST_1' />}</>
    }
  }
}

export default formValidation
