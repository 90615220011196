import React, { useContext, useState } from 'react'
import { Popover } from '@headlessui/react'
import { Link, NavLink } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import ArrowSmallRight from './reusable/icons/ArrowSmallRight'
import Documents from './reusable/icons/Documents'
import { HiChevronRight } from 'react-icons/hi2'
import Dashboard from '../assets/images/sidebar_icon1.svg'
import User from '../assets/images/sidebar_icon2.svg'
import Services from '../assets/images/sidebar_icon3.svg'
import WalletManager from '../assets/images/Wallet_manager.png'
import TransactionManager from '../assets/images/transaction_manager.png'
import CategoryManager from '../assets/images/category_manager.png'
import subcategoryManager from '../assets/images/subcategory.png'
import MerchantManager from '../assets/images/merchant_manager.png'
import ManageOffer from '../assets/images/manage_offer.png'
import EarningManager from '../assets/images/earning_manager.png'
import Logs from '../assets/images/logs.png'
import ManageMicro from '../assets/images/manage_micro.png'
import LoanSlab from '../assets/images/loan_slab.png'
import MicroSetting from '../assets/images/micro_settings.png'
import Reports from '../assets/images/reports.png'
import StaticContents from '../assets/images/static_contents.png'
import Transaction from '../assets/images/sidebar_icon7.svg'
import Setting from '../assets/images/sidebar_icon9.svg'
import Logout from '../assets/images/sidebar_icon11.svg'
import { useTranslation } from 'react-i18next'
import helper from '../utils/helpers'

const classNames = require('classnames')
console.log('hdsgsah')
const Sidebar = () => {
  const { t } = useTranslation()
  const {
    logoutUser,
    user,
    sidebarStatus,
    handleSidebar,
    updatePageName,
    language
  } = useContext(AuthContext)
  const [staticPopup, setStaticPopup] = useState(false)

  const handleLogout = () => {
    logoutUser()
  }

  const checkSidebarPermission = arg => {
    if (!user) {
      return false
    }
    const localPermissions = [...user?.permission]

    if (user?.permission?.length === 0) {
      return true
    }
    const perIndex = localPermissions?.findIndex(item => item?.manager === arg)
    if (perIndex < 0) {
      return false
    }
    if (localPermissions?.[perIndex]?.view) {
      return true
    }

    return false
  }

  return (
    <>
      {' '}
      <div
        className={`sidebar lg:block z-10  ${
          sidebarStatus === 'open' ? 'block' : 'hidden'
        } bg-gradient-to-t from-gradpurple to-gradblue w-[220px] xl:w-[280px] fixed h-full overflow-y-auto`}
        onClick={handleSidebar}
      >
        <div className='text-white'>
          <Link
            to='/dashboard'
            onClick={() => updatePageName('Dashboard')}
            className='px-2 py-6 w-full text-center flex justify-center'
          >
            <img src='images/whitelogo.svg' className='inline' alt='' />
          </Link>
          <div className='profile text-center'>
            <small className='block text-sm'>{t('WELCOME')}</small>
            <strong className='block text-lg overflow-hidden text-ellipsis px-2 whitespace-nowrap '>{`${user?.first_name} ${user?.last_name}`}</strong>
          </div>

          <nav className='pt-4 pb-5 flex flex-col justify-center font-normal text-xs overflow-y-auto'>
            {checkSidebarPermission('dashboard') && (
              <NavLink
                to='/dashboard'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_DASHBOARD'))}
              >
                <span className='mr-2'>
                  <img src={Dashboard} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_DASHBOARD')}
              </NavLink>
            )}

            {checkSidebarPermission('users') && (
              <NavLink
                to='/users'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('O_USERS'))}
              >
                <span className='mr-2'>
                  <img src={User} className='max-w-[18px]' alt='' />
                </span>
                {t('O_USERS')}
              </NavLink>
            )}

            {checkSidebarPermission('wallet_manager') && (
              <NavLink
                to='/wallet-manager'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_WALLET_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={WalletManager} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_WALLET_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('transaction_manager') && (
              <NavLink
                to='/transaction-manager'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_TRANSACTION_MANAGER'))}
              >
                <span className='mr-2'>
                  <img
                    src={TransactionManager}
                    className='max-w-[18px]'
                    alt=''
                  />
                </span>
                {t('NAV_TRANSACTION_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('category_manager') && (
              <NavLink
                to='/categories'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_CATEGORY_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={CategoryManager} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_CATEGORY_MANAGER')}
              </NavLink>
            )}
            {checkSidebarPermission('subcategory_manager') && (
              <NavLink
                to='/subCategories'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_SUB_CATEGORY_MANAGER'))}
              >
                <span className='mr-2'>
                  <img
                    src={subcategoryManager}
                    className='max-w-[18px]'
                    alt=''
                  />
                </span>
                {t('NAV_SUB_CATEGORY_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('subadmin_manager') && (
              <NavLink
                to='/sub-admin-manager'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_SUB_ADMIN_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={Services} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_SUB_ADMIN_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('merchant_manager') && (
              <NavLink
                to='/merchants'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('O_MERCHANTS_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={MerchantManager} className='max-w-[18px]' alt='' />
                </span>
                {t('O_MERCHANTS_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('merchant_manager') && (
              <NavLink
                to='/operators'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('O_OPERATOR_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={MerchantManager} className='max-w-[18px]' alt='' />
                </span>
                {t('O_OPERATOR_MANAGER')}
              </NavLink>
            )}

            {checkSidebarPermission('manage_offers') && (
              <NavLink
                to='/offers'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_MANAGE_OFFERS'))}
              >
                <span className='mr-2'>
                  <img src={ManageOffer} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_MANAGE_OFFERS')}
              </NavLink>
            )}

            {checkSidebarPermission('earning_manager') && (
              <NavLink
                to='/earning-managers'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_EARNING_MANAGER'))}
              >
                <span className='mr-2'>
                  <img src={EarningManager} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_EARNING_MANAGER')}
              </NavLink>
            )}
            {checkSidebarPermission('logs_managers') && (
              <NavLink
                to='/logs-management'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_LOGS_MANAGEMENT'))}
              >
                <span className='mr-2'>
                  <img src={Logs} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_LOGS_MANAGEMENT')}
              </NavLink>
            )}
            {checkSidebarPermission('manage_micro_credit_request') && (
              <NavLink
                to='/manage-micro-credit-request'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() =>
                  updatePageName(t('NAV_MANAGE_MICRO_CREDIT_REQUEST'))
                }
              >
                <span className='mr-2'>
                  <img src={ManageMicro} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_MANAGE_MICRO_CREDIT_REQUEST')}
              </NavLink>
            )}
            {checkSidebarPermission('manage_micro_credit_loan_slabs') && (
              <NavLink
                to='/manage-micro-credit-loan-slabs'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() =>
                  updatePageName(t('NAV_MANAGE_MICRO_CREDIT_LOAN_SLABS'))
                }
              >
                <span className='mr-2'>
                  <img src={LoanSlab} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_MANAGE_MICRO_CREDIT_LOAN_SLABS')}
              </NavLink>
            )}
            {checkSidebarPermission('micro_credit_request_settings') && (
              <NavLink
                to='/micro-credit-request-setting'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() =>
                  updatePageName(t('NAV_MICRO_CREDIT_REQUEST_SETTING'))
                }
              >
                <span className='mr-2'>
                  <img src={MicroSetting} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_MICRO_CREDIT_REQUEST_SETTING')}
              </NavLink>
            )}
            {checkSidebarPermission('manage_orders') && (
              <NavLink
                to='/manage-orders'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_MANAGE_ORDERS'))}
              >
                <span className='mr-2'>
                  <img src={Transaction} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_MANAGE_ORDERS')}
              </NavLink>
            )}
            {checkSidebarPermission('reports') && (
              <NavLink
                to='/reports'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_REPORTS'))}
              >
                <span className='mr-2'>
                  <img src={Reports} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_REPORTS')}
              </NavLink>
            )}

            {checkSidebarPermission('static_content') && (
              <Popover className='relative'>
                {() => (
                  <>
                    <Popover.Button
                      onClick={() => setStaticPopup(!staticPopup)}
                      style={{ width: '100%' }}
                      className='outline-none text-start  flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue'
                    >
                      <span className='mr-2'>
                        <img
                          src={StaticContents}
                          className='max-w-[18px]'
                          alt=''
                        />
                      </span>
                      {t('NAV_STATIC_CONTENTS')}{' '}
                      <span className='mr-2'>
                        <HiChevronRight
                          className={
                            staticPopup
                              ? 'text-lg ml-2 rotate-90 transform'
                              : 'text-lg ml-2'
                          }
                        />
                      </span>
                    </Popover.Button>
                    {staticPopup && (
                      <Popover.Panel className='' static={true}>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/careers'
                          onClick={() => updatePageName(t('NAV_CAREERS'))}
                        >
                          <ArrowSmallRight />
                          {t('NAV_CAREERS')}
                        </NavLink>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/about-us'
                          onClick={() => updatePageName(t('NAV_ABOUT_US'))}
                        >
                          <ArrowSmallRight />
                          {t('NAV_ABOUT_US')}
                        </NavLink>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/contact-us'
                          onClick={() => updatePageName(t('NAV_CONTACT_US'))}
                        >
                          <ArrowSmallRight />
                          {t('NAV_CONTACT_US')}
                        </NavLink>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/faqs'
                          onClick={() => updatePageName(t('NAV_FAQS'))}
                        >
                          <ArrowSmallRight />
                          {t('NAV_FAQS')}
                        </NavLink>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/privacy-policy'
                          onClick={() =>
                            updatePageName(t('NAV_PRIVACY_POLICY'))
                          }
                        >
                          <ArrowSmallRight />
                          {t('NAV_PRIVACY_POLICY')}
                        </NavLink>
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              'flex items-center px-6 lg-px-12 py-2 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                              {
                                active: isActive
                              }
                            )
                          }
                          to='/static/terms-and-conditions'
                          onClick={() =>
                            updatePageName(t('NAV_TERMS_AND_CONDITIONS'))
                          }
                        >
                          <ArrowSmallRight />
                          {t('NAV_TERMS_AND_CONDITIONS')}
                        </NavLink>
                      </Popover.Panel>
                    )}
                  </>
                )}
              </Popover>
            )}

            {checkSidebarPermission('settings') && (
              <NavLink
                to='/settings'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_SETTINGS'))}
              >
                <span className='mr-2'>
                  <img src={Setting} className='max-w-[18px]' alt='' />
                </span>
                {t('NAV_SETTINGS')}
              </NavLink>
            )}

            {checkSidebarPermission('aml') && (
              <NavLink
                to='/aml'
                className={({ isActive }) =>
                  classNames(
                    'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
                    {
                      active: isActive
                    }
                  )
                }
                onClick={() => updatePageName(t('NAV_AML'))}
              >
                <span className='mr-2'>
                  <Documents />
                </span>
                {t('NAV_AML')}
              </NavLink>
            )}

            <Link
              onClick={() =>
                helper.alertFunction(
                  `${t('ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}`,
                  '',
                  handleLogout,
                  '',
                  language
                )
              }
              className='flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue'
            >
              <span className='mr-2'>
                <img src={Logout} className='max-w-[18px]' alt='' />
              </span>
              {t('NAV_LOGOUT')}
            </Link>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidebar
