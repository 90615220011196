import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { validationRules } from 'utils/constants'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Permission from './constant'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import { isEmpty } from 'lodash'

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
const { startCase, capitalize } = require('lodash')

const SubAdd = ({ setShowModal, allSubAdmin, setPage }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true
  })

  const notification = useToastContext()
  const [permissionJons, setPermission] = useState(Permission)
  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [addMerchantLoading, setAddMerchantLoading] = useState(false)

  const onChange = event => {
    setPermission(current =>
      current.map(obj => {
        if (obj.manager === event.target.name) {
          if (event.target.id === 'add' && event.target.checked)
            return {
              ...obj,
              [event.target.id]: event.target.checked,
              view: event.target.checked
            }
          else if (event.target.id === 'add' && !event.target.checked)
            return {
              ...obj,
              [event.target.id]: event.target.checked,
              view: !event.target.checked
            }
          if (event.target.id === 'delete' && event.target.checked)
            return {
              ...obj,
              [event.target.id]: event.target.checked,
              view: event.target.checked
            }
          else if (event.target.id === 'view' && !event.target.checked) {
            return {
              ...obj,
              add: false,
              view: false,
              delete: false,
              all: false
            }
          }

          return { ...obj, [event.target.id]: event.target.checked }
        }
        return obj
      })
    )
  }
  const checkAll = event => {
    setPermission(current =>
      current.map(obj => {
        if (obj.manager === event.target.name) {
          return {
            ...obj,
            add: event.target.checked,
            view: event.target.checked,
            delete: event.target.checked
          }
        }
        return obj
      })
    )
  }

  const onSubmit = async data => {
    setAddMerchantLoading(true)
    const myData = { ...data, country_code: 355 }
    try {
      const path = apiPath.addSubadmin
      const myObj = {
        manager: 'dashboard',
        add: true,
        view: true,
        delete: true
      }
      const permission = JSON.stringify([myObj, ...permissionJons])
      const sendData = { ...myData, permission }
      const result = await apiPost(path, sendData)
      if (result?.data?.success) {
        allSubAdmin()
        setPage(1)
        setShowModal(false)
        notification.success(result.data.message)
      } else {
        notification.error(result.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
    setAddMerchantLoading(false)
  }

  function changeIcon () {
    setIcon(!icon)
  }
  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])

  const codeValue = watch('email') ? watch('email') : ''

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6  w-full max-w-[1150px] mx-auto'>
          <div className='sm:py-4 sm:px-2 py-8 px-7 '>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('SUB_ADMIN_ADD_SUB_ADMIN')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setShowModal(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className='relative p-6 flex-auto'>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                  <div className='px-2'>
                    <OInputField
                      wrapperClassName='relative z-0 mb-6 w-full group'
                      name='first_name'
                      inputLabel={
                        <>
                          {t('O_FIRST_NAME')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      autoFocus
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register(
                        'first_name',
                        formValidation['first_name']
                      )}
                      errors={errors}
                    />
                  </div>

                  <div className='px-2'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='last_name'
                      inputLabel={
                        <>
                          {t('O_LAST_NAME')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register(
                        'last_name',
                        formValidation['last_name']
                      )}
                      errors={errors}
                    />
                  </div>
                  <div className='px-2'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='username'
                      inputLabel={
                        <>
                          {t('SUB_ADMIN_USERNAME')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register(
                        'username',
                        formValidation['username']
                      )}
                      errors={errors}
                    />
                  </div>
                  <div className='px-2'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='email'
                      id='email'
                      inputLabel={
                        <>
                          {t('O_EMAIL_ID')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      value={codeValue.toLowerCase()}
                      maxLength={50}
                      autoComplete='off'
                      onInput={e => preventMaxInput(e, 50)}
                      register={register('email', formValidation['email'])}
                      errors={errors}
                    />
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group bg-zinc-200'>
                      <select
                        type='select'
                        id='country_code'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        {...register('country_code', {})}
                        disabled
                      >
                        <option value='355'>355</option>
                      </select>
                      <label
                        htmlFor='country_code'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-2 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_COUNTRY_CODE')}
                      </label>
                      <ErrorMessage message={errors?.country_code?.message} />
                    </div>
                  </div>
                  <div className='px-2'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='number'
                      name='mobile'
                      id='mobile'
                      inputLabel={
                        <>
                          {t('O_MOBILE_NUMBER')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      min={0}
                      onKeyDown={e => {
                        if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => preventMaxInput(e, 9)}
                      register={register('mobile', formValidation['mobile'])}
                      errors={errors}
                    />
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type={icon ? 'password' : 'text'}
                        name='password'
                        inputLabel={
                          <>
                            {t('O_PASSWORD')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        errors={errors}
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register('password', {
                          required: t('PLEASE_ENTER_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.passwordMessage
                          }
                        })}
                      />
                      {icon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changeIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type={passwordIcon ? 'password' : 'text'}
                        inputLabel={
                          <>
                            {t('O_CONFIRM_PASSWORD')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        name='confirm_password'
                        id='confirm_password'
                        autoComplete='new-password'
                        maxLength={15}
                        errors={errors}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register('confirm_password', {
                          required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.confirmPasswordMessage
                          },
                          validate: value => {
                            const { password } = getValues()
                            return (
                              password === value ||
                              t('PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH')
                            )
                          }
                        })}
                      />
                      {passwordIcon ? (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view absolute top-[18px] right-[20px]'
                          onClick={() => changePasswordIcon()}
                        >
                          <AiFillEye />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <textarea
                        name='address'
                        id='address'
                        placeholder=' '
                        maxLength={200}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        onInput={e => preventMaxInput(e, 200)}
                        {...register('address', {
                          required: t('PLEASE_ENTER_ADDRESS'),
                          pattern: {
                            value: /^[^\s].*/,
                            message: t('CANNOT_START_WITH_A_SPACE')
                          },
                          minLength: {
                            value: 10,
                            message: t('MINIMUM_LENGTH_MUST_BE_10')
                          },
                          validate: value => {
                            return value.trim()
                              ? true
                              : t('WHITE_APCE_NOT_ALLOWED')
                          }
                        })}
                      />
                      <label
                        for='address'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('SUB_ADMIN_ADDRESS')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <ErrorMessage message={errors?.address?.message} />
                    </div>
                  </div>
                </div>
                <div className='pl-3 pr-3 h-56 overflow-y-scroll'>
                  <div className='overflow-x-auto  overflow-y-auto relative rounded-lg border'>
                    <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
                      <thead className='text-xs text-gray-900 border  border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
                        <tr>
                          <th scope='col' className='py-3 px-6 '>
                            {t('SUB_ADMIN_MANAGER')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_VIEW')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('SUB_ADMIN_ADD_EDIT')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_DELETE')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_ALL')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {permissionJons?.map((item, i) => (
                          <tr
                            key={i}
                            className='bg-white border-b  dark:bg-gray-800 dark:border-gray-700'
                          >
                            <td className='py-4 px-6 border-r '>
                              {capitalize(startCase(item.manager))}
                            </td>
                            <td className='py-4 px-6 border-r '>
                              {item?.shownView && (
                                <input
                                  type='checkbox'
                                  name={item?.manager}
                                  id='view'
                                  checked={item.view}
                                  onChange={onChange}
                                />
                              )}
                            </td>

                            <td className='py-4 px-6 border-r '>
                              {item?.shownEdit && (
                                <input
                                  type='checkbox'
                                  id='add'
                                  name={item?.manager}
                                  checked={item.add}
                                  onChange={onChange}
                                />
                              )}
                            </td>

                            <td className='py-4 px-6 border-r '>
                              {item?.shownDelete && (
                                <input
                                  type='checkbox'
                                  name={item?.manager}
                                  id='delete'
                                  checked={item.delete}
                                  onChange={onChange}
                                />
                              )}
                            </td>
                            <td className='py-4 px-6 border-r '>
                              {item?.shownAll && (
                                <input
                                  type='checkbox'
                                  id='all'
                                  name={item?.manager}
                                  onChange={checkAll}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setShowModal(false)}
                >
                  {t('O_BACK')}
                </button>

                {addMerchantLoading ? (
                  <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                    <div className='loading-spinner'></div>
                  </div>
                ) : (
                  <button
                    className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('O_ADD')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default SubAdd
