import React, { useState } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'

const CategoryEdit = ({ allCategory, currentItem, handleEdit }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: currentItem?.name,
      nameAl: currentItem?.nameAl
    }
  })

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      var path = apiPath.categoryUpdate + '/' + currentItem._id
      const result = await apiPut(path, data)
      if (result.data.success) {
        allCategory()
        handleEdit(false)
        notification.success(result?.data.message)
      } else {
        notification.error(result?.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error)
      notification.error(error.message)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto'>
          <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none lg:min-w-[520px]'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('CATEGORY_EDIT_CATEGORY')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => handleEdit(false)}
                  title={t('CLOSE')}
                >
                  <span className=' text-[#B8BBBF]  text-4xl '>×</span>
                </button>
              </div>
              {/*body*/}
              <div className='relative p-6 flex-auto'>
                <div className='grid sm:grid-cols-1'>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='name'
                      id='name'
                      autoFocus={true}
                      inputLabel={
                        <>
                          {t('CATEGORY_NAMES')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      placeholder=''
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register('name', formValidation['name'])}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='nameAl'
                      id='nameAl'
                      autoFocus={true}
                      inputLabel={
                        <>
                          {t('CATEGORY_NAMES') + t('ALBANIAN')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      placeholder=''
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register('nameAl', formValidation['nameAl'])}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => handleEdit(false)}
                >
                  {t('O_BACK')}
                </button>

                {isLoading ? (
                  <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                    <div className='loading-spinner'></div>
                  </div>
                ) : (
                  <button
                    className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('O_EDIT')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default CategoryEdit
