import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/ErrorMessage'
import { useTranslation } from 'react-i18next'
const { startCase, capitalize } = require('lodash')

const SubAdminView = ({ setViewShow, item }) => {
  const { t } = useTranslation()
  const {
    register,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      username: item?.username,
      mobile: item?.mobile,
      email: item?.email,
      country_code: item?.country_code,
      address: item?.address,
      first_name: item?.first_name,
      last_name: item?.last_name,
      permission: item?.permission,
      password: item?.password
    }
  })

  const [permissionJson] = useState(getValues('permission'))

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6  w-full max-w-[1150px] mx-auto'>
          <div className='sm:py-4 sm:px-2 py-8 px-7'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('SUB_ADMIN_VIEW_SUB_ADMIN')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setViewShow(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className='relative p-6 flex-auto'>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <input
                        type='text'
                        id='first_name'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        placeholder=' '
                        disabled
                        {...register('first_name', {})}
                      />

                      <label
                        htmlFor='first_name'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_FIRST_NAME')}
                      </label>
                      <ErrorMessage message={errors?.first_name?.message} />
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <input
                        type='text'
                        id='last_name'
                        placeholder=' '
                        disabled
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        {...register('last_name', {})}
                      />

                      <label
                        htmlFor='last_name'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_LAST_NAME')}
                      </label>
                      <ErrorMessage message={errors?.last_name?.message} />
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <input
                        type='text'
                        name='username'
                        id='username'
                        placeholder=' '
                        maxLength={50}
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        {...register('username', {})}
                        disabled
                      />
                      <label
                        htmlFor='username'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('SUB_ADMIN_USERNAME')}
                      </label>
                      <ErrorMessage message={errors?.username?.message} />
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <input
                        type='text'
                        name='email'
                        placeholder=' '
                        id='email'
                        autoComplete='off'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        {...register('email', {})}
                        disabled
                      />
                      <label
                        htmlFor='email'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_EMAIL_ID')}
                      </label>
                      <ErrorMessage message={errors?.email?.message} />
                    </div>{' '}
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <select
                        type='select'
                        id='country_code'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        {...register('country_code', {})}
                        disabled
                      >
                        <option value=''>{t('O_SELECT_COUNTRY_CODE')}</option>
                        <option value='355'>355</option>
                      </select>
                      <label
                        htmlFor='country_code'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_COUNTRY_CODE')}
                      </label>
                      <ErrorMessage message={errors?.country_code?.message} />
                    </div>
                  </div>
                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <input
                        type='number'
                        name='mobile'
                        id='mobile'
                        placeholder=' '
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        {...register('mobile', {})}
                        disabled
                      />
                      <label
                        htmlFor='mobile'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('O_MOBILE_NUMBER')}
                      </label>
                      <ErrorMessage message={errors?.mobile?.message} />
                    </div>
                  </div>

                  <div className='px-2'>
                    <div className='relative z-0 mb-6 w-full group'>
                      <textarea
                        name='address'
                        id='address'
                        placeholder=' '
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                        {...register('address', {})}
                        disabled
                      />
                      <label
                        htmlFor='address'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('SUB_ADMIN_ADDRESS')}
                      </label>
                      <ErrorMessage message={errors?.address?.message} />
                    </div>
                  </div>
                </div>
                <div className='pl-3 pr-3 h-56 overflow-y-scroll'>
                  <div className='overflow-x-auto  overflow-y-auto relative rounded-lg border'>
                    <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
                      <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
                        <tr>
                          <th scope='col' className='py-3 px-6 '>
                            {t('SUB_ADMIN_MANAGER')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_VIEW')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('SUB_ADMIN_ADD_EDIT')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_DELETE')}
                          </th>
                          <th scope='col' className='py-3 px-6 '>
                            {t('O_ALL')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {permissionJson?.map((item, i) => (
                          <tr
                            key={i}
                            className='bg-white border-b  dark:bg-gray-800 dark:border-gray-700'
                          >
                            <td className='py-4 px-6 border-r '>
                              {capitalize(startCase(item.manager))}
                            </td>
                            <td className='py-4 px-6 border-r '>
                              <input
                                type='checkbox'
                                id='view'
                                checked={item.view}
                              />
                            </td>

                            <td className='py-4 px-6 border-r '>
                              <input
                                type='checkbox'
                                id='add'
                                checked={item.add}
                              />
                            </td>
                            <td className='py-4 px-6 border-r '>
                              {item?.manager === 'settings' ||
                              item?.manager === 'static_content' ||
                              item?.manager === 'transaction_manager' ||
                              item?.manager === 'dashboard' ||
                              item?.manager === 'wallet_manager' ? null : (
                                <input
                                  type='checkbox'
                                  id='delete'
                                  checked={item.delete}
                                />
                              )}
                            </td>
                            <td className='py-4 px-6 border-r '>
                              {item?.manager === 'settings' ||
                              item?.manager === 'static_content' ||
                              item?.manager === 'transaction_manager' ||
                              item?.manager === 'dashboard' ||
                              item?.manager === 'wallet_manager' ? null : (
                                <input type='checkbox' disabled />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setViewShow(false)}
                >
                  {t('O_BACK')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default SubAdminView
