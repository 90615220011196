import useToastContext from 'hooks/useToastContext'
import { AiFillEdit, AiFillDelete, AiFillEye } from 'react-icons/ai'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import { useContext } from 'react'
import { isEmpty } from 'lodash'
import helper from '../../utils/helpers'
import { BsArrowUpShort } from 'react-icons/bs'

const SubCategoryList = ({
  subcategoryList,
  page,
  allSubCategory,
  handleEdit,
  handleView,
  sort,
  setSort,
  setPage
}) => {
  const notification = useToastContext()
  const { t } = useTranslation()
  const { user, language } = useContext(AuthContext)

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.subCategoryStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        allSubCategory({ statusChange: 1 })
      }
      // }
    } catch (error) {
      console.log('error in get all subcategory list==>>>>', error.message)
    }
  }

  const handelDelete = async item => {
    try {
      var path = apiPath.deleteSubCategory + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status == 200) {
        notification.success(result.data.message)
        setPage(1)
        allSubCategory()
        // allSubCategory({ deletePage: 1 });
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div>
      <div className='p-3'>
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('OFFERS_CATEGORY')}
                </th>

                <th scope='col' className='py-3 px-6 text-left'>
                  {t('SUB_CATEGORY')}
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('SUB_CATEGORY') + t('ALBANIAN')}
                </th>
                <th
                  scope='col'
                  className='py-3 px-6 cursor-pointer'
                  onClick={() => {
                    if (
                      sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc'
                    ) {
                      setSort({
                        sort_key: 'createdAt',
                        sort_type: 'desc'
                      })
                    } else {
                      setSort({
                        sort_key: 'createdAt',
                        sort_type: 'asc'
                      })
                    }
                  }}
                >
                  <div className='flex justify-start'>
                    <span>{t('O_CREATED_AT')}</span>
                    <span>
                      {sort.sort_key === 'createdAt' &&
                        sort.sort_type === 'asc' && (
                          <BsArrowUpShort className='w-4 h-4' />
                        )}
                      {sort.sort_key === 'createdAt' &&
                        sort.sort_type === 'desc' && (
                          <BsArrowUpShort className='w-4 h-4 rotate-180' />
                        )}
                    </span>
                  </div>
                </th>
                {/* <th scope="col" className="py-3 px-6 text-right">
                  {t('O_CREATED_AT')}
                </th> */}
                {(user?.permission?.[5]?.add ||
                  user?.permission?.length === 0) && (
                  <th scope='col' className='py-3 px-6 text-left'>
                    {t('O_STATUS')}
                  </th>
                )}
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_ACTION')}
                </th>
              </tr>
            </thead>

            <tbody>
              {subcategoryList &&
                subcategoryList.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    >
                      <th
                        scope='row'
                        className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                      >
                        {i + 1 + 10 * (page - 1)}
                      </th>
                      <td className='py-4 px-6 border-r'>
                        {item?.category_id?.name}
                      </td>
                      <td className='py-4 px-6 border-r'>{item?.name}</td>
                      <td className='py-4 px-6 border-r'>{item?.nameAl}</td>
                      <td className='py-4 px-6 border-r text-right'>
                        {dayjs(item?.createdAt).format('DD-MM-YYYY h:mm A')}{' '}
                      </td>
                      {(user?.permission?.[5]?.add ||
                        user?.permission?.length === 0) && (
                        <td className='py-4 px-6 border-r text-center'>
                          <label
                            className='inline-flex relative items-center cursor-pointer'
                            title={`${
                              item?.status === 'active'
                                ? t('O_ACTIVE')
                                : t('O_INACTIVE')
                            }`}
                          >
                            <input
                              type='checkbox'
                              className='sr-only peer'
                              checked={item?.status === 'active'}
                              onChange={e =>
                                helper.alertFunction(
                                  `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                    e.target.checked ? 'active' : 'inactive'
                                  } "${item.name}" ${t('SUBCATEGORY')}?`,
                                  item,
                                  handelStatusChange,
                                  '',
                                  language
                                )
                              }
                              // onChange={(e) => handelStatusChange(item)(e)}
                            />
                            <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                          </label>
                        </td>
                      )}
                      <td className='py-4 px-6 border-l'>
                        <div className=''>
                          <ul className='flex justify-center'>
                            {(user?.permission?.[5]?.add ||
                              user.permission?.length === 0) && (
                              <li
                                onClick={() => handleEdit(item)}
                                className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                              >
                                <a
                                  title={t('EDIT_SUBCATEGORY')}
                                  className='hover:text-blue-700 transition duration-150 ease-in-out'
                                  data-bs-toggle='tooltip'
                                >
                                  {' '}
                                  <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                                </a>
                              </li>
                            )}
                            {(user?.permission?.[5]?.view ||
                              user.permission?.length === 0) && (
                              <li
                                onClick={() => handleView(item)}
                                className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                              >
                                <a title={t('VIEW_SUBCATEGORY')}>
                                  {' '}
                                  <AiFillEye className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                                </a>
                              </li>
                            )}
                            {(user?.permission?.[5]?.delete ||
                              user.permission?.length === 0) && (
                              <li
                                onClick={e =>
                                  helper.alertFunction(
                                    `${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} "${
                                      item.name
                                    }" ${t('SUBCATEGORY')}?`,
                                    item,
                                    handelDelete,
                                    true,
                                    language
                                  )
                                }
                                // onClick={() => handelDelete(item)}
                                className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                              >
                                <a
                                  title={t('DELETE_SUBCATEGORY')}
                                  className='hover:text-blue-700 transition duration-150 ease-in-out'
                                  data-bs-toggle='tooltip'
                                >
                                  {' '}
                                  <AiFillDelete className='cursor-pointer w-5 h-5 text-red-600' />{' '}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              {isEmpty(subcategoryList) ? (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={6}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SubCategoryList
