import React from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BsArrowUpShort } from 'react-icons/bs'

const UserReportTable = ({ reports, sort, setSort, page }) => {
  const { t } = useTranslation()
  if (reports?.docs) {
    return (
      <div className='p-3'>
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6'>
                  <div className='flex'>
                    <span>{t('O_CUSTOMER_ID')} </span>
                  </div>
                </th>
                <th
                  scope='col'
                  className='py-3 px-6'
                  onClick={() => {
                    if (
                      sort.sort_key === 'fullName' &&
                      sort.sort_type === 'asc'
                    ) {
                      setSort({
                        sort_key: 'fullName',
                        sort_type: 'desc'
                      })
                    } else {
                      setSort({
                        sort_key: 'fullName',
                        sort_type: 'asc'
                      })
                    }
                  }}
                >
                  <div className='flex'>
                    <span>{t('O_NAME_CUSTOMER')} </span>
                    <span>
                      {sort.sort_key === 'fullName' &&
                        sort.sort_type === 'asc' && (
                          <BsArrowUpShort className='w-4 h-4' />
                        )}
                      {sort.sort_key === 'fullName' &&
                        sort.sort_type === 'desc' && (
                          <BsArrowUpShort className='w-4 h-4 rotate-180' />
                        )}
                    </span>
                  </div>
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_EMAIL_ID')}
                </th>
                <th scope='col' className='py-3 px-6 text-left'>
                  {`${t('O_MOBILE_NUMBER')} (+355)`}
                </th>
                <th
                  scope='col'
                  className='py-3 px-6 cursor-pointer'
                  onClick={() => {
                    if (sort.sort_key === 'count' && sort.sort_type === 'asc') {
                      setSort({
                        sort_key: 'count',
                        sort_type: 'desc'
                      })
                    } else {
                      setSort({
                        sort_key: 'count',
                        sort_type: 'asc'
                      })
                    }
                  }}
                >
                  <div className='flex justify-start'>
                    <span>{t('O_COUNT_LOGGED_IN')}</span>
                    <span>
                      {sort.sort_key === 'count' &&
                        sort.sort_type === 'asc' && (
                          <BsArrowUpShort className='w-4 h-4' />
                        )}
                      {sort.sort_key === 'count' &&
                        sort.sort_type === 'desc' && (
                          <BsArrowUpShort className='w-4 h-4 rotate-180' />
                        )}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {reports &&
                reports?.docs?.map((item, i) => (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  >
                    <td
                      scope='row'
                      className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                    >
                      {i + 1 + 10 * (page - 1)}
                    </td>
                    <td
                      className='py-4 px-6 border-r'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.user?._id}
                    </td>
                    <td
                      className='py-4 px-6 border-r'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.user?.fullName}
                    </td>
                    <td
                      className='py-4 px-6 border-r'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.user?.email}
                    </td>

                    <td className='py-4 px-6 border-r text-right'>
                      {item?.user?.mobile}
                    </td>
                    <td className='py-4 px-6 border-r text-right'>
                      {item?.count}
                    </td>
                  </tr>
                ))}
              {isEmpty(reports?.docs) ? (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={7}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default UserReportTable
