import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import OffersTable from './OffersTable'
import AddOffer from './AddOffer'
import EditOffer from './EditOffer'
import Pagination from '../Pagination'
import dayjs from 'dayjs'
import AuthContext from 'context/AuthContext'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'

function Offers () {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [editshowModal, setEditShowModal] = useState(false)
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [offers, setOffers] = useState([])
  const [page, setPage] = useState(1)
  const [item, setItem] = useState('')
  const { user } = useContext(AuthContext)
  const [isDelete, setIsDelete] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })
  const [filterData, setFilterData] = useState({
    category: '',
    searchkey: '',
    startDate: '',
    endDate: '',
    isReset: false,
    user_type: '',
    status: '',
    isFilter: false
  })

  const {
    category,
    startDate,
    endDate,
    searchkey,
    status,
    user_type,
    isFilter
  } = filterData

  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const size = 10

  const getAllOffers = async data => {
    let result
    try {
      if (
        (data?.deletePage && offers?.length >= 1) ||
        (isFilter && status && data?.statusChange && offers?.length >= 1)
      ) {
        setPage(page - 1)
        setIsDelete(true)
        setPaginationObj({ ...paginationObj, page: page - 1 })
      } else {
        setIsDelete(false)
      }

      const payload = {
        page,
        size,
        category,
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        keyword: searchkey,
        status,
        user_type,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }
      const path = apiPath.getAllOffers
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        setOffers(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('error in get all offers list==>>>> result:', error)

      if (error.response.data.message === 'Unauthenticated.') {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleChange = event => {
    setFilterData({
      ...filterData,
      isFilter: true,
      [event.target.name]: event.target.value
    })

    setPage(1)
  }
  const handelEdit = item => {
    setItem(item)
    setEditShowModal(true)
  }
  useEffect(() => {
    getAllOffers()
  }, [page, filterData, sort])

  const handleReset = () => {
    setFilterData({
      category: '',
      searchkey: '',
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false,
      status: '',
      user_type: ''
    })
    setPage(1)
    setSearchTerm('')
  }
  const categoryOptions = [
    {
      key: t('ORDERS_WALLET'),
      value: 'wallet'
    },
    {
      key: t('RECHARGE'),
      value: 'recharge'
    },
    {
      key: t('UTILITIES'),
      value: 'utilities'
    }
  ]

  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      isReset: false,
      startDate: start,
      endDate: end,
      isFilter: true
    })
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: false,
        searchkey: debouncedSearchTerm,
        isFilter: true
      })
      setPage(1)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    updatePageName(t('NAV_MANAGE_OFFERS'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  gap-2 px-4 py-3 flex-wrap justify-between flex '>
              <div className=' flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={filterData?.isReset}
                    setIsReset={setFilterData}
                  />

                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      name='category'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={category}
                      onChange={handleChange}
                    >
                      <option value=''>{t('OFFERS_SELECT_CATEGORY')}</option>
                      {categoryOptions.map(({ key, value }) => (
                        <option key={key} value={value}>
                          {key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='flex items-center  ml-3 mb-3 min-w-[145px]'>
                    <select
                      id='status'
                      name='status'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={status}
                      onChange={handleChange}
                    >
                      <option selected value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='active'>{t('O_ACTIVE')}</option>
                      <option value='inactive'>{t('O_INACTIVE')}</option>
                    </select>
                  </div>

                  <div className='flex items-center  ml-3 mb-3 min-w-[125px]'>
                    <select
                      id='user'
                      name='user_type'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={user_type}
                      onChange={handleChange}
                    >
                      <option value=''>{t('OFFERS_USER_TYPE')}</option>
                      <option value='all'>{t('O_ALL')}</option>
                      <option value='user'>{t('OFFERS_SPECIFIC')}</option>
                    </select>
                  </div>

                  <button
                    type='button'
                    onClick={() => handleReset()}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='lg:pt-0 pt-2'>
                <div className='flex justify-end'>
                  <div className='relative '>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      name='searchkey'
                      title=''
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[200px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('OFFER_SEARCH_BY_TITLE_CODE')}
                      required
                      onChange={e => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                  {(user?.permission?.[8]?.add ||
                    user.permission?.length === 0) && (
                    <button
                      title={t('OFFER_ADD_OFFER')}
                      type='button'
                      className='bg-LightBlue flex text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                      onClick={() => setShowModal(true)}
                    >
                      + {t('OFFER_ADD_OFFER')}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <OffersTable
              offers={offers}
              getAllOffers={getAllOffers}
              handelEdit={handelEdit}
              user={user}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
            />

            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {showModal ? (
          <AddOffer
            setShowModal={setShowModal}
            getAllOffers={getAllOffers}
            setPage={setPage}
          />
        ) : null}
        {editshowModal ? (
          <EditOffer
            setEditShowModal={setEditShowModal}
            getAllOffers={getAllOffers}
            item={item}
          />
        ) : null}
      </>
    </div>
  )
}

export default Offers
