import React, { useEffect, useState } from 'react'
import { apiGet, apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import ErrorMessage from 'components/ErrorMessage'
import OInputField from 'components/reusable/OInputField'
import { useTranslation } from 'react-i18next'
import { preventMaxInput } from 'utils/validations'

const SubCategoryEdit = ({ allSubCategory, currentItem, handleEdit }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cat, setCat] = useState(currentItem?.category_id?._id)
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: currentItem?.name,
      nameAl: currentItem?.nameAl
    }
  })

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      const payload = {
        category_id: cat,
        ...data
      }
      var path = apiPath.subcategoryUpdate + '/' + currentItem._id
      const result = await apiPut(path, payload)
      if (result.data.success) {
        allSubCategory()
        handleEdit(false)
        notification.success(result?.data.message)
      } else {
        notification.error(result?.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error)
      notification.error(error.message)
    }
    setIsLoading(false)
  }

  const allCategory = async payload => {
    try {
      var path = apiPath.categoryAllList
      const result = await apiGet(path, payload)
      if (result?.data?.success) {
        var response = result?.data?.results
        setCategoryList(response)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  const selectCategory = e => {
    if (e.target.value) {
      setCat(e.target.value)
      clearErrors('category_id')
    } else {
      setCat('')
      setError('category_id', {
        type: 'validation',
        message: t('PLEASE_SELECT_CATEGORY')
      })
    }
  }

  useEffect(() => {
    allCategory()
  }, [])

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          {/*content*/}
          <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
              {/*header*/}
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('SUB_EDIT_CATEGORY')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => handleEdit(false)}
                  title={t('CLOSE')}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className='relative p-6 flex-auto'>
                <div className='grid sm:grid-cols-2'>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0  w-full group'>
                      <select
                        type='select'
                        id='category_id'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        value={cat}
                        onChange={selectCategory}
                      >
                        <option value=''>{t('OFFERS_SELECT_CATEGORY')}</option>
                        {categoryList &&
                          categoryList.map(item => {
                            return (
                              <option value={item?._id}>{item?.name}</option>
                            )
                          })}
                      </select>
                      <label
                        htmlFor='category'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('OFFERS_CATEGORY')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <ErrorMessage message={errors?.category_id?.message} />
                    </div>
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='name'
                      id='name'
                      inputLabel={
                        <>
                          {t('SUB_CATEGORY')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      placeholder=''
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register('name', {
                        required: t('PLEASE_ENTER_SUB_CATEGORY'),
                        minLength: {
                          value: 2,
                          message: t('MINIMUM_LENGTH_MUST_BE_2')
                        },
                        pattern: {
                          value: /^[^\s].*/,
                          message: t('CANNOT_START_WITH_A_SPACE')
                        },
                        validate: value => {
                          return !!value.trim()
                            ? true
                            : t('WHITE_APCE_NOT_ALLOWED')
                        }
                      })}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='nameAl'
                      id='nameAl'
                      inputLabel={
                        <>
                          {t('SUB_CATEGORY')} {t('ALBANIAN')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      placeholder=''
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register('nameAl', {
                        required: t('PLEASE_ENTER_SUBCATEGORY_IN_ALBANIAN'),
                        minLength: {
                          value: 2,
                          message: t('MINIMUM_LENGTH_MUST_BE_2')
                        },
                        pattern: {
                          value: /^[^\s].*/,
                          message: t('CANNOT_START_WITH_A_SPACE')
                        },
                        validate: value => {
                          return !!value.trim()
                            ? true
                            : t('WHITE_APCE_NOT_ALLOWED')
                        }
                      })}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => handleEdit(false)}
                >
                  {t('O_BACK')}
                </button>

                {isLoading ? (
                  <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                    <div className='loading-spinner'></div>
                  </div>
                ) : (
                  <button
                    className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('O_EDIT')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  )
}

export default SubCategoryEdit
