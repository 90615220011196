import React, { useEffect, useState } from 'react'
import { apiPost, apiGet } from '../../utils/apiFetch'
import formValidation from '../../utils/formValidation'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../../components/ErrorMessage'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import OInputField from 'components/reusable/OInputField'
import { validationRules } from 'utils/constants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { validateFile } from 'utils/reusableMethods'
import { useTranslation } from 'react-i18next'
import FormData from 'form-data'
import { preventMaxInput } from 'utils/validations'
import { isEmpty } from 'lodash'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

const MerchantAdd = ({ setShowModal, getAllMerchants, setPage }) => {
  const { t } = useTranslation()

  const notification = useToastContext()
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [addMerchantLoading, setAddMerchantLoading] = useState(false)
  const [fileErrors, setFileErrors] = useState({
    identity_card: '',
    passport: ''
  })
  const [recordsTemp, setRecordTemp] = useState('')
  const [listTemp, setListTemp] = useState('')
  const [cat, setCat] = useState('')
  const [subCat, setSubCat] = useState('')
  const [isCatError, setIsCatError] = useState(false)
  const [isSubCatError, setIsSubCatError] = useState(false)
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  })

  useEffect(() => {
    if (watch('identity_card')?.length > 0) {
      const identityMessg = validateFile(watch('identity_card')[0])
      const fileSize = (watch('identity_card')[0].size / 1048576).toFixed(2)
      if (identityMessg) {
        setFileErrors({
          ...fileErrors,
          identity_card: identityMessg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          identity_card: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          identity_card: ''
        })
      }
    }
  }, [watch('identity_card')])

  useEffect(() => {
    if (watch('passport')?.length > 0) {
      const passportMsg = validateFile(watch('passport')[0])
      const fileSize = (watch('passport')[0].size / 1048576).toFixed(2)
      if (passportMsg) {
        setFileErrors({
          ...fileErrors,
          passport: passportMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          passport: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          passport: ''
        })
      }
    }
  }, [watch('passport')])

  useEffect(() => {
    if (watch('business_logo')?.length > 0) {
      const businessLogoMsg = validateFile(watch('business_logo')[0])
      const fileSize = (watch('business_logo')[0].size / 1048576).toFixed(2)
      if (businessLogoMsg) {
        setFileErrors({
          ...fileErrors,
          business_logo: businessLogoMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          business_logo: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          business_logo: ''
        })
      }
    }
  }, [watch('business_logo')])

  const checkFields = () => {
    let isValid = true
    if (!cat && !subCat) {
      setIsCatError(true)
      setIsSubCatError(true)
      isValid = false
    }
    if (!cat) {
      setIsCatError(true)
      isValid = false
    } else {
      setIsCatError(false)
    }
    if (!subCat) {
      setIsSubCatError(true)
      isValid = false
    } else {
      setIsSubCatError(false)
    }
    if (address === '') {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }
    return isValid
  }

  const handleSubmitForm = async data => {
    try {
      const isValid = checkFields()
      if (!isValid) return
      if (fileErrors.identity_card || fileErrors.passport) {
        return
      }

      setAddMerchantLoading(true)
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('identity_card', data.identity_card[0])
      formData.append('passport', data.passport[0])
      formData.append('business_logo', data.business_logo[0])
      formData.append('business_name', data.business_name)
      formData.append('description', data.description)
      formData.append('type', data.type)
      formData.append('mobile', data.mobile)
      formData.append('country_code', 355)
      formData.append('email', data.email)
      formData.append('license', data.license)
      formData.append('password', data.password)
      if (cat) {
        formData.append('category', cat)
      }
      if (subCat) {
        formData.append('sub_category', subCat)
      }
      if (address) {
        formData.append('business_address', address)
      }
      const res = await apiPost(pathObj.addMerchant, formData)
      if (res.data.success === true) {
        getAllMerchants()
        setPage(1)
        setShowModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddMerchantLoading(false)
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }

  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }
  const getCategoryList = async payload => {
    try {
      var path = pathObj.getCategoryList
      const res = await apiGet(path, payload)
      if (res?.data?.success) {
        setRecordTemp(res?.data?.results?.category_list)
        setListTemp(res?.data?.results?.sub_category_list)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  useEffect(() => {
    getCategoryList()
  }, [])

  const preventMaxAddress = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const handleSelect = async value => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setAddress(value)
    setCoordinates(ll)
  }

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])

  const codeValue = watch('email') ? watch('email') : ''

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6  w-full max-w-[1150px] mx-auto'>
          <div className='sm:py-4 sm:px-2 py-8 px-7 '>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('MERCHANT_ADD_MERCHANT')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setShowModal(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              <form
                onSubmit={handleSubmit(handleSubmitForm, () => {
                  const isValid = checkFields()
                  if (!isValid) return
                })}
                method='post'
              >
                <div className='relative p-6 flex-auto'>
                  <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0 mb-6 w-full group'
                        name='first_name'
                        inputLabel={
                          <>
                            {t('MERCHANT_FIRST_NAME')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        autoFocus
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register(
                          'first_name',
                          formValidation['first_name']
                        )}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0 w-full group'
                        name='last_name'
                        inputLabel={
                          <>
                            {t('MERCHANT_LAST_NAME')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register(
                          'last_name',
                          formValidation['last_name']
                        )}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0 w-full group'
                        name='business_name'
                        inputLabel={
                          <>
                            {t('MERCHANT_BUSINESS_NAME')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register(
                          'business_name',
                          formValidation['business_name']
                        )}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0  mb-6 w-full group'
                        type='text'
                        name='email'
                        id='email'
                        inputLabel={
                          <>
                            {t('O_EMAIL_ID')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        value={codeValue.toLowerCase()}
                        maxLength={50}
                        autoComplete='off'
                        onInput={e => preventMaxInput(e, 50)}
                        register={register('email', formValidation['email'])}
                        errors={errors}
                      />
                    </div>

                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <OInputField
                          wrapperClassName='relative z-0 w-full group'
                          type={icon ? 'password' : 'text'}
                          name='password'
                          inputLabel={
                            <>
                              {t('O_PASSWORD')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          errors={errors}
                          maxLength={15}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register('password', {
                            required: t('PLEASE_ENTER_PASSWORD'),
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.passwordMessage
                            }
                          })}
                        />
                        {icon ? (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changeIcon()}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changeIcon()}
                          >
                            <AiFillEye />
                          </span>
                        )}
                      </div>
                    </div>
                    <OInputField
                      name='type'
                      inputLabel='Type'
                      type='hidden'
                      maxLength={50}
                      defaultValue='merchant'
                      register={register('type', {
                        required: t('PLEASE_ENTER_TYPE')
                      })}
                      errors={errors}
                    />
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          type={passwordIcon ? 'password' : 'text'}
                          inputLabel={
                            <>
                              {t('O_CONFIRM_PASSWORD')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          name='confirm_password'
                          id='confirm_password'
                          autoComplete='new-password'
                          maxLength={15}
                          errors={errors}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register('confirm_password', {
                            required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.confirmPasswordMessage
                            },
                            validate: value => {
                              const { password } = getValues()
                              return (
                                password === value ||
                                t(
                                  'PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH'
                                )
                              )
                            }
                          })}
                        />
                        {passwordIcon ? (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changePasswordIcon()}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changePasswordIcon()}
                          >
                            <AiFillEye />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group bg-zinc-200 '>
                        <select
                          type='select'
                          name='country_code'
                          id='country_code'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          {...register('country_code', {})}
                          disabled
                        >
                          <option value='355'>355</option>
                        </select>
                        <label
                          htmlFor='country_code'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-2 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_COUNTRY_CODE')}
                        </label>
                        <ErrorMessage message={errors?.country_code?.message} />
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0  mb-6 w-full group'
                        type='number'
                        name='mobile'
                        id='mobile'
                        inputLabel={
                          <>
                            {t('O_MOBILE_NUMBER')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        min={0}
                        onKeyDown={e => {
                          if (['-', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        onInput={e => preventMaxInput(e, 9)}
                        register={register('mobile', formValidation['mobile'])}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <select
                          type='select'
                          id='category_id'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={cat}
                          onChange={e => {
                            setCat(e.target.value)
                            if (e.target.value === '') setSubCat('')
                            if (e.target.value) {
                              setIsCatError(false)
                            }
                          }}
                        >
                          <option value=''>
                            {t('OFFERS_SELECT_CATEGORY')}
                          </option>
                          {recordsTemp &&
                            recordsTemp.map(item => {
                              return (
                                <option value={item?._id}>{item?.name}</option>
                              )
                            })}
                        </select>
                        <label
                          htmlFor='category'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {' '}
                          {t('OFFERS_CATEGORY')}
                          <span className='text-red-500'>*</span>
                        </label>
                        {isCatError && (
                          <ErrorMessage message={t('PLEASE_SELECT_CATEGORY')} />
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <select
                          type='select'
                          id='category_id'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={subCat}
                          onChange={e => {
                            setSubCat(e.target.value)
                            if (e.target.value) {
                              setIsSubCatError(false)
                            }
                          }}
                        >
                          <option value=''>
                            {t('OFFERS_SELECT_SUBCATEGORY')}
                          </option>
                          {listTemp?.length &&
                            listTemp?.map(
                              (i, key) =>
                                i?.category_id == cat && (
                                  <option key={key} value={i?._id}>
                                    {i?.name}
                                  </option>
                                )
                            )}
                        </select>
                        <label
                          htmlFor='category'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {' '}
                          {t('SUB_CATEGORY')}
                          <span className='text-red-500'>*</span>
                        </label>
                        {isSubCatError && (
                          <ErrorMessage
                            message={t('PLEASE_SELECT_SUB_CATEGORY')}
                          />
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type='text'
                        name='license'
                        id='license'
                        inputLabel={
                          <>
                            {t('LICENSE')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        onInput={e => preventMaxInput(e, 15)}
                        register={register('license', {
                          required: t('PLEASE_ENTER_SHOP_ACT_LICENSE_NUMBER'),
                          pattern: {
                            value: validationRules.removeWhitespace,
                            message: t(
                              'SHOP_ACT_NUMBER_LICENSE_SHOULD_BE_ALPHANUMERIC'
                            )
                          },
                          minLength: {
                            value: 3,
                            message: t('MINIMUM_LENGTH_MUST_BE_3')
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0   mb-6 w-full group'>
                        <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                          <input
                            type='file'
                            name='identity_card'
                            placeholder='Identity Card '
                            className='form-control'
                            accept='image/png,image/jpeg,image/jpg'
                            {...register('identity_card', {
                              required: t('PLEASE_PROVIDE_IDENTITY_CARD')
                            })}
                          />
                        </div>
                        <label
                          htmlFor='floating_file'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_IDENTITY_CARD')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage
                          message={
                            errors?.identity_card?.message ||
                            fileErrors.identity_card
                          }
                        />
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                          <input
                            type='file'
                            name='passport'
                            placeholder='passport'
                            className='form-control'
                            accept='image/*'
                            {...register('passport', {
                              required: t('PLEASE_PROVIDE_PASSPORT')
                            })}
                          />
                        </div>
                        <label
                          htmlFor='floating_file'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_PASSPORT')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage
                          message={
                            errors?.passport?.message || fileErrors.passport
                          }
                        />
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0   w-full group'>
                        <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                          <input
                            type='file'
                            placeholder='Logo'
                            className='form-control'
                            accept='image/*'
                            {...register('business_logo', {
                              required: t('PLEASE_PROVIDE_BUSINESS_LOGO')
                            })}
                          />
                        </div>
                        <label
                          htmlFor='floating_file'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_LOGO')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage
                          message={
                            errors?.business_logo?.message ||
                            fileErrors.business_logo
                          }
                        />
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0  w-full group'>
                        <PlacesAutocomplete
                          value={address}
                          onChange={e => {
                            setAddress(e)
                            if (e) {
                              setAddressError(false)
                            }
                          }}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <div class='relative w-full'>
                              <OInputField
                                {...getInputProps({
                                  type: 'text',
                                  name: 'business_address',
                                  errors: errors,
                                  id: 'business_address',
                                  inputLabel: (
                                    <>
                                      {t('MERCHANT_BUSINESS_ADDRESS')}
                                      <span className='text-red-500'>*</span>
                                    </>
                                  ),
                                  onInput: e => preventMaxAddress(e)
                                })}
                                businessMargin
                              />
                              {addressError && (
                                <ErrorMessage
                                  message={t('PLEASE_SELECT_BUSINESS_ADDRESS')}
                                />
                              )}
                              <span className='absolute right-[10px] top-[10px] p-2 bg-white '>
                                {' '}
                                <img
                                  src='./images/location-outline.png'
                                  alt='location-logo'
                                />
                              </span>
                              <div className='autocomplete-dropdown-container'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item'
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer'
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer'
                                      }
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0  w-full group'>
                        <textarea
                          name='description'
                          rows='3'
                          id='description'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          maxLength={200}
                          onInput={e => preventMaxInput(e, 200)}
                          {...register('description', {
                            required: t('PLEASE_ENTER_DESCRIPTION'),
                            pattern: {
                              value: /^[^\s].*/,
                              message: t('CANNOT_START_WITH_A_SPACE')
                            },
                            maxLength: {
                              value: 200,
                              message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                            },
                            minLength: {
                              value: 10,
                              message: t('MINIMUM_CHARACTER_CAN_WE_MUST_10')
                            },
                            validate: value => {
                              return value.trim()
                                ? true
                                : t('WHITE_APCE_NOT_ALLOWED')
                            }
                          })}
                        />

                        <label
                          htmlFor='description'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('OFFERS_DESCRIPTION')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.description?.message} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    {t('O_BACK')}
                  </button>

                  {addMerchantLoading ? (
                    <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                      <div className='loading-spinner'></div>
                    </div>
                  ) : (
                    <button
                      className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                    >
                      {t('O_ADD')}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default MerchantAdd
