import React, { useEffect, useState } from 'react'
import { apiPost, apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../../components/ErrorMessage'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { IoMdAdd } from 'react-icons/io'
import { TiDelete } from 'react-icons/ti'
import { isEmpty } from 'lodash'
import OInputField from 'components/reusable/OInputField'
import ODatePicker from 'components/shared/datePicker/ODatePicker'
import { useTranslation } from 'react-i18next'
import formValidation from '../../utils/formValidation'
import { preventMaxInput } from 'utils/validations'

const AddOffer = ({ setShowModal, getAllOffers, setPage }) => {
  const { t } = useTranslation()
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(true)
  const [date, setDate] = useState(null)
  const discountMessage = ''
  const validate = () => {
    if (!isCheckAll) {
      if (!isSpecific) {
        setMessage('Please assign offer to user.')
        return false
      }
      if (isSpecific) {
        if (isEmpty(userIds)) {
          setMessage('Please assign offer to user.')
          return false
        }
      }
      return true
    } else if (!isSpecific) {
      if (!isCheckAll) {
        setMessage('Please assign offer to user.')
        return false
      }
      return true
    }
  }
  const notification = useToastContext()

  const [addMerchantLoading, setAddMerchantLoading] = useState(false)
  const handleSubmitForm = async ({
    title,
    titleAl,
    category,
    discountType,
    discount,
    code,
    description,
    descriptionAl
  }) => {
    try {
      const isValid = validate()
      if (!isValid) {
        setIsError(true)
        return
      }
      if (date === null) {
        setIsValidityError(true)
        return
      } else {
        setIsValidityError(false)
      }

      setIsError(false)
      setAddMerchantLoading(true)
      const sendData = {
        title,
        titleAl,
        discount_type: discountType,
        category,
        code,
        discount,
        description,
        descriptionAl,
        available_for: availableFor,
        user_ids: JSON.stringify(userIds),
        validity: date ? dayjs(date).format('YYYY-MM-DD') : null
      }
      if (date) {
        sendData.validity = date
      }
      const res = await apiPost(apiPath.getAllOffers, sendData)
      if (res.data.success === true) {
        setShowModal(false)
        setPage(1)
        notification.success(res.data.message)
      } else {
        notification.error(res.data.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setAddMerchantLoading(false)
      getAllOffers()
    }
  }

  const [keyword, setKeyword] = useState('')
  const [availableUser, setAvailableUser] = useState({})
  const [availableFor, setAvailableFor] = useState('all')
  const [userIds, setUserIds] = useState([])
  const [isSpecific, setIsSpecific] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(true)
  const [isValidityError, setIsValidityError] = useState(false)
  const [userNameArray, setUserNameArray] = useState([])

  const discountTypeOptions = [
    {
      key: 'Fixed',
      value: 'fixed'
    },
    {
      key: 'Percentage',
      value: 'percentage'
    }
  ]

  const categoryOptions = [
    {
      key: 'Wallet',
      value: 'wallet'
    },
    {
      key: 'Recharge',
      value: 'recharge'
    },
    {
      key: 'Utilities',
      value: 'utilities'
    }
  ]
  const getAllUser = async payload => {
    try {
      const payloadUser = {
        keyword: keyword.trim()
      }
      const path = apiPath.getUsers
      const result = await apiGet(path, payloadUser)
      if (result?.data?.results.docs.length > 0) {
        const response = result?.data?.results.docs
        response.map(user => setAvailableUser({ ...availableUser, ...user }))
      } else {
        setAvailableUser('')
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  useEffect(() => {
    if (keyword) {
      getAllUser()
    }
  }, [keyword])

  const preventMinus = e => {
    if (watch('discountType') === 'percentage' && e.target.value > 10) {
      e.preventDefault()
    } else if (e.target.value > 9999999) {
      e.preventDefault()
    }
  }

  const codeValue = watch('code') ? watch('code') : ''

  const handleDateChange = date => {
    setDate(date)
    setIsValidityError(false)
  }

  const handleDiscount = () => {
    reset({ discount: null })
  }
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6 w-full max-w-3xl'>
          {/* content */}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/* header */}
            <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-xl font-semibold'>{t('OFFER_ADD_OFFER')}</h3>
              <button
                className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                onClick={() => setShowModal(false)}
              >
                <span className=' text-[#B8BBBF]  text-4xl ' title={t('CLOSE')}>
                  ×
                </span>
              </button>
            </div>
            <div className='relative p-6 flex-auto'>
              <div className='grid sm:grid-cols-2'>
                <div className='sm:py-4 sm:px-2'>
                  <div className='relative z-0  w-full group'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='title'
                      inputLabel={
                        <>
                          {t('OFFERS_TITLE')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      maxLength={15}
                      autoFocus
                      onInput={e => preventMaxInput(e, 15)}
                      register={register('title', formValidation['title'])}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className='sm:py-4 sm:px-2'>
                  <div className='relative z-0  w-full group'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='titleAl'
                      inputLabel={
                        <>
                          {t('OFFERS_TITLE') + t('ALBANIAN')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      register={register('titleAl', formValidation['titleAl'])}
                      errors={errors}
                    />
                  </div>
                </div>

                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0   w-full group'>
                    <select
                      name='discountType'
                      id='discountType'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      required
                      {...register('discountType', {
                        required: t('PLEASE_SELECT_DISCOUNT_TYPE')
                      })}
                      onChange={handleDiscount}
                    >
                      <option value=''>{t('SELECT_DISCOUNT_TYPE')}</option>
                      {discountTypeOptions.map(({ key, value }) => (
                        <option value={value} key={key}>
                          {key}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor='discountType'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('OFFERS_DISCOUNT_TYPE')}
                      <span className='text-red-500'>*</span>
                    </label>
                    <ErrorMessage message={errors?.discountType?.message} />
                  </div>
                </div>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <OInputField
                    wrapperClassName='relative z-0   w-full group'
                    name='code'
                    inputLabel={
                      <>
                        {t('OFFERS_CODE')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    type='text'
                    value={codeValue.toUpperCase()}
                    maxLength={15}
                    onInput={e => preventMaxInput(e, 15)}
                    register={register('code', formValidation['code'])}
                    errors={errors}
                  />
                </div>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0   w-full group'>
                    <select
                      type='select'
                      name='category'
                      id='category'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      {...register('category', {
                        required: t('PLEASE_SELECT_CATEGORY')
                      })}
                    >
                      <option value=''>{t('OFFERS_SELECT_CATEGORY')}</option>
                      {categoryOptions.map(({ key, value }) => (
                        <option value={value} key={key}>
                          {key}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor='category'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('OFFERS_CATEGORY')}
                      <span className='text-red-500'>*</span>
                    </label>
                    <ErrorMessage message={errors?.category?.message} />
                  </div>
                </div>

                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0  w-full group'>
                    <input
                      type='number'
                      name='discount'
                      id='discount'
                      maxLength={30}
                      onKeyPress={preventMinus}
                      onKeyDown={e => {
                        if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab', '.'].includes(
                            e.key
                          ) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      {...register('discount', {
                        required: t('PLEASE_ENTER_DISCOUNT'),
                        validate: val => {
                          if (val > 0) {
                            if (watch('discountType') === 'percentage') {
                              if (val > 100) {
                                return t('PERCENTAGE_SHOULD_BE_LESS_THAN_100')
                              }
                            } else {
                              if (val > 99999999) {
                                return t('MAXIMUM_LENGTH_MUST_BE_8_DIGITS')
                              }
                            }
                          } else {
                            return t(
                              'MINIMUM_DISCOUNT_SHOULD_BE_GREATER_THAN_0'
                            )
                          }
                        }
                      })}
                    />
                    <label
                      htmlFor='discount'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('OFFERS_DISCOUNT')}
                      <span className='text-red-500'>*</span>
                    </label>
                    <ErrorMessage
                      message={errors?.discount?.message || discountMessage}
                    />
                  </div>
                </div>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0  w-full group'>
                    <ODatePicker
                      name='validity'
                      id='validity'
                      handleDateChange={handleDateChange}
                    />
                    <label
                      htmlFor='validity'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('VALIDITY')}
                      <span className='text-red-500'>*</span>
                    </label>

                    {isValidityError && (
                      <ErrorMessage message={t('PLEASE_SELECT_VALIDITY')} />
                    )}
                  </div>
                </div>

                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0  w-full group'>
                    <textarea
                      name='description'
                      rows='3'
                      id='description'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      maxLength={200}
                      onInput={e => preventMaxInput(e, 200)}
                      {...register('description', {
                        required: t('PLEASE_ENTER_DESCRIPTION'),
                        pattern: {
                          value: /^[^\s].*/,
                          message: t('CANNOT_START_WITH_A_SPACE')
                        },
                        maxLength: {
                          value: 200,
                          message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                        },
                        minLength: {
                          value: 10,
                          message: t('MINIMUM_CHARACTER_CAN_WE_MUST_10')
                        },
                        validate: value => {
                          return value.trim()
                            ? true
                            : t('WHITE_APCE_NOT_ALLOWED')
                        }
                      })}
                    />

                    <label
                      htmlFor='description'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('OFFERS_DESCRIPTION')}
                      <span className='text-red-500'>*</span>
                    </label>
                    <ErrorMessage message={errors?.description?.message} />
                  </div>
                </div>
                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <div className='relative z-0  w-full group'>
                    <textarea
                      name='descriptionAl'
                      rows='3'
                      id='descriptionAl'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      {...register('descriptionAl', {
                        required: t('PLEASE_ENTER_DESCRIPTION_IN_ALBANIAN'),
                        pattern: {
                          value: /^[^\s].*/,
                          message: t('CANNOT_START_WITH_A_SPACE')
                        },
                        maxLength: {
                          value: 200,
                          message: t('MAXIMUM_CHARACTER_CAN_BE_1000')
                        },
                        minLength: {
                          value: 10,
                          message: t('MINIMUM_CHARACTER_CAN_WE_MUST_10')
                        },
                        validate: value => {
                          return value.trim()
                            ? true
                            : t('WHITE_APCE_NOT_ALLOWED')
                        }
                      })}
                    />

                    <label
                      htmlFor='descriptionAl'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('OFFERS_DESCRIPTION') + t('ALBANIAN')}
                      <span className='text-red-500'>*</span>
                    </label>
                    <ErrorMessage message={errors?.descriptionAl?.message} />
                  </div>
                </div>

                <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                  <p className='text-LightBlue mb-1'>
                    {t('OFFERS_ASSIGN_OFFER_TO_USERS')}
                  </p>
                  <div>
                    <div>
                      <div className='form-check'>
                        <input
                          className='mr-2 cursor-pointer'
                          type='checkbox'
                          id='allUser'
                          checked={isCheckAll}
                          onChange={event => {
                            if (event.target.checked) {
                              setAvailableFor('all')
                              setIsSpecific(false)
                              setIsError(false)
                            }
                            setIsCheckAll(event.target.checked)
                          }}
                        />
                        <label
                          className='form-check-label inline-block text-gray-800 cursor-pointer'
                          htmlFor='allUser'
                        >
                          {t('OFFERS_ALL_USER')}
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          id='specificUser'
                          className='mr-2 cursor-pointer '
                          type='checkbox'
                          checked={isSpecific}
                          onChange={event => {
                            setIsSpecific(event.target.checked)
                            setIsCheckAll(false)
                            if (!event.target.checked) {
                              setUserIds([])
                              setUserNameArray([])
                            }
                          }}
                        />
                        <label
                          className='form-check-label inline-block text-gray-800 cursor-pointer'
                          htmlFor='specificUser'
                        >
                          {t('OFFERS_SPECIFIC_USERS')}
                        </label>
                        {isError && <ErrorMessage message={message} />}
                      </div>
                    </div>
                  </div>
                </div>
                {isSpecific && (
                  <>
                    <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                      <div className='relative '>
                        <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                          {!keyword && (
                            <svg
                              aria-hidden='true'
                              className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                              fill='none'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                stroke-width='2'
                                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                              />
                            </svg>
                          )}
                        </div>
                        <input
                          type='search'
                          name='keyword'
                          title=''
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                          placeholder={t('O_SEARCH_BY_EMAIL_OR_MOBILE')}
                          value={keyword}
                          onChange={event => setKeyword(event.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {isSpecific && keyword && availableUser ? (
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <button
                      id='code'
                      disabled={
                        !!userNameArray.some(
                          previous => previous._id === availableUser._id
                        )
                      }
                      maxLength={40}
                      className='block flex items-center justify-center py-4 px-3 w-full text-base text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                      onClick={() => {
                        const checkUser = userNameArray.some(
                          previous => previous._id === availableUser._id
                        )
                        if (!checkUser) {
                          setUserNameArray(item => item.concat(availableUser))
                          setKeyword('')
                          setAvailableFor('user')

                          setUserIds(ids => ids.concat(availableUser?._id))
                        }
                        setIsError(false)
                      }}
                    >
                      <IoMdAdd className='w-5 h-5' />
                      {availableUser?.name}
                    </button>
                  </div>
                ) : (
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7' />
                )}
              </div>

              <div className='grid sm:grid-cols-2 max-h-[200px] overflow-y-auto'>
                {isSpecific &&
                  userNameArray?.length !== 0 &&
                  userNameArray.map(item => (
                    <div
                      key={item._id}
                      className='md:py-4 sm:px-2 sm:py-8 px-7'
                    >
                      <div>
                        <div className='p-3 mt-2 rounded-md  w-full text-black text-base border-2 flex items-center justify-between'>
                          {item.name}
                          <div>
                            <TiDelete
                              className='w-7 h-7 cursor-pointer'
                              onClick={() => {
                                setUserIds(
                                  userIds.filter(id => id !== item._id)
                                )
                                setUserNameArray(
                                  userNameArray.filter(
                                    user => user._id !== item._id
                                  )
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                type='button'
                onClick={() => setShowModal(false)}
              >
                {t('O_BACK')}
              </button>

              {addMerchantLoading ? (
                <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                  <div className='loading-spinner'></div>
                </div>
              ) : (
                <button
                  className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                  type='submit'
                  onClick={handleSubmit(handleSubmitForm, () => {
                    if (!date) {
                      setIsValidityError(true)
                    }
                  })}
                >
                  {t('O_ADD')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default AddOffer
