import React from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OImage from 'components/reusable/OImage'
import { validationRules } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

const OperatorView = ({ setViewShowModal, getAllUser, item }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      first_name: item?.first_name,
      last_name: item?.last_name,
      mobile: item?.mobile,
      email: item?.email,
      country_code: item?.country_code,
      categoryData: item?.categoryData?.name,
      subCategoryData: item?.subCategoryData?.name,
      description: item?.description,
      license: item?.license,
      business_name: item?.business_name,
      business_address: item?.business_address,
      createdAt: dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A'),
      kyc: item?.kyc_verified ? 'Verified' : 'Unverified'
    }
  })

  const onSubmit = async data => {
    try {
      const path = apiPath.updateUser + '/' + item._id
      const result = await apiPut(path, data)
      if (result.data.success) {
        getAllUser()
        setViewShowModal(false)
        notification.success(result?.data.message)
      } else {
        setViewShowModal(false)
        notification.error(result?.data.message)
      }
    } catch (error) {
      notification.error(error.message)
    }
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl h-full'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='sm:py-4 sm:px-2 py-8 px-7'
          >
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>{t('VIEW_OPERATOR')}</h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setViewShowModal(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className='relative p-6 flex-auto pb-0'>
                <div className='grid grid-cols-2'>
                  <div>
                    <div className='sm:py-4 sm:px-2 py-8 px-7'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_email'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          {...register('first_name', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_FIRST_NAME')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_email'
                          id='floating_name'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          disabled
                          {...register('country_code', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_COUNTRY_CODE')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('categoryData', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('OFFERS_CATEGORY')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('license', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('LICENSE')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('createdAt', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_CREATED_AT')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('business_address', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('MERCHANT_BUSINESS_ADDRESS')}
                        </label>
                      </div>

                      <div className='relative z-0 mb-6  group ml-4'>
                        <label className='text-[#A5A5A5] dark:text-gray-400 w-24 text-center'>
                          {t('O_IDENTITY_CARD')}
                        </label>
                        <OImage
                          src={item?.identity_card}
                          className='w-24 h-24 text-sm '
                          alt='id'
                        />
                      </div>

                      <div className='relative z-0  group ml-4'>
                        <label className='text-[#A5A5A5] dark:text-gray-400 w-24 text-center'>
                          {t('O_PASSPORT')}
                        </label>
                        <OImage
                          src={item?.passport}
                          className='w-24 h-24 text-sm '
                          alt='id'
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='sm:py-4 sm:px-2 py-8 px-7'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('last_name', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_LAST_NAME')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='email'
                          name='email'
                          id='email'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('email', {
                            required: 'Please enter email.',
                            pattern: {
                              value: validationRules.email,
                              message:
                                'Invalid email address (Ex: example@domain.com).'
                            }
                          })}
                        />
                        <label
                          htmlFor='floating_email1'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_EMAIL_ID')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='number'
                          name='mobile'
                          id='mobile'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          disabled
                          {...register('mobile', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='mobile'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_MOBILE_NUMBER')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('subCategoryData', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('MERCHANT_SUBCATEGORY_DATA')}
                        </label>
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='floating_name'
                          id='floating_name'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('description', {
                            required: true
                          })}
                        />
                        <label
                          htmlFor='floating_name'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('OFFERS_DESCRIPTION')}
                        </label>
                      </div>
                      <div
                        className='relative z-0 mb-6 w-full group'
                        style={{ height: '56px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setViewShowModal(false)}
                >
                  {t('O_BACK')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default OperatorView
