import React, { useContext, useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import apiPath from '../../utils/pathObj'
import { apiPost } from '../../utils/apiFetch'
import useToastContext from 'hooks/useToastContext'
import View from './View'
import dayjs from 'dayjs'
import { BsArrowUpShort } from 'react-icons/bs'
import helper from '../../utils/helpers'
import classNames from 'classnames'
import AuthContext from 'context/AuthContext'

const Table = ({ requests, getAllLoanRequest, user, sort, setSort, page }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const { language } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [loanRequest, setLoanRequest] = useState({})
  const handleViewClick = (item, e) => {
    setLoanRequest(item)
    setShowModal(true)
  }

  const changeStatus = async (id, status) => {
    try {
      const payload = {
        id,
        status: status.status
      }
      const path = apiPath.ChangeStatusMicroCreditLoanRequests
      const res = await apiPost(path, payload)
      if (res?.status === 200) {
        if (res.data.success === true) {
          notification.success(res.data.message)
          getAllLoanRequest()
        } else {
          notification.error(res?.data?.message)
        }
      }
    } catch (error) {
      console.log('error ', error)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('ORDER_CUSTOMER_NAME')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('MICRO_LOAN')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('LONE_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('TENURE')} (Days)
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')} (+355)
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_EMAIL_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('MICRO_INTEREST_RATE')} (%)
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')}</span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
              {(user?.permission?.[11]?.add ||
                user?.permission?.length === 0) && (
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_STATUS')}
                </th>
              )}
              <th scope='col' className='py-3 px-6 text-left'>
                {t('TAKE_ACTIONS')}
              </th>
            </tr>
          </thead>
          <tbody>
            {requests &&
              requests?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td scope='row' className='py-4 px-6 border-r'>
                    {item?.firstName} {item?.lastName}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r  dark:text-white'
                  >
                    {item?.loanCreditTitle}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r text-right dark:text-white'
                  >
                    {item?.loanAmount}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r text-right dark:text-white'
                  >
                    {item?.tenure}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r text-right dark:text-white'
                  >
                    {item?.mobile}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r  dark:text-white'
                  >
                    {item?.email}
                  </td>
                  <td
                    scope='row'
                    className='py-4 px-6 border-r text-right dark:text-white'
                  >
                    {item?.interestRate?.toFixed(2)}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  {(user?.permission?.[11]?.add ||
                    user?.permission?.length === 0) && (
                    <td className='py-4 px-6 border-r text-green-700'>
                      {startCase(item?.status)}
                    </td>
                  )}
                  <td className='py-4 px-6 border-r flex items-center justify-center'>
                    <span
                      href=''
                      className='mr-2'
                      data-modal-target='defaultModal'
                      data-modal-toggle='defaultModal'
                      onClick={handleViewClick.bind(this, item)}
                      title={t('O_VIEW')}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 cursor-pointer'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                        />
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                      </svg>
                    </span>
                    {item?.status === 'pending' ? (
                      <>
                        <span
                          title={t('ACCEPT_LOAN')}
                          className='text-green-600 cursor-pointer'
                          onClick={e =>
                            helper.alertFunction(
                              `${t(
                                'ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THE_MANAGE_MICRO_CREDIT_REQUEST'
                              )}?`,
                              item,
                              () =>
                                changeStatus(item?._id, { status: 'approved' }),
                              '',
                              language
                            )
                          }
                        >
                          {startCase(t('APPROVE'))}
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <span
                          title={t('CANCEL_LOAN')}
                          className='text-red-600 cursor-pointer'
                          onClick={e =>
                            helper.alertFunction(
                              `${t(
                                'ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THE_MANAGE_MICRO_CREDIT_REQUEST'
                              )}?`,
                              item,

                              () =>
                                changeStatus(item?._id, {
                                  status: 'cancelled'
                                }),
                              '',
                              language
                            )
                          }
                        >
                          {startCase(t('CANCEL'))}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                    {item?.status !== 'pending' ? (
                      <span
                        title={startCase(item.status)}
                        className={classNames({
                          'text-green-600': item.status === 'approved',
                          'text-red-600': item.status === 'cancelled'
                        })}
                      >
                        {' '}
                        {startCase(item.status)}{' '}
                      </span>
                    ) : null}
                  </td>
                </tr>
              ))}
            {isEmpty(requests) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={11}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {showModal ? (
        !isEmpty(loanRequest) ? (
          <View setShowModal={setShowModal} request={loanRequest} />
        ) : null
      ) : null}
    </div>
  )
}

export default Table
