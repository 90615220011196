import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPut } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import OInputField from 'components/reusable/OInputField'
import formValidation from '../../utils/formValidation'

const Settings = () => {
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {}
  })
  const [settingChangeLoading, setSettingChangeLoading] = useState(false)
  const notification = useToastContext()

  const handleSubmitForm = async data => {
    try {
      setSettingChangeLoading(true)
      data.conversion_rate = parseInt(data.conversion_rate)
      const res = await apiPut(pathObj.updateSettings, data)
      if (res.data.success === true) {
        getSettings()
        notification.success(res?.data?.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setSettingChangeLoading(false)
    }
  }

  const getSettings = async () => {
    try {
      const res = await apiGet(pathObj.getSettings)
      if (res) {
        reset(res?.data?.results)
      }
    } catch (error) {
      console.log('error:', error)

      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getSettings()
  }, [])
  const preventMax = e => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
  }
  const preventMaxValue = e => {
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.slice(0, 5)
    }
  }
  const preventMaxHundred = e => {
    if (e.target.value > 100) {
      e.target.value = e.target.value.slice(0, 2)
    }
  }
  useEffect(() => {
    updatePageName(t('NAV_SETTINGS'))
  }, [])
  var pressedKeys = []

  return (
    <section className=''>
      <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
        <section className='sm:px-8 px-4 py-4 '>
          <div className='border xl:w-full round'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>
                {t('SETTINGS_TRANSFER_SETTING')}
              </div>
            </header>
            <div className='bg-white py-6 px-4  rounded-b-md'>
              <main className='justify-center flex flex-wrap grid  xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('SETTINGS_MINIMUM_WITHDRAWAL_AMOUNT')} (
                          {t('O_LEK')})<span className='text-red-500'>*</span>
                        </>
                      }
                      type='number'
                      name='minimum_withdrawal_request'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      id='minimum_withdrawal_request'
                      register={register(
                        'minimum_withdrawal_request',
                        formValidation['minimum_withdrawal_request']
                      )}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_REFERRAL_BONUS')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      type='number'
                      name='referral_bonus'
                      id='referral_bonus'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('referral_bonus', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_REFERRAL_BONUS')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        maxLength: {
                          value: 5,
                          message: t('MAXIMUM_LIMIT_IS_5_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_SIGN_IN_BONUS')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      type='number'
                      name='signin_bonus'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('signin_bonus', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_SIGN_IN_BONUS')
                        },
                        maxLength: {
                          value: 5,
                          message: t('MAXIMUM_LIMIT_IS_5_CHARACTER')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_ADD_MONEY_LIMIT')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      type='number'
                      name='add_money_limit'
                      onInput={e => preventMaxValue(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('add_money_limit', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_ADD_MONEY_LIMIT')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        maxLength: {
                          value: 5,
                          message: t('MAXIMUM_LIMIT_IS_5_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_WITHDRAW_MONEY_TO_BANK_LIMIT')} (
                          {t('O_LEK')})<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='witdraw_money_to_bank_limit'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('witdraw_money_to_bank_limit', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_WITHDRAW_MONEY_TO_BANK')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_TRANSFER_MONEY_TO_BANK_LIMIT')} (
                          {t('O_LEK')})<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='transfer_money_to_bank_limit'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('transfer_money_to_bank_limit', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_TRANSFER_MONEY_TO_BANK')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_TRANSACTION_FEE')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='transaction_fee'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('transaction_fee', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_TRANSACTION_FEE')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>

                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_FACILITY_FEE')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='facility_fee'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('facility_fee', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_FACILITY_FEE')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>

                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_BUYING_DEALS')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='buying_deals'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('buying_deals', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_BUYING_DEALS')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>

                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('O_NFC_PAYMENT')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='nfc_payment'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('nfc_payment', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_NFC_PAYMENT')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>

                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('O_TRANSACTION_LIMIT')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='transactionLimit'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('transactionLimit', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_TRANSACTION_LIMIT')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('O_TRANSACTION_IN_DAY')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='transactionInDay'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('transactionInDay', {
                        required: {
                          value: true,
                          message: t(
                            'PLEASE_ENTER_ALLOWED_TRANSACTION_IN_A_DAY'
                          )
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>

          <div className='border xl:w-full rounded mt-5'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>{t('SETTINGS_COMMISSION')}</div>
            </header>
            <div className='bg-white py-6 px-4  rounded-b-md'>
              <main className='justify-center flex flex-wrap grid  lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_CASH_IN_COMMISSION')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='cash_in_commission'
                      id='cash_in_commission'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('cash_in_commission', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_CASH_IN_COMISSION')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_CASH_OUT_COMMISSION')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='cash_out_commission'
                      id='cash_out_commission'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('cash_out_commission', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_CASH_OUT_COMISSION')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_DEALS_COMMISSION')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='deals_commission'
                      id='deals_commission'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('deals_commission', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_DEALS_COMISSION')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_TRANSFER_TO_BANK_CUSTOMER_COMMISSION')}{' '}
                          (%)<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='customerWithdrawToBank'
                      id='customerWithdrawToBank'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('customerWithdrawToBank', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_TRANSFER_TO_BANK_COMISSION')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_TRANSFER_TO_BANK_MERCHANT_COMMISSION')}{' '}
                          (%)<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='merchantWithdrawToBank'
                      id='merchantWithdrawToBank'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('merchantWithdrawToBank', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_TRANSFER_TO_BANK')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_CASH_TRANSFER_COMMISION')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='cash_transfer_commision'
                      id='cash_transfer_commision'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('cash_transfer_commision', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_CASH_TRANSFER_COMISSION')
                        },
                        min: {
                          value: 0.0,
                          message: t('MINIMUM_VALUE_MUST_BE_0.0')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_OPERATOR_CASH_IN_COMMISSION')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='operator_cash_in_commission'
                      id='operator_cash_in_commission'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('operator_cash_in_commission', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_OPERATOR_CASH_IN_COMISSION')
                        },
                        min: {
                          value: 0.0,
                          message: t('MINIMUM_VALUE_MUST_BE_0.0')
                        }
                      })}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>

          <div className='border xl:w-full rounded mt-5'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>
                {t('SETTINGS_LOYALTY_POINTS')}
              </div>
            </header>
            <div className='bg-white py-6 px-4  rounded-b-md'>
              <main className='justify-center flex flex-wrap grid  lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_NUMBER_OF_LOYALTY_POINTS')} (
                          {t('SETTINGS_POINTS')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='conversion_point'
                      id='conversion_point'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('conversion_point', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTE_NUMBER_OF_LOYALTY_POINT')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_CONVERSION_RATE_OF_LOYALTY_POINT')} (
                          {t('O_LEK')})<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='conversion_rate'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('conversion_rate', {
                        required: {
                          value: true,
                          message: t(
                            'PLEASE_ENTE_CONVERSION_RATE_OF_LOYALTY_POINT'
                          )
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_UTILITY_LOYALTY_POINT')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='utilityLoyaltyPoint'
                      id='utilityLoyaltyPoint'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('utilityLoyaltyPoint', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTE_UTILITY_LOYALTY_POINT')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full  '>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_THRESHOLD_VALUE_OF_REDEEM_POINT')} (
                          {t('O_LEK')})<span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='threshold_value_to_redeem_point'
                      type='number'
                      onInput={e => preventMax(e)}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('threshold_value_to_redeem_point', {
                        required: {
                          value: true,
                          message: t(
                            'PLEASE_ENTE_THTESHOLD_VALUE_TO_REDDEM_POINT'
                          )
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('SETTINGS_DEALS_LOYALTY_POINT')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      name='dealsLoyaltyPoint'
                      id='dealsLoyaltyPoint'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('dealsLoyaltyPoint', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_DEALS_LOYALTY_POINTS')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </section>

        <div className='mt-4 text-center'>
          {settingChangeLoading &&
          (user?.permission?.[17]?.add || user.permission?.length === 0) ? (
            <div
              className='max-w-[100px] block spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
              style={{ margin: '0 auto' }}
            >
              <div className='loading-spinner'></div>
            </div>
          ) : (
            <button
              disabled={!isDirty}
              className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
              type='submit'
            >
              {t('O_UPDATE')}
            </button>
          )}
        </div>
      </form>
    </section>
  )
}

export default Settings
