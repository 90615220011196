import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import dayjs from 'dayjs'
import AuthContext from 'context/AuthContext'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'

function TransactionManager () {
  const { t } = useTranslation()
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [transactions, setTransactions] = useState([])
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [isReset, setIsReset] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })

  const [transactionState, setTransactionState] = useState({
    start_date: '',
    end_date: '',
    transaction_type: '',
    keyword: '',
    user_id: ''
  })

  const handleChange = event => {
    setTransactionState({
      ...transactionState,
      [event.target.name]: event.target.value
    })
    setPage(1)
  }
  const { transaction_type, keyword } = transactionState

  // get all transactions function-
  const getTransactions = async () => {
    try {
      const payload = {
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        transaction_type,
        keyword,
        page,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }

      const path = apiPath.getTransactions
      const result = await apiGet(path, payload)

      const response = result?.data?.results
      const resultStatus = result?.data?.success
      setTransactions(response?.docs)
      setPaginationObj({
        ...paginationObj,
        page: resultStatus ? response.page : null,
        pageCount: resultStatus ? response.totalPages : null,
        perPageItem: resultStatus ? response?.docs.length : null,
        totalItems: resultStatus ? response.totalDocs : null
      })
    } catch (error) {
      console.log('error in get all transactions list==>>>>', error)

      if (error.response?.status === 401 || error.response?.status === 409) {
        logoutUser()
      }
    }
  }
  useEffect(() => {
    getTransactions()
  }, [transaction_type, keyword, startDate, endDate, sort, page])

  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    setIsReset(true)
    setTransactionState({
      ...transactionState,
      startDate: '',
      endDate: '',
      transaction_type: '',
      keyword: '',
      user_id: ''
    })
    setPage(1)
    setSearchTerm('')
  }
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
    setIsReset(false)
  }
  const transactionTypeOptions = [
    {
      key: <>{t('O_WALLET_TO_WALLET_TRANSACTION')}</>,
      value: 'wallet_to_wallet'
    },
    {
      key: <>{t('O_WALLET_TO_BANK')}</>,
      value: 'walletToBank'
    },
    {
      key: <>{t('O_NFC_PAYMENTS')}</>,
      value: 'nfc'
    },
    {
      key: <>{t('O_ADD_MONEY_TO_WALLET_TRANSACTION')}</>,
      value: 'add_money_to_wallet'
    },
    {
      key: <>{t('O_DEAL_PAYMENT')}</>,
      value: 'dealPayments'
    }
  ]
  const handleDateChange = (start, end) => {
    setStartDate(start)
    setPage(1)
    setEndDate(end)
    setIsReset(false)
  }

  useEffect(() => {
    setTransactionState({
      ...transactionState,
      keyword: debouncedSearchTerm
    })
    setPage(1)
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    updatePageName(t('NAV_TRANSACTION_MANAGER'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='col-span-2 flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={isReset}
                    setIsReset={setIsReset}
                  />

                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='transaction_type'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={transaction_type}
                      onChange={handleChange}
                    >
                      <option value=''>{t('O_SELECT_TRANSACTION_TYPE')}</option>
                      {transactionTypeOptions.map(({ key, value }, ind) => (
                        <option value={value} key={ind}>
                          {key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center'>
                <div className='relative'>
                  <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                    {!searchTerm ? (
                      <svg
                        aria-hidden='true'
                        className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                        />
                      </svg>
                    ) : (
                      ''
                    )}
                  </div>
                  <input
                    type='search'
                    id='default-search'
                    name='keyword'
                    title=''
                    className='block w-64 p-2 outline-none text-sm text-gray-900 2xl:min-w-[200px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder={t('O_SEARCH_BY_USER_NAME')}
                    required
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <Table
              transactions={transactions}
              page={paginationObj.page}
              setSort={setSort}
              sort={sort}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionManager
