import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import OperatorTable from './OperatorTable'
import OperatorAdd from './OperatorAdd'
import OperatorEdit from './OperatorEdit'
import OperatorView from './OperatorView'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'

function Operator () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)

  const [showModal, setShowModal] = useState(false)
  const [editshowModal, setEditShowModal] = useState(false)

  const [viewShowModal, setViewShowModal] = useState(false)
  const [operators, setOperators] = useState([])
  const [page, setPage] = useState(1)
  const [type] = useState('')
  const [item, setItem] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })
  const size = 10
  const [filterData, setFilterData] = useState({
    category: '',
    searchkey: '',
    startDate: '',
    endDate: '',
    isReset: false,
    isFilter: false
  })

  const getAllOperators = async data => {
    let result
    try {
      const { category, startDate, endDate, searchkey, isFilter } = filterData

      if (
        (data?.deletePage && operators?.length >= 1) ||
        (isFilter && category && data?.statusChange && operators?.length >= 1)
      ) {
        setPage(page - 1)
        setPaginationObj({ ...paginationObj, page: page - 1 })
      } else {
        setIsDelete(false)
      }
      const payload = {
        page,
        size,
        type,
        status: category,
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        keyword: searchkey,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }

      const path = apiPath.getOperatorList
      result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        setOperators(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('error in get all merchants list==>>>> result:', error)
      setPaginationObj({})
      if (error?.response?.status === 401 || error?.response?.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handelEdit = item => {
    setItem(item)
    setEditShowModal(true)
  }
  const handleUserView = item => {
    setItem(item)
    setViewShowModal(true)
  }
  useEffect(() => {
    getAllOperators()
  }, [page, filterData, sort])

  const handleReset = () => {
    setFilterData({
      category: '',
      searchkey: '',
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setSearchTerm('')
  }
  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      isReset: false,
      startDate: start,
      endDate: end,
      isFilter: true
    })
  }
  const statusPage = e => {
    setFilterData({
      ...filterData,
      isReset: false,
      category: e.target.value,
      isFilter: true
    })
    setPage(1)
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: false,
        searchkey: debouncedSearchTerm,
        isFilter: true
      })
      setPage(1)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    updatePageName(t('O_OPERATOR_MANAGER'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3] 2xl:flex gap-2 px-4 py-3 flex-wrap justify-between'>
              <div className='col-span-2 flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap justify-center'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={filterData?.isReset}
                    setIsReset={setFilterData}
                  />

                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      value={filterData?.category}
                      onChange={e => statusPage(e)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='active'>{t('O_ACTIVE')}</option>
                      <option value='inactive'>{t('O_INACTIVE')}</option>
                    </select>
                  </div>

                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='lg:pt-0 pt-2'>
                <div className='flex'>
                  <div className='relative '>
                    <div
                      className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'
                      style={{ transform: 'translateY(-2px)' }}
                    >
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('O_SEARCH_BY_BUSINESS_NAME_EMAIL_MOBILE')}
                      title=''
                      required
                      onChange={e => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                  {(user?.permission?.[7]?.add ||
                    user.permission?.length === 0) && (
                    <button
                      title={t('MERCHANT_ADD_OPERATOR')}
                      type='button'
                      className='bg-LightBlue flex text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                      onClick={() => setShowModal(true)}
                    >
                      + {t('MERCHANT_ADD_OPERATOR')}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <OperatorTable
              user={user}
              operators={operators}
              getAllOperators={getAllOperators}
              handelEdit={handelEdit}
              page={page}
              setSort={setSort}
              sort={sort}
              handleUserView={handleUserView}
              setPage={setPage}
            />

            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {showModal ? (
          <OperatorAdd
            setShowModal={setShowModal}
            getAllOperators={getAllOperators}
            setPage={setPage}
          />
        ) : null}
        {editshowModal ? (
          <OperatorEdit
            setEditShowModal={setEditShowModal}
            getAllOperators={getAllOperators}
            item={item}
          />
        ) : null}
        {viewShowModal ? (
          <OperatorView setViewShowModal={setViewShowModal} item={item} />
        ) : null}
      </>
    </div>
  )
}

export default Operator
