import React from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import OImage from 'components/reusable/OImage'
import { validationRules } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import NoImage from '../../assets/images/noImage.jpg'
import NoPdf from '../../assets/images/pdflogo.png'
import { AiOutlineDownload } from 'react-icons/ai'
import dayjs from 'dayjs'

const UserView = ({ setViewShowModal, getAllUser, item }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const { register, handleSubmit, getValues } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      first_name: item?.first_name,
      last_name: item?.last_name,
      mobile: item?.mobile,
      email: item?.email,
      country_code: item?.country_code,
      userType: item?.userType,
      NID: item?.NID,
      niptNo: item?.niptNo,
      businessName: item?.businessName,
      referral_code: item?.referral_code,
      status: item?.status
    }
  })

  const onSubmit = async data => {
    try {
      const path = apiPath.updateUser + '/' + item._id
      const result = await apiPut(path, data)
      if (result.data.success) {
        getAllUser()
        setViewShowModal(false)
        notification.success(result?.data.message)
      } else {
        setViewShowModal(false)
        notification.error(result?.data.message)
      }
    } catch (error) {
      notification.error(error.message)
    }
  }
  return (
    <>
      <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto '>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='sm:py-4 sm:px-2 py-8 px-7'
          >
            <div
              className='border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] m-auto'
              style={{ maxHeight: '80vh', overflowY: 'scroll' }}
            >
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>{t('O_VIEW_USER')}</h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setViewShowModal(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>

              <div className='relative p-6 flex-auto'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='floating_email'
                      id='floating_name'
                      disabled
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      {...register('first_name', {
                        required: true
                      })}
                    />
                    <label
                      htmlFor='floating_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_FIRST_NAME')}
                    </label>
                  </div>
                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='floating_name'
                      id='floating_name'
                      disabled
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      {...register('last_name', {
                        required: true
                      })}
                    />
                    <label
                      htmlFor='floating_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_LAST_NAME')}
                    </label>
                  </div>

                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      disabled
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                      placeholder=' '
                      required
                      {...register('email', {
                        required: 'Please enter email.',
                        pattern: {
                          value: validationRules.email,
                          message:
                            'Invalid email address (Ex: example@domain.com).'
                        }
                      })}
                    />
                    <label
                      htmlFor='floating_email1'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_EMAIL_ID')}
                    </label>
                  </div>

                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='floating_email'
                      id='floating_name'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      disabled
                      {...register('country_code', {
                        required: true
                      })}
                    />
                    <label
                      htmlFor='floating_name'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_COUNTRY_CODE')}
                    </label>
                  </div>

                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='number'
                      name='mobile'
                      id='mobile'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      disabled
                      {...register('mobile', {
                        required: true
                      })}
                    />
                    <label
                      htmlFor='mobile'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_MOBILE_NUMBER')}
                    </label>
                  </div>
                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='referral_code'
                      id='referral_code'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      disabled
                      {...register('referral_code', {})}
                    />
                    <label
                      htmlFor='referral_code'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('USERS_REFERRAL_CODE')}
                    </label>
                  </div>
                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='status'
                      id='status'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      disabled
                      {...register('status', {})}
                    />
                    <label
                      htmlFor='status'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_STATUS')}
                    </label>
                  </div>
                  <div className='relative z-0 mb-6 w-full group'>
                    <input
                      type='text'
                      name='createdAt'
                      id='createdAt'
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      required
                      disabled
                      value={dayjs(item?.createdAt).format(
                        'DD-MM-YYYY hh:mm A'
                      )}
                    />
                    <label
                      htmlFor='createdAt'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {t('O_CREATED_AT')}
                    </label>
                  </div>
                  <div className='relative z-0   w-full group'>
                    <select
                      type='select'
                      id='userType'
                      disabled
                      className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                      placeholder=' '
                      {...register('userType', {
                        required: 'Please select business type.'
                      })}
                    >
                      <option value=''>{t('OFFERS_USER_TYPE')}</option>
                      <option value='individual'>{t('O_INDIVIDUAL')}</option>
                      <option value='business'>{t('BUSINESS')}</option>
                    </select>
                    <label
                      htmlFor='category'
                      className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                    >
                      {' '}
                      {t('OFFERS_CATEGORY')}
                      <span className='text-red-500'>*</span>
                    </label>
                  </div>

                  {getValues('userType') === 'individual' ? (
                    <>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='NID'
                          id='NID'
                          disabled
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none max-w-lg focus:ring-0  peer'
                          placeholder=' '
                          required
                          {...register('NID')}
                        />
                        <label
                          htmlFor='NID'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_NID')}
                        </label>
                      </div>

                      <div className='flex'>
                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400 mb-3 text-center block'>
                            {t('O_IDENTITY_CARD_FRONT_ONLY')}
                          </label>
                          <OImage
                            src={item?.identityCardFront ?? NoImage}
                            className='w-24 h-24 text-sm rounded-lg'
                            alt='identityCardFront'
                          />
                          {item?.identityCardFront ? (
                            <a
                              href={item?.identityCardFront}
                              download
                              target='blank'
                            >
                              <button
                                type='button'
                                className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>

                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400 mb-3 text-center block'>
                            {t('O_IDENTITY_CARD_BACK_ONLY')}
                          </label>
                          <OImage
                            src={item?.identityCardBack ?? NoImage}
                            className='w-24 h-24 text-sm rounded-lg'
                            alt='identityCardBack'
                          />
                          {item?.identityCardBack ? (
                            <a
                              href={item?.identityCardBack}
                              download
                              target='blank'
                            >
                              <button
                                type='button'
                                className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : getValues('userType') === 'business' ? (
                    <>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          type='text'
                          name='niptNo'
                          id='niptNo'
                          disabled
                          inputLabel={<>{t('O_NIPT_NO')}</>}
                          maxLength={40}
                          register={register('niptNo')}
                        />
                      </div>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          name='businessName'
                          inputLabel={
                            <>
                              {t('MERCHANT_BUSINESS_NAME')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          type='text'
                          maxLength={15}
                          disabled
                          register={register('businessName')}
                        />
                      </div>

                      <div className='flex'>
                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400 mb-3 block text-center'>
                            {t('O_IDENTITY_CARD_FRONT_ONLY')}
                          </label>
                          <OImage
                            src={item?.identityCardFront ?? NoImage}
                            className='w-24 h-24 text-sm rounded-lg m-auto'
                            alt='identityCardFront'
                          />
                          {item?.identityCardFront ? (
                            <a
                              href={item?.identityCardFront}
                              download
                              target='blank'
                            >
                              <button
                                type='button'
                                className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>

                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400  mb-3 block'>
                            {t('O_IDENTITY_CARD_BACK_ONLY')}
                          </label>
                          <OImage
                            src={item?.identityCardBack ?? NoImage}
                            className='w-24 h-24 text-sm  rounded-lg m-auto'
                            alt='identityCardBack'
                          />
                          {item?.identityCardBack ? (
                            <a
                              href={item?.identityCardBack}
                              download
                              target='blank'
                            >
                              <button
                                type='button'
                                className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>
                      </div>

                      <div className='flex w-[375px]'>
                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400  mb-3 block'>
                            {t('O_BUSINESS_EXTRACT_ONLY')}
                          </label>
                          <OImage
                            src={NoPdf}
                            className='w-24 h-24 text-sm  rounded-lg m-auto'
                            alt='businessExtract'
                          />
                          {console.log('sdfdsh', item?.businessExtract)}
                          {item?.businessExtract ? (
                            <a
                              href={item?.businessExtract}
                              download
                              target='blank'
                              className='block text-center'
                            >
                              <button
                                type='button'
                                className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>

                        <div className='relative z-0 mb-6  group mr-4'>
                          <label className='text-[#A5A5A5] dark:text-gray-400  mb-3 block'>
                            {t('O_BUSINESS_LICENSE_ONLY')}
                          </label>
                          <OImage
                            src={NoPdf}
                            className='w-24 h-24 text-sm rounded-lg'
                            alt='businessLicense'
                          />
                          {item?.businessLicense ? (
                            <a
                              href={item?.businessLicense}
                              download
                              target='blank'
                              className='block'
                            >
                              <button
                                type='button'
                                className='ml-10 bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                              >
                                <AiOutlineDownload
                                  style={{ width: '15px', fontWeight: 800 }}
                                />
                              </button>
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className='absolute right-[150px] bottom-[24px] z-0 mb-6  group ml-3 '>
                    <label className='text-[#A5A5A5] dark:text-gray-400  mb-3 block text-center'>
                      {t('PROFILE_PICTURE')}
                    </label>
                    <div className='flex justify-center flex-col align-center text-center'>
                      <OImage
                        src={item?.profile_pic ?? NoImage}
                        className='w-24 h-24 text-sm rounded-lg m-auto'
                        alt='id'
                      />
                      {item?.profile_pic ? (
                        <a href={item?.profile_pic} download target='blank'>
                          <button
                            type='button'
                            className='bg-LightBlue text-sm px-2 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                          >
                            <AiOutlineDownload
                              style={{ width: '15px', fontWeight: 800 }}
                            />
                          </button>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setViewShowModal(false)}
                >
                  {t('O_BACK')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default UserView
