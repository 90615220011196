import { useTranslation } from 'react-i18next'

const UseChange = ({ data }) => {
  let { t } = useTranslation()
  return t(data)
}

export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  passwordMessage: (
    <>{<UseChange data='PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_' />}</>
  ),
  confirmPasswordMessage: (
    <>
      {<UseChange data='CONFIRM_PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_' />}
    </>
  ),
  newPasswordMessage: (
    <>{<UseChange data='NEW_PASSWORD_MUST_CONTAIN_LOWERCASE_UPPERCASE_' />}</>
  ),
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  // removeWhitespace: /^(?=.*[a-zA-Z @./#&ËÇeç+-])(?=.*[0-9])[a-zA-Z0-9 @./#&+-]*$/
  removeWhitespace: /^[a-zA-Z0-9][a-zA-Z0-9 \^\\\-!@#$%&*ËÇeç()_~.+,/'"]+$/gm
  // mobile:/^(9|8|7)\d{11}$/,
}
