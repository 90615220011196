import React, { useContext, useEffect, useState } from 'react'
import Table from './Table'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import pathObj from 'utils/pathObj'
import { apiGet } from 'utils/apiFetch'
import Pagination from '../Pagination'

function LogsManagement () {
  const { t } = useTranslation()
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isReset, setIsReset] = useState(false)
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })
  const [logState, setLogState] = useState({
    startDate: '',
    endDate: '',
    keyword: ''
  })

  const [logs, setLogs] = useState([])
  const { keyword, startDate, endDate } = logState

  const handleReset = () => {
    setLogState({
      ...logState,
      startDate: '',
      endDate: '',
      keyword: ''
    })
    setPage(1)
    setIsReset(true)
    setSearchTerm('')
  }
  const getLogs = async () => {
    try {
      const payload = {
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        keyword,
        page,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }
      const path = pathObj.getLogs
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success

        setLogs(response?.docs)
        setPaginationObj({
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('getWalletTransactions error :', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }
  useEffect(() => {
    getLogs()
  }, [page, keyword, endDate, startDate, sort])
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleDateChange = (start, end) => {
    setIsReset(false)
    setLogState({ ...logState, startDate: start, endDate: end })
    setPage(1)
  }

  useEffect(() => {
    setLogState({
      ...logState,
      keyword: debouncedSearchTerm
    })
    setIsReset(false)
    setPage(1)
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    updatePageName(t('NAV_LOGS_MANAGEMENT'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='flex flex-wrap items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap '>
                  <div className='flex flex-wrap'>
                    <ODateRangePicker
                      handleDateChange={handleDateChange}
                      isReset={isReset}
                      setIsReset={setIsReset}
                      className='w-full'
                    />
                  </div>
                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center md:justify-end px-4'>
                <label
                  htmlFor='default-search'
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                >
                  {t('O_SEARCH')}
                </label>
                <div className='flex'>
                  <div className='relative'>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      id='default-search'
                      name='keyword'
                      className='block w-64 p-2 text-sm text-gray-900 2xl:min-w-[200px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('FULL_NAME_LOG')}
                      title=''
                      required
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <Table logs={logs} page={page} setSort={setSort} sort={sort} />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                // isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogsManagement
