import React from 'react'
import { isEmpty, startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { BsArrowUpShort } from 'react-icons/bs'

const Table = ({ earningManager, page, sort, setSort }) => {
  const { t } = useTranslation()
  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_TRANSACTION_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_USER_NAME')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MERCHANT_NAME')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_TRANSACTION_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_COMMISSION')} (%)
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_COMMISSION_AMOUNT')} ({t('O_LEK')})
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_TRANSACTION_TYPE')}
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')}</span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {earningManager &&
              earningManager?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>{item?.transactionId}</td>
                  <td className='py-4 px-6 border-r'>
                    {item?.user
                      ? item?.user?.first_name + ' ' + item?.user?.last_name
                      : ''}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {item?.merchant
                      ? item?.merchant?.first_name +
                        ' ' +
                        item?.merchant?.last_name
                      : ''}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.transactionAmount}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.commission?.toFixed(2)}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.commissionAmount?.toFixed(2)}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {startCase(item?.transactionType)}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                </tr>
              ))}
            {isEmpty(earningManager) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
