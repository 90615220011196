import React, { useEffect, useState, useContext } from 'react'
import { apiGet, apiPost } from '../../utils/apiFetch'
import Pagination from '../Pagination'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import pathObj from '../../utils/pathObj'
import UserReportTable from './UserReportTable'
import MerchantTable from './MerchantTable'
import Transactions from './Transactions'
import { BsCloudDownloadFill } from 'react-icons/bs'
import dayjs from 'dayjs'
import helpers from '../../utils/helpers'
import AuthContext from 'context/AuthContext'

function Reports () {
  const { t } = useTranslation()
  const { updatePageName } = useContext(AuthContext)
  const [pagination_obj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [reportType, setReportType] = useState('')
  const [isReset, setIsReset] = useState(false)
  const [reports, setReports] = useState({})
  const [status, setStatus] = useState('')
  const [reportFormat, setReportFormat] = useState('')
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })

  const pageSize = 10

  const getReports = async () => {
    try {
      let params = {
        page_size: pageSize,
        page,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type,
        status: status
      }
      if (startDate) {
        params.startDate = startDate
          ? dayjs(startDate).format('YYYY-MM-DD')
          : null
      }
      if (endDate) {
        params.endDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null
      }
      if (reportType) {
        params.reportType = reportType
      }
      if (status) {
        params.status = status
      }
      if (!reportType) {
        return
      }

      const res = await apiGet(pathObj.getReports, params)
      const resultStatus = res?.data?.success

      setReports(res?.data?.results)
      setPaginationObj({
        ...pagination_obj,
        page: resultStatus ? res?.data?.results?.page : null,
        pageCount: resultStatus ? res?.data?.results?.totalPages : null,
        perPageItem: resultStatus ? res?.data?.results?.docs?.length : null,
        totalItems: resultStatus ? res?.data?.results?.totalDocs : null
      })
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    getReports()
  }, [startDate, endDate, reportType, page, status, sort])

  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    setIsReset(true)
    setReportType('')
    setPaginationObj({})
    setStatus('')
    setReportFormat('')
  }
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setPage(1)
    setIsReset(false)
  }

  const handleStatusType = e => {
    setStatus(e.target.value)
    setPage(1)
    setIsReset(false)
  }

  const handleReportType = e => {
    setReportType(e.target.value)
    setPage(1)
    setIsReset(false)
  }

  const handleReportFormat = e => {
    setReportFormat(e.target.value)
    setPage(1)
    setIsReset(false)
  }

  const handleDownloadReport = async () => {
    try {
      let sendData = {}
      if (startDate) {
        sendData.startDate = startDate
          ? dayjs(startDate).format('YYYY-MM-DD')
          : null
      }
      if (endDate) {
        sendData.endDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null
      }
      if (reportType) {
        sendData.reportType = reportType
      }
      if (reportFormat) {
        sendData.type = reportFormat
      }
      if (status) {
        sendData.status = status
      }
      const res = await apiPost(pathObj.downloadReport, sendData)
      if (res.data.success) {
        helpers.downloadFile(res?.data?.results?.file_path)
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  useEffect(() => {
    updatePageName(t('NAV_REPORTS'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='col-span-2 flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap '>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={isReset}
                    setIsReset={setIsReset}
                  />
                  <div style={{ height: '50px' }}>
                    <button
                      type='button'
                      onClick={handleReset}
                      className='bg-LightBlue text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                    >
                      {t('O_RESET')}
                    </button>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center px-4'>
                <label
                  htmlFor='default-search'
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                >
                  {t('O_SEARCH')}
                </label>
                <div className='flex'>
                  <div className='relative mr-4'>
                    <div>
                      <select
                        name='cars'
                        id='cars'
                        style={{
                          border: 'solid 1px #d9d9d9',
                          padding: '5px 10px',
                          height: '40px',
                          outline: 'none',
                          borderRadius: '8px'
                        }}
                        value={reportType}
                        onChange={handleReportType}
                      >
                        <option value=''>{t('REPORT_SELECT_REPORT')}</option>
                        <option value='user'>{t('OFFERS_USER')}</option>
                        <option value='merchant'>{t('O_MERCHANT')}</option>
                        <option value='transaction'>
                          {t('O_TRANSACTION')}
                        </option>
                      </select>
                    </div>
                  </div>
                  {['user', 'merchant'].includes(reportType) && (
                    <div className='relative '>
                      <div>
                        <select
                          name='cars'
                          id='cars'
                          style={{
                            border: 'solid 1px #d9d9d9',
                            padding: '5px 10px',
                            height: '40px',
                            outline: 'none',
                            borderRadius: '8px'
                          }}
                          value={status}
                          onChange={handleStatusType}
                        >
                          <option value=''>{t('REPORT_SELECT_STATUS')}</option>
                          <option value='active'>{t('O_ACTIVE')}</option>
                          <option value='inactive'>{t('O_INACTIVE')}</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {['user', 'merchant', 'transaction'].includes(reportType) && (
                    <div className='relative ml-4'>
                      <div>
                        <select
                          name='cars'
                          id='cars'
                          style={{
                            border: 'solid 1px #d9d9d9',
                            padding: '5px 10px',
                            height: '40px',
                            outline: 'none',
                            borderRadius: '8px'
                          }}
                          value={reportFormat}
                          onChange={handleReportFormat}
                        >
                          <option value=''>{t('REPORT_FORMAT')}</option>
                          <option value='csv'>{t('REPORT_CSV')}</option>
                          <option value='pdf'>{t('REPORT_PDF')}</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {reportType !== '' &&
                    pagination_obj?.totalItems > 0 &&
                    reportFormat !== '' && (
                      <div
                        className='flex items-center cursor-pointer'
                        onClick={handleDownloadReport}
                      >
                        <BsCloudDownloadFill
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginLeft: '1rem'
                          }}
                          title='Download report'
                        />
                      </div>
                    )}
                </div>
              </div>
            </form>
            <section className='table-section'>
              {reportType === '' ? (
                <div
                  className='text-center '
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    marginTop: '2rem',
                    marginBottom: '1rem'
                  }}
                >
                  {t('REPORT_TYPE_OF_REPORT')}
                </div>
              ) : reportType === 'user' ? (
                <UserReportTable
                  reports={reports}
                  setSort={setSort}
                  sort={sort}
                  page={page}
                />
              ) : reportType === 'merchant' ? (
                <MerchantTable
                  reports={reports}
                  setSort={setSort}
                  sort={sort}
                  page={page}
                />
              ) : reportType === 'transaction' ? (
                <Transactions
                  reports={reports}
                  setSort={setSort}
                  sort={sort}
                  page={page}
                />
              ) : null}

              {pagination_obj?.totalItems ? (
                <Pagination
                  handlePageClick={handlePageClick}
                  options={pagination_obj}
                  page={page}
                />
              ) : null}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports
