import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { isEmpty } from 'lodash'
import withReactContent from 'sweetalert2-react-content'

const calendar = require('dayjs/plugin/calendar')
const MySwal = withReactContent(Swal)
dayjs.extend(calendar)
const helpers = {
  currencyFormat: (number = '') => {
    return new Intl.NumberFormat('en-IN', {}).format(number)
  },
  dateFormat: (date, timeZone) => {
    const convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone: timeZone || 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  msgDateFormat: (date, timeZone) => {
    const convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone || 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  matchDateTime: (date, timeZone) => {
    return dayjs(date).calendar(null, {
      sameDay: 'h:mm A', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'dddd [at] h:mm A', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday at] h:mm A', // The day before ( Yesterday at 2:30 AM )
      lastWeek: '[Last] dddd [at] h:mm A', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'DD/MM/YYYY' // Everything else ( 17/10/2011 )
    })
  },
  marketStatus: s => {
    let status = ''
    switch (s) {
      case 1:
        status = 'Open'
        break
      case 2:
        status = 'In Active'
        break
      case 3:
        status = 'Suspended'
        break
      case 4:
        status = 'Closed'
        break
      case 9:
        status = 'Ball Start'
        break
      default:
        status = ''
    }
    return status
  },
  getSportType: t => {
    let type = ''
    switch (t) {
      case 1:
        type = 'soccer'
        break
      case 2:
        type = 'tennis'
        break
      case 4:
        type = 'cricket'
        break

      default:
        type = ''
    }
    return type
  },
  isInputNumber: event => {
    const char = String.fromCharCode(event.which)
    if (!/[0-9]/.test(char)) {
      event.preventDefault()
    }
  },
  alertFunction: (title, item, changeFunction, deleteIcon, language) => {
    MySwal.fire({
      html: <strong>{title}</strong>,
      icon: deleteIcon ? 'error' : 'warning',
      showConfirmButton: 'Okay',
      confirmButtonText: language == '1' ? 'Ok' : 'Ne rregull',
      cancelButtonText: language == '1' ? 'Cancel' : 'Anullo',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        changeFunction(item)
      }
    })
  },
  downloadFile: url => {
    if (isEmpty(url)) {
      alert('Invalid URL')
      return false
    }
    const parts = url.split('/')
    const filename = parts[parts.length - 1]

    fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    })
  }
}

export default helpers
