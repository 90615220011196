export const generateRandomID = (length) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const containsNumbers = (str) => {
  return /\d/.test(str)
}

export const validateFile = (file) => {
  let fileErrorMsg = ''
  const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png']
  if (!supportedTypes.includes(file.type)) {
    fileErrorMsg = 'Only jpeg,jpg and png are supported.'
  }

  // if(data.passport[0].size > 1000*300){
  //     console.log('passport: ',data.passport[0].size > 1000*300)
  //     identity_card_error = 'Please provide file size less than 300kb'
  // }

  // if (file_error_msg ) {
  //   return file_error_msg;
  // } else {
  //   return file_error_msg;
  // }
  return fileErrorMsg
}

export const supportedImageTypes = ['image/jpeg', 'image/jpg', 'image/png']
