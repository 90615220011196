import React, { useContext } from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import useToastContext from 'hooks/useToastContext'
import helper from '../../utils/helpers'
import { BsArrowUpShort } from 'react-icons/bs'

const Table = ({ FAQs, getAllFAQ, handelEdit, page, sort, setSort }) => {
  const { t } = useTranslation()
  const { user, language } = useContext(AuthContext)
  const notification = useToastContext()

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.changeFAQStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllFAQ()
      }
      // }
    } catch (error) {
      console.log('error in get all faqs list==>>>>', error.message)
    }
  }

  const handelDelete = async item => {
    try {
      const path = apiPath.getFAQs + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        getAllFAQ({ deletePage: 1 })
        notification.success(result.data.message)
      }
    } catch (error) {
      console.log('error in get all FAQs list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE]  bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('FAQS_TITLE')}
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('FAQS_TITLE') + t('ALBANIAN')}
              </th>
              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')}</span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_UPDATE_DATE')}
              </th>
              {(user?.permission?.[16]?.add ||
                user?.permission?.length === 0) && (
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_STATUS')}
                </th>
              )}
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {FAQs &&
              FAQs?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>{item?.title}</td>
                  <td className='py-4 px-6 border-r'>{item?.titleAl}</td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.updatedAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  {(user?.permission?.[16]?.add ||
                    user?.permission?.length === 0) && (
                    <td className='py-4 px-6 border-r text-center'>
                      <label
                        className='inline-flex relative items-center cursor-pointer'
                        title={`${
                          item?.status === 'active'
                            ? t('O_ACTIVE')
                            : t('O_INACTIVE')
                        }`}
                      >
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={item?.status === 'active'}
                          onChange={e =>
                            helper.alertFunction(
                              `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                e.target.checked ? 'active' : 'inactive'
                              } "${item.title}" FAQs?`,
                              item,
                              handelStatusChange,
                              '',
                              language
                            )
                          }
                        />
                        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                      </label>
                    </td>
                  )}
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(user?.permission?.[16]?.add ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handelEdit(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('EDIT_FAQS')}>
                              {' '}
                              <AiFillEdit className='w-5 h-5 text-slate-600' />
                            </a>
                          </li>
                        )}

                        {(user?.permission?.[16]?.delete ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={e =>
                              helper.alertFunction(
                                `${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} "${
                                  item.title
                                }" FAQs?`,
                                item,
                                handelDelete,
                                true,
                                language
                              )
                            }
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('DELETE_FAQS')}>
                              {' '}
                              <AiFillDelete className='w-5 h-5 text-red-600' />{' '}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(FAQs) ? (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={6}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
