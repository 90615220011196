import React, { useContext, useEffect, useState } from 'react'
import 'chartjs-adapter-date-fns'
import { FcPortraitMode, FcNightPortrait, FcBusinessman } from 'react-icons/fc'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import offerSvg from 'assets/icons/offer.svg'
import moneySvg from 'assets/icons/money.svg'
import coinSvg from 'assets/icons/coin.svg'
import transactionSvg from 'assets/icons/transaction.svg'
import bankSvg from 'assets/icons/bank.svg'
import yuanSvg from 'assets/icons/yuanSign.svg'
import addMoneySvg from 'assets/icons/addMoney.svg'
import loveSvg from 'assets/icons/love.svg'
import CountUp from 'react-countup'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js'
import AuthContext from 'context/AuthContext'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
)

const monthsLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
]

export const lineGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: ''
    }
  },
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

export const lineGraphData = {
  labels: [],
  datasets: [
    {
      label: 'Users',
      data: []
    }
  ]
}

export const lineGraphOptions2 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: ''
    }
  },
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

export const lineGraphData2 = {
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  datasets: [
    {
      label: 'Sales',
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  ]
}

function Home () {
  const { t } = useTranslation()
  let { user, logoutUser, updatePageName, lastActivity, setLastActivity } =
    useContext(AuthContext)
  const [graphPayload, setGraphPayload] = useState({
    graphYear: '-1',
    graphMonth: '-1'
  })
  const [dashboardDetails, setDashboardDetails] = useState({})

  const [usersGraphYears, setUsersGraphYears] = useState([])
  const [orderGraphYears, setOrderGraphYears] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [isReset, setIsReset] = useState(false)
  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setIsReset(false)
  }

  useEffect(() => {
    if (graphPayload.graphMonth !== '-1') {
      if (dashboardDetails?.userGraph?.daysList) {
        const newDates = dashboardDetails?.userGraph?.daysList
        lineGraphData.labels = newDates

        // working on y axis data
        let refData = lineGraphData.datasets[0].data
        refData.length = 0 // Clear the array more efficiently

        const daysEarnReport = dashboardDetails?.userGraph?.daysEarnReport
        for (const value of daysEarnReport) {
          refData.push(value)
        }

        lineGraphData.datasets[0].data = refData
        setReRender(prev => !prev)
      }
    } else {
      if (dashboardDetails?.userGraph?.monthlyEarnReport) {
        // working on y axis data
        let refData = lineGraphData.datasets[0].data
        refData.length = 0 // Clear the array more efficiently

        const monthlyEarnReport = dashboardDetails?.userGraph?.monthlyEarnReport
        for (const value of monthlyEarnReport) {
          refData.push(value)
        }

        lineGraphData.labels = [...monthsLabels]
        setReRender(prev => !prev)
      }
    }

    if (dashboardDetails?.userGraph?.graphDate) {
      let yearArray = []
      yearArray.push(dashboardDetails?.userGraph?.graphDate?.endYear)
      for (
        let i = yearArray[0] - 1;
        i >= dashboardDetails?.userGraph?.graphDate?.startYear;
        i--
      ) {
        yearArray.push(i)
      }

      setUsersGraphYears([...yearArray])
    }
  }, [dashboardDetails])

  useEffect(() => {
    if (graphPayload.graphMonth !== '-1') {
      if (dashboardDetails?.orderGraph?.daysList) {
        const newDates = dashboardDetails?.orderGraph?.daysList
        lineGraphData2.labels = newDates
        // working on y axis data
        let refData = lineGraphData2.datasets[0].data
        while (refData.length > 0) {
          refData.pop()
        }
        const daysEarnReportOrder =
          dashboardDetails?.orderGraph?.daysEarnReportOrder
        for (let i = 0; i < daysEarnReportOrder?.length; i++) {
          refData.push(daysEarnReportOrder[i])
        }
        lineGraphData2.datasets[0].data = refData

        setReRender(prev => !prev)
      }
    } else {
      if (dashboardDetails?.orderGraph?.monthlyEarnDataOrder) {
        // working on y axis data
        let refData = lineGraphData2.datasets[0].data
        while (refData.length > 0) {
          refData.pop()
        }
        const monthlyEarnDataOrder =
          dashboardDetails?.orderGraph?.monthlyEarnDataOrder
        for (let i = 0; i < monthlyEarnDataOrder.length; i++) {
          refData.push(monthlyEarnDataOrder[i])
        }
        lineGraphData2.labels = [...monthsLabels]
        setReRender(prev => !prev)
      }
    }
    if (dashboardDetails?.orderGraph?.graphDate) {
      let yearArray = []
      yearArray.push(dashboardDetails?.orderGraph?.graphDate?.endYear)
      for (
        let i = yearArray[0] - 1;
        i >= dashboardDetails?.orderGraph?.graphDate?.startYear;
        i--
      ) {
        yearArray.push(i)
      }
      setOrderGraphYears([...yearArray])
    }
  }, [dashboardDetails])

  const [reRender, setReRender] = useState(false)

  const { graphMonth, graphYear } = graphPayload

  const getDashboardDetails = async () => {
    try {
      const payload = {
        start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        graphMonth,
        graphYear
      }
      const path = pathObj.getDashboardDetails
      const result = await apiGet(path, payload)
      setDashboardDetails({ ...dashboardDetails, ...result.data.results })
    } catch (error) {
      console.log('error:', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getDashboardDetails()
  }, [startDate, endDate, graphPayload])

  const handleYearSelect = e => {
    setGraphPayload({
      ...graphPayload,
      graphYear: e.target.value
    })
  }
  const handleMonthSelect = e => {
    setGraphPayload({
      ...graphPayload,
      graphMonth: e.target.value
    })
  }
  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setIsReset(true)
  }
  const handleResetGraph = () => {
    setGraphPayload({
      graphYear: '-1',
      graphMonth: '-1'
    })
  }
  useEffect(() => {
    updatePageName(t('NAV_DASHBOARD'))
  }, [])

  useEffect(() => {
    const activityListener = () => {
      setLastActivity(new Date())
    }
    window.addEventListener('mousemove', activityListener)

    return () => {
      window.removeEventListener('mousemove', activityListener)
    }
  }, [])

  useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      const currentTime = new Date()
      const inactivityDuration = currentTime - lastActivity
      if (inactivityDuration >= 60000) {
        logoutUser()
      }
    }, 60000)
    return () => {
      clearTimeout(inactivityTimeout)
    }
  }, [lastActivity])

  return (
    <>
      <div className='sm:flex items-center text-center sm:text-left px-3 md:px-4 xl:px-7 lg:px-5  md:py-5 py-4 md:py-8 border'>
        <ODateRangePicker
          handleDateChange={handleDateChange}
          isReset={isReset}
          setIsReset={setIsReset}
        />
        <button
          type='button'
          onClick={handleReset}
          className='bg-LightBlue text-sm px-8 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
        >
          {t('O_RESET')}
        </button>
      </div>

      <div className='py-4 px-4 md:px-8'>
        <div className='sale_report grid  3xl:grid-cols-4 gap-4 2xl:grid-cols-3 sm:grid-cols-2 mb-7 '>
          {(user?.permission?.[1]?.view || user?.role === 'admin') && (
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg xl:flex-wrap'>
                {dashboardDetails?.total_no_of_users ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_users}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('O_USERS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'>
                <FcPortraitMode />
              </span>
            </div>
          )}

          {(user?.permission?.[1]?.view || user?.role === 'admin') && (
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_active_users ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_active_users}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_ACTIVE_USERS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <FcPortraitMode />
              </span>
            </div>
          )}

          {(user?.permission?.[1]?.view || user?.role === 'admin') && (
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_inactive_users ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_inactive_users}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_INACTIVE_USERS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <FcNightPortrait />
              </span>
            </div>
          )}

          {(user?.permission?.[4]?.view || user?.role === 'admin') && (
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_merchants ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_merchants}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('O_MERCHANT')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0  mt-2 sm:mt-0'>
                <FcBusinessman className='h-8 w-8' />
              </span>
            </div>
          )}

          {(user?.permission?.[3]?.view || user?.role === 'admin') && (
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_transactions ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_transactions}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_TOTAL_TRANSACTION')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0  mt-2 sm:mt-0'>
                <img src={transactionSvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          )}

          {(user?.permission?.[2]?.view || user?.role === 'admin') && (
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_wallet_to_bank_transactions ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={
                        dashboardDetails?.total_no_of_wallet_to_bank_transactions
                      }
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_WALLET_TO_BANK_TRANSACTION')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <img src={bankSvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          )}

          {(user?.permission?.[3]?.view || user?.role === 'admin') && (
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_utilities_transaction ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_utilities_transaction}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('O_UTILITIES_TRANSACTION')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <img src={yuanSvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          )}

          {(user?.permission?.[2]?.view || user?.role === 'admin') && (
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_add_money_to_wallet_transactions ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={
                        dashboardDetails?.total_no_of_add_money_to_wallet_transactions
                      }
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('O_ADD_MONEY_TO_WALLET_TRANSACTION')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <img src={addMoneySvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          )}

          {(user?.permission?.[5]?.view || user?.role === 'admin') && (
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.total_no_of_offers ? (
                  <>
                    <CountUp
                      duration={0.6}
                      end={dashboardDetails?.total_no_of_offers}
                    />
                  </>
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_TOTAL_OFFER')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <img src={offerSvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          )}

          <div className=' items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
            <div className='flex justify-between'>
              <span className='lg:ml-1 text-base text-neutral-400 font-normal block lg:inline-block'>
                {t('DASHBOARD_LOYALTY_PAYMENT')}
              </span>
              <span className='ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <img src={loveSvg} className='h-8 w-8' alt='' />
              </span>
            </div>

            <h3 className='block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
              <span className='ml-1' style={{ marginRight: '6px' }}>
                {t('O_LEK')}
              </span>
              <CountUp
                duration={0.6}
                decimals={2}
                end={dashboardDetails?.totalLoyaltyPoint}
              />
            </h3>
          </div>

          {(user?.permission?.[7]?.view || user?.role === 'admin') && (
            <div className=' items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <div className='flex justify-between'>
                <span className='lg:ml-1 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('DASHBOARD_EARNING')}
                </span>
                <span className='ml-auto  mt-2 sm:mt-0'>
                  <img src={moneySvg} className='h-8 w-8' alt='' />
                </span>
              </div>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                <span className='ml-1' style={{ marginRight: '6px' }}>
                  {t('O_LEK')}
                </span>
                <CountUp
                  duration={0.6}
                  decimals={2}
                  end={dashboardDetails?.totalEarning}
                />
              </h3>
            </div>
          )}

          {(user?.permission?.[2]?.view || user?.role === 'admin') && (
            <div className='bg-gray-200  items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <div className='flex justify-between'>
                <span className='lg:ml-1 text-base text-neutral-400 font-normal block lg:inline-block mb-2'>
                  {t('DASHBOARD_WALLET_BALANCE')}
                </span>
                <span className='ml-auto sm:mr-0  mt-2 sm:mt-0'>
                  <img src={coinSvg} className='h-8 w-8' alt='' />
                </span>
              </div>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg mb-3'>
                <span className='ml-1' style={{ marginRight: '6px' }}>
                  {t('O_LEK')}{' '}
                </span>
                {dashboardDetails?.total_wallet_balance ? (
                  <>
                    <CountUp
                      duration={0.6}
                      decimals={2}
                      end={dashboardDetails?.total_wallet_balance}
                    />
                  </>
                ) : (
                  0
                )}
              </h3>
            </div>
          )}
        </div>
      </div>
      <div className='py-7 px-4 md:px-8 bg-[#F9F9F9] border-solid border-2 border-gray m-10 rounded-md'>
        <div className='flex justify-center mb-5'>
          <div className='flex items-center mb-3 ml-4'>
            {console.log(graphPayload)}
            <select
              name='month'
              className='flex flex-row-reverse border outline-none border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              value={graphPayload.graphYear}
              onChange={handleYearSelect}
            >
              <option value='-1'>{t('DASHBOARD_SELECT_YEAR')}</option>
              {usersGraphYears?.map(year => {
                return <option key={year}>{year}</option>
              })}
            </select>
          </div>

          <div className='flex items-center mb-3 ml-4'>
            <select
              name='month'
              className='flex flex-row-reverse border outline-none border-[#E9EDF9] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              value={graphPayload.graphMonth}
              onChange={handleMonthSelect}
            >
              <option value='-1'>{t('DASHBOARD_SELECT_MONTH')}</option>
              <option value='1'>{t('DASHBOARD_JANUARY')}</option>
              <option value='2'>{t('DASHBOARD_FEBRUARY')}</option>
              <option value='3'>{t('DASHBOARD_MARCH')}</option>
              <option value='4'>{t('DASHBOARD_APRIL')}</option>
              <option value='5'>{t('DASHBOARD_MAY')}</option>
              <option value='6'>{t('DASHBOARD_JUNE')}</option>
              <option value='7'>{t('DASHBOARD_JULY')}</option>
              <option value='8'>{t('DASHBOARD_AUGUST')}</option>
              <option value='9'>{t('DASHBOARD_SEPTEMBER')}</option>
              <option value='10'>{t('DASHBOARD_OCTOBER')}</option>
              <option value='11'>{t('DASHBOARD_NOVEMBER')}</option>
              <option value='12'>{t('DASHBOARD_DECEMBER')}</option>
            </select>
          </div>
          <div className='flex items-center ml-2 sm:mb-0'>
            <button
              type='button'
              onClick={handleResetGraph}
              className='bg-LightBlue text-sm px-8 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
            >
              {t('O_RESET')}
            </button>
          </div>
        </div>

        <div className='sale_report grid grid-cols-2 gap-5 mb-7'>
          {(user?.permission?.[1]?.view || user?.role === 'admin') && (
            <div className='bg-white p-8 rounded-lg'>
              <div className='flex justify-between'>
                <h4 className='font-medium text-lg'>{t('O_USERS')}</h4>
              </div>
              <Line options={lineGraphOptions} data={lineGraphData} />
            </div>
          )}
          {(user?.permission?.[1]?.view || user?.role === 'admin') && (
            <div className='bg-white p-8 rounded-lg'>
              <h4 className='font-medium text-lg'>{t('DASHBOARD_SALES')}</h4>
              <Line options={lineGraphOptions} data={lineGraphData2} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Home
