import React, { useEffect, useState } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import ErrorMessage from 'components/ErrorMessage'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import { validateFile } from 'utils/reusableMethods'
import OImage from 'components/reusable/OImage'
import NoImage from '../../assets/images/noImage.jpg'

const UserEdit = ({ setEditShowModal, getAllUser, item }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      first_name: item?.first_name,
      last_name: item?.last_name,
      mobile: item?.mobile,
      email: item?.email,
      country_code: item?.country_code,
      userType: item?.userType,
      niptNo: item?.niptNo,
      NID: item?.NID,
      businessName: item?.businessName,
      profile_pic: item?.profile_pic ?? false,
      identityCardFront: item?.identityCardFront ?? false,
      identityCardBack: item?.identityCardBack ?? false,
      businessExtract: item?.businessExtract ?? false,
      businessLicense: item?.businessLicense ?? false
    }
  })

  const [cat, setCat] = useState(item?.userType)
  const [fileErrors, setFileErrors] = useState({
    identityCardFront: '',
    passport: '',
    identityCardBack: '',
    businessExtract: '',
    businessLicense: '',
    profile_pic: ''
  })

  useEffect(() => {
    if (
      watch('identityCardFront')?.length > 0 &&
      typeof watch('identityCardFront') === 'object'
    ) {
      const identityCardMsg = validateFile(watch('identityCardFront')[0])
      const fileSize = (watch('identityCardFront')[0].size / 1048576).toFixed(2)
      if (identityCardMsg) {
        setFileErrors({
          ...fileErrors,
          identityCardFront: identityCardMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          identityCardFront: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          identityCardFront: ''
        })
      }
    }
  }, [watch('identityCardFront')])

  useEffect(() => {
    if (
      watch('identityCardBack')?.length > 0 &&
      typeof watch('identityCardBack') === 'object'
    ) {
      const identityCardMsg = validateFile(watch('identityCardBack')[0])
      const fileSize = (watch('identityCardBack')[0].size / 1048576).toFixed(2)
      if (identityCardMsg) {
        setFileErrors({
          ...fileErrors,
          identityCardBack: identityCardMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          identityCardBack: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          identityCardBack: ''
        })
      }
    }
  }, [watch('identityCardBack')])

  useEffect(() => {
    if (
      watch('businessExtract')?.length > 0 &&
      typeof watch('businessExtract') === 'object'
    ) {
      const identityCardMsg = validateFile(watch('businessExtract')[0])
      const fileSize = (watch('businessExtract')[0].size / 1048576).toFixed(2)
      if (identityCardMsg) {
        setFileErrors({
          ...fileErrors,
          businessExtract: identityCardMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          businessExtract: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          businessExtract: ''
        })
      }
    }
  }, [watch('businessExtract')])

  useEffect(() => {
    if (
      watch('businessLicense')?.length > 0 &&
      typeof watch('businessLicense') === 'object'
    ) {
      const identityCardMsg = validateFile(watch('businessLicense')[0])
      const fileSize = (watch('businessLicense')[0].size / 1048576).toFixed(2)
      if (identityCardMsg) {
        setFileErrors({
          ...fileErrors,
          businessLicense: identityCardMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          businessLicense: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          businessLicense: ''
        })
      }
    }
  }, [watch('businessLicense')])

  useEffect(() => {
    if (
      watch('profile_pic')?.length > 0 &&
      typeof watch('profile_pic') === 'object'
    ) {
      const identityCardMsg = validateFile(watch('profile_pic')[0])
      const fileSize = (watch('profile_pic')[0].size / 1048576).toFixed(2)
      if (identityCardMsg) {
        setFileErrors({
          ...fileErrors,
          profile_pic: identityCardMsg
        })
      } else if (fileSize > 3) {
        setFileErrors({
          ...fileErrors,
          profile_pic: t('FILE_SIZE_ERROR')
        })
      } else {
        setFileErrors({
          ...fileErrors,
          profile_pic: ''
        })
      }
    }
  }, [watch('profile_pic')])

  const onSubmit = async data => {
    try {
      if (
        cat === 'business' &&
        (fileErrors.identityCardFront ||
          fileErrors.identityCardBack ||
          fileErrors.businessLicense ||
          fileErrors.businessExtract)
      ) {
        return
      } else if (
        (cat === 'individual' && fileErrors.identityCardFront) ||
        fileErrors.identityCardBack
      ) {
        return
      }
      const formData = new FormData()
      setIsLoading(true)
      if (cat === 'business') {
        if (typeof watch('identityCardFront') === 'object') {
          formData.append('identityCardFront', data.identityCardFront[0])
        }
        if (typeof watch('identityCardBack') === 'object')
          formData.append('identityCardBack', data.identityCardBack[0])
        if (typeof watch('businessLicense') === 'object')
          formData.append('businessLicense', data.businessLicense[0])

        if (typeof watch('businessExtract') === 'object')
          formData.append('businessExtract', data.businessExtract[0])
        formData.append('niptNo', data.niptNo)
      }
      if (cat === 'individual') {
        if (typeof watch('identityCardFront') === 'object')
          formData.append('identityCardFront', data.identityCardFront[0])
        if (typeof watch('identityCardBack') === 'object')
          formData.append('identityCardBack', data.identityCardBack[0])
        formData.append('NID', data.NID)
      }
      if (typeof watch('profile_pic') === 'object')
        formData.append('profile_pic', data.profile_pic[0])
      formData.append('first_name', data.first_name)
      formData.append('userType', cat)
      formData.append('last_name', data.last_name)
      formData.append('mobile', data.mobile)
      formData.append('country_code', data.country_code || 355)
      formData.append('email', data.email)
      formData.append('businessName', data.businessName)

      const path = apiPath.updateUser + '/' + item._id
      const result = await apiPut(path, formData)
      if (result.data.success) {
        getAllUser()
        setEditShowModal(false)
        notification.success(result?.data.message)
      } else {
        notification.error(result?.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error)
      notification.error(error.message)
    }
    setIsLoading(false)
  }

  const selectCategory = e => {
    if (e.target.value) {
      setCat(e.target.value)
      clearErrors('category_id')
    } else {
      setCat('')
      setError('category_id', {
        type: 'validation',
        message: 'Please select category.'
      })
    }
  }

  const codeValue = watch('email') ? watch('email') : ''
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto'>
          <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
            <div
              className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none lg:min-w-[1000px]'
              style={{ maxHeight: '80vh', overflowY: 'scroll' }}
            >
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>{t('O_EDIT_USER')}</h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setEditShowModal(false)}
                >
                  <span className=' text-[#B8BBBF]  text-4xl ' title='Close'>
                    ×
                  </span>
                </button>
              </div>
              <div className='relative p-6 flex-auto'>
                <div className='grid grid-cols-3'>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0  w-full group'
                      name='first_name'
                      inputLabel={
                        <>
                          {t('MERCHANT_FIRST_NAME')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      autoFocus
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register(
                        'first_name',
                        formValidation['first_name']
                      )}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='last_name'
                      inputLabel={
                        <>
                          {t('MERCHANT_LAST_NAME')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      maxLength={15}
                      onInput={e => preventMaxInput(e, 15)}
                      register={register(
                        'last_name',
                        formValidation['last_name']
                      )}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      name='country_code'
                      inputLabel={<>{t('O_COUNTRY_CODE')}</>}
                      errors={errors}
                      type='select'
                      register={register('country_code', {})}
                      selectOptions={[
                        <option value='' key='select_code'>
                          {t('O_SELECT_CODE')}
                        </option>,
                        <option value='355' key='355'>
                          +355
                        </option>
                      ]}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='number'
                      name='mobile'
                      id='mobile'
                      inputLabel={
                        <>
                          {t('O_MOBILE_NUMBER')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      min={0}
                      onKeyDown={e => {
                        if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => preventMaxInput(e, 9)}
                      register={register('mobile', formValidation['mobile'])}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <OInputField
                      wrapperClassName='relative z-0   w-full group'
                      type='text'
                      name='email'
                      id='email'
                      inputLabel={
                        <>
                          {t('O_EMAIL_ID')}
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      value={codeValue.toLowerCase()}
                      maxLength={50}
                      autoComplete='off'
                      onInput={e => preventMaxInput(e, 50)}
                      register={register('email', formValidation['email'])}
                      errors={errors}
                    />
                  </div>
                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <select
                        type='select'
                        id='userType'
                        className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                        placeholder=' '
                        value={cat}
                        {...register('userType', {
                          required: t('PLEASE_SELECT_USER_TYPE')
                        })}
                        onChange={selectCategory}
                      >
                        <option value='individual'>{t('O_INDIVIDUAL')}</option>
                        <option value='business'>{t('BUSINESS')}</option>
                      </select>
                      <label
                        htmlFor='category'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {' '}
                        {t('OFFERS_USER_TYPE')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <ErrorMessage message={errors?.userType?.message} />
                    </div>
                  </div>

                  <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                    <div className='relative z-0   w-full group'>
                      <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                        <input
                          type='file'
                          name='profile_pic'
                          style={{ width: '100%' }}
                          placeholder='Identity Card '
                          className='form-control'
                          accept='image/png,image/jpeg,image/jpg'
                          {...register('profile_pic', {
                            required:
                              typeof watch('profile_pic') === 'object' ||
                              watch('profile_pic') === false
                                ? t('PLEASE_UPLOAD_PICTURE')
                                : false
                          })}
                        />
                      </div>
                      <label
                        for='floating_file'
                        className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                      >
                        {t('PROFILE_PICTURE')}
                        <span className='text-red-500'>*</span>
                      </label>
                      <ErrorMessage
                        message={
                          errors?.profile_pic?.message || fileErrors.profile_pic
                        }
                      />
                    </div>
                    {
                      <OImage
                        src={
                          typeof watch('profile_pic') === 'object'
                            ? URL.createObjectURL(watch('profile_pic')[0])
                            : item?.profile_pic
                        }
                        fallbackUrl={NoImage}
                        className='w-20 h-20'
                      />
                    }
                  </div>

                  {cat === 'individual' ? (
                    <>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          type='text'
                          name='NID'
                          id='NID'
                          inputLabel={
                            <>
                              {t('NID')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          maxLength={40}
                          onInput={e => preventMaxInput(e, 50)}
                          register={register('NID')}
                          errors={errors}
                        />
                      </div>

                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='identityCardFront'
                              placeholder='Identity Card '
                              className='form-control'
                              style={{ width: '100%' }}
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('identityCardFront', {
                                required:
                                  typeof watch('identityCardFront') ===
                                    'object' ||
                                  watch('identityCardFront') === false
                                    ? t(
                                        'PLEASE_POROVIDE_FRONT_SIDE_OF_IDENTITY_CARD'
                                      )
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_IDENTITY_CARD_FRONT')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.identityCardFront?.message ||
                              fileErrors.identityCardFront
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('identityCardFront') === 'object'
                                ? URL.createObjectURL(
                                    watch('identityCardFront')[0]
                                  )
                                : item?.identityCardFront
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>

                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='identityCardBack'
                              placeholder='Identity Card '
                              style={{ width: '100%' }}
                              className='form-control'
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('identityCardBack', {
                                required:
                                  typeof watch('identityCardBack') ===
                                    'object' ||
                                  watch('identityCardBack') === false
                                    ? t(
                                        'PLEASE_POROVIDE_BACK_SIDE_OF_IDENTITY_CARD'
                                      )
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_IDENTITY_CARD_BACK')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.identityCardBack?.message ||
                              fileErrors.identityCardBack
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('identityCardBack') === 'object'
                                ? URL.createObjectURL(
                                    watch('identityCardBack')[0]
                                  )
                                : item?.identityCardBack
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>
                    </>
                  ) : cat === 'business' ? (
                    <>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          type='number'
                          name='niptNo'
                          id='niptNo'
                          inputLabel={
                            <>
                              {t('NIPT_NO')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          maxLength={15}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register('niptNo')}
                          errors={errors}
                        />
                      </div>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          name='businessName'
                          inputLabel={
                            <>
                              {t('MERCHANT_BUSINESS_NAME')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          type='text'
                          maxLength={15}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register(
                            'businessName',
                            formValidation['business_name']
                          )}
                          errors={errors}
                        />
                      </div>

                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='identityCardFront'
                              placeholder='Identity Card '
                              style={{ width: '100%' }}
                              className='form-control'
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('identityCardFront', {
                                required:
                                  typeof watch('identityCardFront') ===
                                    'object' ||
                                  watch('identityCardFront') === false
                                    ? t(
                                        'PLEASE_POROVIDE_FRONT_SIDE_OF_IDENTITY_CARD'
                                      )
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_IDENTITY_CARD_FRONT')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.identityCardFront?.message ||
                              fileErrors.identityCardFront
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('identityCardFront') === 'object'
                                ? URL.createObjectURL(
                                    watch('identityCardFront')[0]
                                  )
                                : item?.identityCardFront
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='identityCardBack'
                              placeholder='Identity Card '
                              style={{ width: '100%' }}
                              className='form-control'
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('identityCardBack', {
                                required:
                                  typeof watch('identityCardBack') ===
                                    'object' ||
                                  watch('identityCardBack') === false
                                    ? t(
                                        'PLEASE_POROVIDE_BACK_SIDE_OF_IDENTITY_CARD'
                                      )
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_IDENTITY_CARD_BACK')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.identityCardBack?.message ||
                              fileErrors.identityCardBack
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('identityCardBack') === 'object'
                                ? URL.createObjectURL(
                                    watch('identityCardBack')[0]
                                  )
                                : item?.identityCardBack
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='businessExtract'
                              placeholder='Identity Card '
                              style={{ width: '100%' }}
                              className='form-control'
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('businessExtract', {
                                required:
                                  typeof watch('businessExtract') ===
                                    'object' ||
                                  watch('businessExtract') === false
                                    ? t('PLEASE_UPLOAD_BUSINESS_EXTRACT')
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_BUSINESS_EXTRACT')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.businessExtract?.message ||
                              fileErrors.businessExtract
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('businessExtract') === 'object'
                                ? URL.createObjectURL(
                                    watch('businessExtract')[0]
                                  )
                                : item?.businessExtract
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>
                      <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
                        <div className='relative z-0   w-full group'>
                          <div className='block py-3 h-14 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'>
                            <input
                              type='file'
                              name='businessLicense'
                              placeholder='Identity Card '
                              style={{ width: '100%' }}
                              className='form-control'
                              accept='image/png,image/jpeg,image/jpg'
                              {...register('businessLicense', {
                                required:
                                  typeof watch('businessLicense') ===
                                    'object' ||
                                  watch('businessLicense') === false
                                    ? t('PLEASE_UPLOAD_BUSINESS_LICENSE')
                                    : false
                              })}
                            />
                          </div>
                          <label
                            for='floating_file'
                            className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-0 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          >
                            {t('O_BUSINESS_LICENSE')}
                            <span className='text-red-500'>*</span>
                          </label>
                          <ErrorMessage
                            message={
                              errors?.businessLicense?.message ||
                              fileErrors.businessLicense
                            }
                          />
                        </div>
                        {
                          <OImage
                            src={
                              typeof watch('businessLicense') === 'object'
                                ? URL.createObjectURL(
                                    watch('businessLicense')[0]
                                  )
                                : item?.businessLicense
                            }
                            fallbackUrl={NoImage}
                            className='w-20 h-20'
                          />
                        }
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setEditShowModal(false)}
                >
                  {t('O_BACK')}
                </button>

                {isLoading ? (
                  <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                    <div className='loading-spinner'></div>
                  </div>
                ) : (
                  <button
                    className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('O_EDIT')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default UserEdit
