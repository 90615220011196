import React from 'react'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { BsArrowUpShort } from 'react-icons/bs'

const Table = ({ walletTransactions, page, sort, setSort }) => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>

              <th scope='col' className='py-3 px-6'>
                <div className='text-left'>
                  <span>{t('WALLET_TRANSACTION_ID')} </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6'>
                <div className='flex align-left'>
                  <span>{t('O_FROM_NAME_MOBILE_USER_TYPE')} </span>
                </div>
              </th>

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'transaction_amount' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'transaction_amount',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'transaction_amount',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className=''>
                  <span className='flex justify-start'>
                    {t('WALLET_TRANSACTION_AMOUNT')}
                    {sort.sort_key === 'transaction_amount' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'transaction_amount' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6'>
                <div className='flex align-left'>
                  <span>{t('O_TRANSACTION_TYPE')} </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6'>
                <div className='flex align-left'>
                  <span>{t('O_TYPE')} </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6'>
                <div className='flex'>
                  <span>{t('O_TO_NAME_MOBILE_USER_TYPE')} </span>
                </div>
              </th>

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')}</span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6 text-left'>
                {t('WALLET_CURRENCY')}
              </th>
            </tr>
          </thead>
          <tbody>
            {walletTransactions &&
              walletTransactions?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r text-left'>
                    {item?.transaction_id}{' '}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>
                    {item?.sender_data.length > 0
                      ? item?.sender_data.map(value => {
                          return (
                            value?.first_name +
                            ' ' +
                            value?.last_name +
                            ' ' +
                            `(${value?.mobile}) ` +
                            `(${item.sender_type})`
                          )
                        })
                      : ''}
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.transaction_amount?.toFixed(2) || 0}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>
                    {startCase(item?.transaction_type)}{' '}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>
                    {startCase(item?.type)}{' '}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    {item?.receiver_data.length > 0
                      ? item?.receiver_data.map(value => {
                        return (
                          value?.first_name +
                          ' ' +
                          value?.last_name +
                          ' ' +
                          `(${value?.mobile}) ` +
                          `(${item.receiver_type})`
                        )
                        })
                      : ''}
                  </td>

                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.updatedAt).format('DD-MM-YYYY h:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-r text-left'>{t('O_LEK')}</td>
                </tr>
              ))}

            {isEmpty(walletTransactions) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
