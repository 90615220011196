import React, { useState, useEffect } from 'react'
import { apiPut, apiGet } from '../../utils/apiFetch'
import { useForm } from 'react-hook-form'
import ErrorMessage from '../../components/ErrorMessage'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { validationRules } from 'utils/constants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import { isEmpty } from 'lodash'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

const OperatorEdit = ({ setEditShowModal, getAllOperators, item }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const {
    register,
    watch,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      first_name: item?.first_name,
      last_name: item?.last_name,
      business_name: "ALPAY",
      mobile: item?.mobile,
      description: item?.description,
      license: item?.license,
      countryCode: item?.country_code,
      email: item?.email,
      category: item?.categoryData?._id,
      sub_category: item?.subCategoryData?._id,
      business_address: item?.business_address
    }
  })

  const [icon, setIcon] = useState(true)
  const [passwordIcon, setPasswordIcon] = useState(true)
  const [editOperatorLoading, setEditOperatorLoading] = useState(false)
  const [recordsTemp, setRecordTemp] = useState('')
  const [listTemp, setListTemp] = useState('')
  const [cat, setCat] = useState(item?.categoryData?._id)
  const [subCat, setSubCat] = useState(item?.subCategoryData?._id)
  const [isCatError, setIsCatError] = useState(false)
  const [isSubCatError, setIsSubCatError] = useState(false)
  const [address, setAddress] = useState(item?.business_address)
  const [addressError, setAddressError] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: '',
    lng: ''
  })

  const checkFields = () => {
    let isValid = true
    if (!cat && !subCat) {
      setIsCatError(true)
      setIsSubCatError(true)
      isValid = false
    }
    if (!cat) {
      setIsCatError(true)
      isValid = false
    } else {
      setIsCatError(false)
    }
    if (!subCat) {
      setIsSubCatError(true)
      isValid = false
    } else {
      setIsSubCatError(false)
    }
    if (address === '') {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }
    return isValid
  }

  const handleSubmitForm = async data => {
    try {
      const isValid = checkFields()
      if (!isValid) return
      setEditOperatorLoading(true)
      const sendData = {
        first_name: data.first_name,
        last_name: data.last_name,
        business_name: "ALPAY",
        license: data.license,
        mobile: data.mobile,
        country_code: data.countryCode,
        email: data.email,
        description: data.description,
        category: cat,
        sub_category: subCat,
        business_address: address
      }
      if (data?.password && data?.password != '') {
        sendData.password = data.password
      }
      const res = await apiPut(pathObj.editOperator + '/' + item._id, sendData)
      if (res.data.success === true) {
        setEditShowModal(false)
        notification.success(res.data.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    } finally {
      setEditOperatorLoading(false)
      getAllOperators()
    }
  }

  function changeIcon () {
    setIcon(!icon)
  }

  function changePasswordIcon () {
    setPasswordIcon(!passwordIcon)
  }

  const getCategoryList = async payload => {
    try {
      var path = pathObj.getCategoryList
      const res = await apiGet(path, payload)
      if (res?.data?.success) {
        setRecordTemp(res?.data?.results?.category_list)
        setListTemp(res?.data?.results?.sub_category_list)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  useEffect(() => {
    getCategoryList()
  }, [])

  const preventMaxAddress = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  const handleSelect = async value => {
    const result = await geocodeByAddress(value)
    const ll = await getLatLng(result[0])
    setAddress(value)
    setCoordinates(ll)
  }

  const password = watch('password')
  useEffect(() => {
    if (!isEmpty(password)) {
      trigger('confirm_password')
    }
  }, [password, trigger])
  const codeValue = watch('email') ? watch('email') : ''

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative my-6  w-full max-w-[1150px] mx-auto'>
          <div className='sm:py-4 sm:px-2 py-8 px-7 '>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>
                  {t('MERCHANT_EDIT_OPERATOR')}
                </h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setEditShowModal(false)}
                >
                  <span
                    className=' text-[#B8BBBF]  text-4xl '
                    title={t('CLOSE')}
                  >
                    ×
                  </span>
                </button>
              </div>
              <form
                onSubmit={handleSubmit(handleSubmitForm, () => {
                  const isValid = checkFields()
                  if (!isValid) return
                })}
                method='post'
              >
                <div className='relative p-6 flex-auto'>
                  <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0 mb-6 w-full group'
                        name='first_name'
                        inputLabel={
                          <>
                            {t('MERCHANT_FIRST_NAME')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        autoFocus
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register(
                          'first_name',
                          formValidation['first_name']
                        )}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        name='last_name'
                        inputLabel={
                          <>
                            {t('MERCHANT_LAST_NAME')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        maxLength={15}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register(
                          'last_name',
                          formValidation['last_name']
                        )}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0 mb-6 w-full group'
                        type='text'
                        name='email'
                        id='email'
                        inputLabel={
                          <>
                            {t('O_EMAIL_ID')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        value={codeValue.toLowerCase()}
                        maxLength={50}
                        autoComplete='off'
                        onInput={e => preventMaxInput(e, 50)}
                        register={register('email', formValidation['email'])}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <OInputField
                          wrapperClassName='relative z-0   w-full group'
                          type={icon ? 'password' : 'text'}
                          name='password'
                          inputLabel={<>{t('O_PASSWORD')}</>}
                          errors={errors}
                          maxLength={15}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register('password', {
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.passwordMessage
                            }
                          })}
                        />
                        {icon ? (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changeIcon()}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changeIcon()}
                          >
                            <AiFillEye />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <OInputField
                          wrapperClassName='relative z-0 w-full group'
                          type={passwordIcon ? 'password' : 'text'}
                          inputLabel={<>{t('O_CONFIRM_PASSWORD')}</>}
                          name='confirm_password'
                          id='confirm_password'
                          autoComplete='new-password'
                          maxLength={15}
                          errors={errors}
                          onInput={e => preventMaxInput(e, 15)}
                          register={register('confirm_password', {
                            pattern: {
                              value: validationRules.password,
                              message: validationRules.confirmPasswordMessage
                            },
                            validate: value => {
                              const { password } = getValues()
                              return (
                                password === value ||
                                t(
                                  'PASSWORD_AND_CONFIRM_PASSWORD_DOES_NOT_MATCH'
                                )
                              )
                            }
                          })}
                        />
                        {passwordIcon ? (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changePasswordIcon()}
                          >
                            <AiFillEyeInvisible />
                          </span>
                        ) : (
                          <span
                            className='password_view absolute top-[18px] right-[20px]'
                            onClick={() => changePasswordIcon()}
                          >
                            <AiFillEye />
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='px-2 mb-4'>
                      <div className='relative z-0 w-full group bg-zinc-200'>
                        <select
                          type='select'
                          name='countryCode'
                          id='countryCode'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          {...register('countryCode', {})}
                          disabled
                        >
                          <option value='355'>355</option>
                        </select>
                        <label
                          for='countryCode'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-1 left-2 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_COUNTRY_CODE')}
                        </label>
                        <ErrorMessage message={errors?.countryCode?.message} />
                      </div>
                    </div>

                    <div className='px-2'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type='number'
                        name='mobile'
                        id='mobile'
                        inputLabel={
                          <>
                            {t('O_MOBILE_NUMBER')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        min={0}
                        onKeyDown={e => {
                          if (['-', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        onInput={e => preventMaxInput(e, 9)}
                        register={register('mobile', formValidation['mobile'])}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <select
                          type='select'
                          id='category_id'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={cat}
                          onChange={e => {
                            setCat(e.target.value)
                            if (e.target.value === '') {
                              setSubCat('')
                              setIsSubCatError(true)
                            } else {
                              setSubCat('')
                            }
                            if (e.target.value) {
                              setIsCatError(false)
                            }
                          }}
                        >
                          <option value=''>
                            {t('OFFERS_SELECT_CATEGORY')}
                          </option>
                          {recordsTemp &&
                            recordsTemp.map(item => {
                              return (
                                <option value={item?._id}>{item?.name}</option>
                              )
                            })}
                        </select>
                        <label
                          htmlFor='category'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {' '}
                          {t('OFFERS_CATEGORY')}
                          <span className='text-red-500'>*</span>
                        </label>
                        {isCatError && (
                          <ErrorMessage message={t('PLEASE_SELECT_CATEGORY')} />
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <select
                          type='select'
                          id='category_id'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          value={subCat}
                          onChange={e => {
                            setSubCat(e.target.value)
                            if (e.target.value === '') {
                              setIsSubCatError(true)
                            } else {
                              setIsSubCatError(false)
                            }
                          }}
                        >
                          <option value=''>
                            {t('OFFERS_SELECT_SUBCATEGORY')}
                          </option>
                          {listTemp?.length &&
                            listTemp?.map(
                              (i, key) =>
                                i?.category_id == cat && (
                                  <option key={key} value={i?._id}>
                                    {i?.name}
                                  </option>
                                )
                            )}
                        </select>
                        <label
                          htmlFor='category'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {' '}
                          {t('SUB_CATEGORY')}
                          <span className='text-red-500'>*</span>
                        </label>
                        {isSubCatError && (
                          <ErrorMessage
                            message={t('PLEASE_SELECT_SUB_CATEGORY')}
                          />
                        )}
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <OInputField
                        wrapperClassName='relative z-0   w-full group'
                        type='text'
                        name='license'
                        id='license'
                        inputLabel={
                          <>
                            {t('LICENSE')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        min={0}
                        onInput={e => preventMaxInput(e, 15)}
                        register={register('license', {
                          required: t('PLEASE_ENTER_SHOP_ACT_LICENSE_NUMBER'),
                          pattern: {
                            value: validationRules.removeWhitespace,
                            message: t(
                              'SHOP_ACT_NUMBER_LICENSE_SHOULD_BE_ALPHANUMERIC'
                            )
                          },
                          minLength: {
                            value: 3,
                            message: t('MINIMUM_LENGTH_MUST_BE_3')
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0   w-full group'>
                        <PlacesAutocomplete
                          value={address}
                          onChange={e => {
                            setAddress(e)
                            if (e) {
                              setAddressError(false)
                            }
                          }}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <div class='relative w-full '>
                              <OInputField
                                {...getInputProps({
                                  type: 'text',
                                  name: 'business_address',
                                  errors: errors,
                                  id: 'business_address',
                                  inputLabel: (
                                    <>
                                      {t('MERCHANT_BUSINESS_ADDRESS')}
                                      <span className='text-red-500'>*</span>
                                    </>
                                  ),
                                  onInput: e => preventMaxAddress(e)
                                })}
                                businessMargin
                              />
                              {addressError && (
                                <ErrorMessage message='Please select business address.' />
                              )}
                              <span className='absolute right-[10px] top-[10px] p-2 bg-white '>
                                {' '}
                                <img
                                  src='./images/location-outline.png'
                                  alt='location-logo'
                                />
                              </span>
                              <div className='autocomplete-dropdown-container'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item'
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer'
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer'
                                      }
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                    <div className='px-2 mb-4'>
                      <div className='relative z-0  mb-6 w-full group'>
                        <textarea
                          name='description'
                          rows='3'
                          id='description'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          maxLength={200}
                          onInput={e => preventMaxInput(e, 200)}
                          {...register('description', {
                            required: t('PLEASE_ENTER_DESCRIPTION'),
                            pattern: {
                              value: /^[^\s].*/,
                              message: t('CANNOT_START_WITH_A_SPACE')
                            },
                            maxLength: {
                              value: 200,
                              message: t('MAXIMUM_CHARACTER_CAN_BE_200')
                            },
                            minLength: {
                              value: 10,
                              message: t('MINIMUM_CHARACTER_CAN_WE_MUST_10')
                            },
                            validate: value => {
                              return value.trim()
                                ? true
                                : t('WHITE_APCE_NOT_ALLOWED')
                            }
                          })}
                        />

                        <label
                          htmlFor='description'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('OFFERS_DESCRIPTION')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.description?.message} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-black bg-[#E1E1E1] cursor-pointer font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setEditShowModal(false)}
                  >
                    {t('O_BACK')}
                  </button>

                  {editOperatorLoading ? (
                    <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                      <div className='loading-spinner'></div>
                    </div>
                  ) : (
                    <button
                      className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                    >
                      {t('O_EDIT')}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default OperatorEdit
