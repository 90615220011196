import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
const pickerClasses = [
  'cursor-pointer',
  'bg-calendar',
  'bg-[right_10px_top_10px]',
  'bg-[length:15px_15px]',
  'bg-no-repeat',
  'flex',
  'flex-row-reverse',
  'border',
  'outline-none',
  'text-gray-900',
  'text-sm',
  'rounded-lg',
  'block',
  'w-full',
  'p-2',
  'dark:bg-gray-700',
  'dark:border-gray-600',
  'dark:placeholder-gray-400',
  'dark:text-white',
  'dark:focus:ring-blue-500',
  'dark:focus:border-blue-500'
]
const Albanian = require('flatpickr/dist/l10n/sq.js').default.sq
const ODateRangePicker = props => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { language } = useContext(AuthContext)

  const onChangeStartDate = ([date]) => {
    setStartDate(date)
    props?.handleDateChange(date, endDate)
  }
  const onChangeEndDate = ([date]) => {
    setEndDate(date)
    props.handleDateChange(startDate, date)
  }
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
  }
  useEffect(() => {
    if (props?.isReset) {
      handleReset()
    }
  }, [props?.isReset])

  return (
    <>
      <div className='relative flex items-center mb-3'>
        <label className='mx-2 text-[#B8BBBF] text-xs whitespace-nowrap'>
          {t('O_FROM')}
        </label>
        <Flatpickr
          className={pickerClasses.join(' ')}
          name='start_date'
          placeholder={t('O_START_DATE')}
          value={startDate}
          options={{
            maxDate: endDate,
            dateFormat: 'd-m-Y',
            locale: language === 2 ? Albanian : undefined
          }}
          onChange={onChangeStartDate}
        />
      </div>
      <div className='relative flex items-center mb-3'>
        <label className='mx-2 text-[#B8BBBF] text-xs whitespace-nowrap'>
          {t('O_TO')}
        </label>
        <Flatpickr
          className={pickerClasses.join(' ')}
          name='end_date'
          placeholder={t('O_END_DATE')}
          value={endDate}
          options={{
            minDate: startDate,
            dateFormat: 'd-m-Y',
            locale: language === 2 ? Albanian : undefined
          }}
          onChange={onChangeEndDate}
        />
      </div>
    </>
  )
}

export default ODateRangePicker
