import React from 'react'
import Sidebar from '../components/Sidebar'
import TopNavBar from '../components/TopNavBar'
import Loader from './Loader'
import Toast from './Toast'

function MainLayout ({ children }) {
  return (
    <div className='main_wrap'>
      <Loader />
      <Toast />
      <Sidebar />
      <div className='right_panel xl:ml-[280px] lg:ml-[220px] ml-0'>
        <TopNavBar />
        {children}
      </div>
    </div>
  )
}

export default MainLayout
