import ErrorMessage from 'components/ErrorMessage'
import React, { useContext, useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormData from 'form-data'
import AuthContext from 'context/AuthContext'
import OButton from 'components/reusable/OButton'
import { AiFillCamera } from 'react-icons/ai'
import { isEmpty } from 'lodash'
import OImage from 'components/reusable/OImage'
import { useTranslation } from 'react-i18next'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import OInputField from 'components/reusable/OInputField'

const Profile = () => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, dirtyFields }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })
  const [profilePicError, setProfilePicError] = useState('')
  const [updateProfileLoading, setUpdatePrfileLoading] = useState(false)
  const [profilePic, setProfilePic] = useState(null)
  const { updateProfile, user, updatePageName } = useContext(AuthContext)
  const profilePicRef = useRef()

  useEffect(() => {
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email
    })
  }, [user])

  const handleSubmitForm = async data => {
    try {
      setUpdatePrfileLoading(true)
      const formData = new FormData()
      if (dirtyFields.first_name) {
        formData.append('first_name', data.first_name)
      }
      if (dirtyFields.last_name) {
        formData.append('last_name', data.last_name)
      }
      if (dirtyFields.email) {
        formData.append('email', data.email)
      }
      if (profilePic) {
        formData.append('profile_pic', profilePic)
      }
      reset()

      updateProfile(formData)
    } catch (err) {
      console.log('err:', err)
    } finally {
      setUpdatePrfileLoading(false)
    }
  }

  const validateFiles = profilePic => {
    let picError = ''
    const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png']

    if (!isEmpty(supportedTypes.includes(profilePic.type))) {
      picError = 'Only jpeg, jpg and png are supported.'
    }

    if (picError) {
      setProfilePicError(picError)
      return false
    } else {
      setProfilePicError('')
      return true
    }
  }

  const handleFileChange = e => {
    const image = e?.target?.files[0]
    const isValidImage = validateFiles(image)
    if (isValidImage) {
      setProfilePic(image)
    } else {
      setProfilePic(null)
    }
  }
  useEffect(() => {
    updatePageName(t('EDIT_PROFILE'))
  }, [])
  const codeValue = watch('email') ? watch('email') : ''
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='px-3 py-4'>
        <div className='bg-white border border-[#E9EDF9] rounded-lg py-4 '>
          <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2'>
              <div className='py-4 px-4 md:px-8'>
                <div className='relative'>
                  <OInputField
                    wrapperClassName='relative z-0 mb-6 w-full group'
                    name='first_name'
                    inputLabel={
                      <>
                        {t('O_FIRST_NAME')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    type='text'
                    autoFocus
                    maxLength={15}
                    onInput={e => preventMaxInput(e, 15)}
                    register={register(
                      'first_name',
                      formValidation['first_name']
                    )}
                    errors={errors}
                  />
                </div>
              </div>
              <div className='py-4 px-4 md:px-8'>
                <div className='relative'>
                  <OInputField
                    wrapperClassName='relative z-0 w-full group'
                    name='last_name'
                    inputLabel={
                      <>
                        {t('O_LAST_NAME')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    type='text'
                    maxLength={15}
                    onInput={e => preventMaxInput(e, 15)}
                    register={register(
                      'last_name',
                      formValidation['last_name']
                    )}
                    errors={errors}
                  />
                </div>
              </div>
              <div className='py-4 px-4 md:px-8'>
                <div className='relative'>
                  <OInputField
                    wrapperClassName='relative z-0  mb-6 w-full group'
                    type='text'
                    name='email'
                    id='email'
                    value={codeValue.toLowerCase()}
                    inputLabel={
                      <>
                        {t('O_EMAIL_ID')}
                        <span className='text-red-500'>*</span>
                      </>
                    }
                    maxLength={50}
                    autoComplete='off'
                    onInput={e => preventMaxInput(e, 50)}
                    register={register('email', formValidation['email'])}
                    errors={errors}
                  />
                </div>
              </div>

              <div>
                <div className='py-4 flex justify-center'>
                  <div className='relative w-24 h-24 '>
                    <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white w-28'>
                      {t('PROFILE_PICTURE')}
                    </label>
                    <input
                      type='file'
                      accept='image/png, image/jpg, image/jpeg'
                      name='image'
                      ref={profilePicRef}
                      onChange={handleFileChange}
                      className='hidden'
                    />

                    <OImage
                      src={
                        profilePic
                          ? URL.createObjectURL(profilePic)
                          : user?.profile_pic
                      }
                      fallbackUrl='/images/user.png'
                      className='w-24 h-24'
                      alt=''
                      style={{ borderRadius: '50%' }}
                    />

                    {/* <span> */}
                    <AiFillCamera
                      className=' bg-gray-100  absolute w-4 rounded-xl cursor-pointer'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        bottom: '-23px',
                        right: 0,
                        background: '',
                        padding: '1px'
                      }}
                      onClick={() => profilePicRef?.current?.click()}
                    />
                    {/* </span> */}
                  </div>
                </div>
                <div className='text-center mt-4'>
                  <ErrorMessage message={profilePicError} />
                </div>
              </div>

              {/* profile pic ends here  */}
            </div>
            <div className='flex justify-center mt-4'>
              <OButton
                disabled={!isDirty && profilePic == null}
                label='Submit'
                type='submit'
                loading={updateProfileLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Profile
