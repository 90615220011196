import React, { useContext } from 'react'
import { apiPut, apiDelete } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { FaPassport } from 'react-icons/fa'
import {
  AiFillIdcard,
  AiFillEdit,
  AiFillDelete,
  AiFillEye
} from 'react-icons/ai'
import useToastContext from 'hooks/useToastContext'
import helper from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { IoIosBusiness } from 'react-icons/io'
import { BsArrowUpShort } from 'react-icons/bs'
import AuthContext from 'context/AuthContext'

const OperatorTable = ({
  operators,
  getAllOperators,
  handelEdit,
  handleUserView,
  user,
  page,
  sort,
  setSort,
  setPage
}) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const { language } = useContext(AuthContext)

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.changeOperatorStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllOperators({ statusChange: 1 })
      }
      // }
    } catch (error) {
      console.log('error in get all operators list==>>>>', error.message)
    }
  }

  const handelDelete = async item => {
    let result
    try {
      const path = apiPath.deleteOperator + '/' + item?._id
      result = await apiDelete(path)
      if (result?.status === 200) {
        setPage(1)
        getAllOperators()
        notification.success(result.data.message)
      }
    } catch (error) {
      console.log('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'first_name' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'first_name',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'first_name',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_SEARCH_BY_NAME')} </span>
                  <span>
                    {sort.sort_key === 'first_name' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'first_name' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (sort.sort_key === 'email' && sort.sort_type === 'asc') {
                    setSort({
                      sort_key: 'email',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'email',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex'>
                  <span>{t('O_EMAIL_ID')} </span>
                  <span>
                    {sort.sort_key === 'email' && sort.sort_type === 'asc' && (
                      <BsArrowUpShort className='w-4 h-4' />
                    )}
                    {sort.sort_key === 'email' && sort.sort_type === 'desc' && (
                      <BsArrowUpShort className='w-4 h-4 rotate-180' />
                    )}
                  </span>
                </div>
              </th>

              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')} (+355)
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('MERCHANT_DOCUMENTS')}
              </th>

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (
                    sort.sort_key === 'createdAt' &&
                    sort.sort_type === 'asc'
                  ) {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'desc'
                    })
                  } else {
                    setSort({
                      sort_key: 'createdAt',
                      sort_type: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('O_CREATED_AT')} </span>
                  <span>
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sort_key === 'createdAt' &&
                      sort.sort_type === 'desc' && (
                        <BsArrowUpShort className='w-4 h-4 rotate-180' />
                      )}
                  </span>
                </div>
              </th>

              {(user?.permission?.[7]?.add ||
                user?.permission?.length === 0) && (
                <th scope='col' className='py-3 px-6 text-left'>
                  {t('O_STATUS')}
                </th>
              )}

              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {operators &&
              operators?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r'>{item?.first_name} {item?.last_name} </td>
                  <td className='py-4 px-6 border-r'>{item?.email}</td>
                  <td className='py-4 px-6 border-r text-right'>
                    {item?.mobile}
                  </td>
                  <td className='py-4 px-6 border-r flex justify-center'>
                    <a
                      href={item?.passport}
                      title={t('O_PASSPORT_ICON')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaPassport className='h-7 w-7' />
                    </a>
                    <a
                      href={item?.identity_card}
                      title={t('MERCHANT_ID_CARD')}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <AiFillIdcard className='h-7 ml-2 w-7' />
                    </a>
                  </td>
                  <td className='py-4 px-6 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}{' '}
                  </td>
                  {(user?.permission?.[7]?.add ||
                    user?.permission?.length === 0) && (
                    <td className='py-4 px-6 border-r text-center'>
                      <label
                        className='inline-flex relative items-center cursor-pointer'
                        title={`${
                          item?.status === 'active'
                            ? t('O_ACTIVE')
                            : t('O_INACTIVE')
                        }`}
                      >
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={item?.status === 'active'}
                          onChange={e =>
                            helper.alertFunction(
                              `${t('ARE_YOU_SURE_YOU_WANT_TO')} ${
                                e.target.checked ? t('ACTIVE') : t('INACTIVE')
                              } "${item.first_name} ${item.last_name}"?`,
                              item,
                              handelStatusChange,
                              '',
                              language
                            )
                          }
                        />
                        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                      </label>
                    </td>
                  )}
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(user?.permission?.[1]?.view ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handleUserView(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('VIEW_OPERATOR')}>
                              {' '}
                              <AiFillEye className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                            </a>
                          </li>
                        )}
                        {(user?.permission?.[7]?.add ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => handelEdit(item)}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('EDIT_OPERATOR')}>
                              {' '}
                              <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                            </a>
                          </li>
                        )}
                        {(user?.permission?.[7]?.delete ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={e =>
                              helper.alertFunction(
                                `${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} "${
                                  item.first_name} ${item.last_name}
                                }"?`,
                                item,
                                handelDelete,
                                true,
                                language
                              )
                            }
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue'
                          >
                            <a title={t('DELETE_OPERATOR')}>
                              {' '}
                              <AiFillDelete className='cursor-pointer w-5 h-5 text-red-600' />{' '}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(operators) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OperatorTable
