import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPut } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import OInputField from 'components/reusable/OInputField'

const MicroCreditRequestSetting = () => {
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })
  const [settingChangeLoading, setsSettingChangeLoading] = useState(false)
  const notification = useToastContext()

  const handleSubmitForm = async data => {
    try {
      setsSettingChangeLoading(true)
      data.conversion_rate = parseInt(data.conversion_rate)
      const res = await apiPut(pathObj.updateMcrSettings, data)
      if (res.data.success === true) {
        getSettings()
        notification.success(res?.data?.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.log('err:', err)
    }
    setsSettingChangeLoading(false)
  }

  const getSettings = async () => {
    try {
      const res = await apiGet(pathObj.getMcrSettings)
      if (res) {
        reset(res?.data?.results)
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  const preventMaxHundred = e => {
    if (e.target.value > 100) {
      e.target.value = e.target.value.slice(0, 2)
    }
  }

  const preventMaxHundredDays = e => {
    if (e.target.value > 100) {
      e.target.value = e.target.value.slice(0, 1000)
    }
  }

  const preventMax = e => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
  }

  useEffect(() => {
    updatePageName(t('NAV_MICRO_CREDIT_REQUEST_SETTING'))
  }, [])
  var pressedKeys = []

  return (
    <section className=''>
      <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
        <section className='sm:px-8 px-4 py-4'>
          <div className='border xl:w-full round'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>
                {t('MICRO_SETTINGS_INTEREST_RATE')}
              </div>
            </header>
            <div className='bg-white py-6  rounded-b-md'>
              <main className='justify-center flex flex-wrap xl:[&>*]:mr-14 sm:[&>*]:mr-7 2xl:[&>*]:mr-14  sm:px-0 px-4 xl:[&>*]:w-3/12 sm:[&>*]:w-2/5 '>
                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('MICRO_SETTINGS_90_DAYS')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='number'
                      step='any'
                      name='nine_zero_interest_rate'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      id='nine_zero_interest_rate'
                      register={register('nine_zero_interest_rate', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_90_DAYS_INTEREST')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>

                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('MICRO_SETTINGS_180_DAYS')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      id='one_eight_zero_interest_rate'
                      type='number'
                      name='one_eight_zero_interest_rate'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('one_eight_zero_interest_rate', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_180_DAYS_INTEREST')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4   w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('MICRO_SETTINGS_365_DAYS')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      id='three_six_five_interest_rate'
                      type='number'
                      name='three_six_five_interest_rate'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('three_six_five_interest_rate', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_365_DAYS_INTEREST')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('MICRO_PROCESSING_FEE')} ({t('O_LEK')})
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      name='processingFee'
                      id='processingFee'
                      onInput={e => preventMax(e)}
                      maxLength={10}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      register={register('processingFee', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_PROCESSING_FEE')
                        },
                        maxLength: {
                          value: 10,
                          message: t('MAXIMUM_LENGTH_IS_10_CHARACTER')
                        },
                        pattern: {
                          value: true,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full' />
                <div className='mb-4 w-full' />
              </main>
            </div>
          </div>

          <div className='border xl:w-full rounded mt-5'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
              <div className='font-semibold'>
                {t('MICRO_SETTINGS_PENALTY_PERCENTAGE')}
              </div>
            </header>
            <div className='bg-white py-6  rounded-b-md'>
              <main className='justify-center flex flex-wrap xl:[&>*]:mr-14 sm:[&>*]:mr-7 2xl:[&>*]:mr-14  sm:px-0 px-4 xl:[&>*]:w-3/12 sm:[&>*]:w-2/5 '>
                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      errors={errors}
                      inputLabel={
                        <>
                          {t('MICRO_SETTINGS_DAYS')} ({t('MICRO_SETTINGS_DAYS')}
                          )<span className='text-red-500'>*</span>
                        </>
                      }
                      type='text'
                      name='penalty_days'
                      onInput={e => preventMaxHundredDays(e)}
                      maxLength={2}
                      onKeyDown={event => {
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
                          !/[0-9]/.test(event.key)
                        ) {
                          event.preventDefault()
                        }
                      }}
                      id='penalty_days'
                      register={register('penalty_days', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_DAYS')
                        },
                        pattern: {
                          value: true,
                          message: t('ONIT_DIGITS_ARE_ALLOWED')
                        },
                        max: {
                          value: 30,
                          message: t('MAXIMUM_LIMIT_IS_30_DAYS')
                        },
                        min: {
                          value: 1,
                          message: t('MINIMUM_VALUE_IS_MUST_1')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4  w-full'>
                  <div className='relative'>
                    <OInputField
                      wrapperClassName='relative z-0 w-full group'
                      inputLabel={
                        <>
                          {t('MICRO_SETTINGS_PENALTY')} (%)
                          <span className='text-red-500'>*</span>
                        </>
                      }
                      errors={errors}
                      id='penalty_percentage'
                      name='penalty_percentage'
                      type='number'
                      step='any'
                      onInput={e => preventMaxHundred(e)}
                      onKeyDown={e => {
                        pressedKeys.push(e.key)
                        var lastKey = pressedKeys[pressedKeys.length - 2]
                        if (lastKey == '.') {
                          if (['-', '.', '+', 'e'].includes(e.key)) {
                            e.preventDefault()
                          }
                        } else if (['-', '+', 'e'].includes(e.key)) {
                          e.preventDefault()
                        }
                        if (
                          !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                          e.target.value?.split('.')[1]?.length >= 2
                        ) {
                          e.preventDefault()
                        }
                      }}
                      register={register('penalty_percentage', {
                        required: {
                          value: true,
                          message: t('PLEASE_ENTER_PENALTY')
                        },
                        min: {
                          value: 0.01,
                          message: t('MINIMUM_VALUE_MUST_BE_0.01')
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='mb-4 w-full' />
              </main>
            </div>
          </div>
        </section>

        <div className='mt-4 text-center'>
          {settingChangeLoading &&
          (user?.permission?.[13]?.add || user.permission?.length === 0) ? (
            <div
              className='max-w-[100px] block spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
              style={{ margin: '0 auto' }}
            >
              <div className='loading-spinner'></div>
            </div>
          ) : (
            <button
              disabled={!isDirty}
              className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
              type='submit'
            >
              {t('O_UPDATE')}
            </button>
          )}
        </div>
      </form>
    </section>
  )
}

export default MicroCreditRequestSetting
