import React, { useContext, useEffect, useState } from 'react'
import Table from './Table'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import pathObj from 'utils/pathObj'
import dayjs from 'dayjs'
import { apiGet } from 'utils/apiFetch'
import AuthContext from 'context/AuthContext'
import Pagination from '../Pagination'
import CountUp from 'react-countup'

function EarningManager () {
  const { t } = useTranslation()
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({
    sort_key: 'createdAt',
    sort_type: 'desc'
  })

  const [isReset, setIsReset] = useState(false)

  const [earningManager, setEarningManager] = useState([])
  const [totalEarning, setTotalEarning] = useState(0)

  const [earningState, setEarningState] = useState({
    startDate: '',
    endDate: '',
    userType: ''
  })

  const { userType, startDate, endDate } = earningState
  const getEarning = async () => {
    try {
      const payload = {
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        userType,
        page,
        sort_key: sort.sort_key,
        sort_type: sort.sort_type
      }
      const path = pathObj.getEarnings
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success

        setEarningManager(response?.docs)
        setTotalEarning(response?.totalEarning)
        setPaginationObj({
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.log('getWalletTransactions error :', error)
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }
  useEffect(() => {
    getEarning()
  }, [page, userType, endDate, startDate, sort])

  const handleDateChange = (start, end) => {
    setEarningState({ ...earningState, startDate: start, endDate: end })
    setPage(1)
    setIsReset(false)
  }

  const transactionTypeOptions = [
    {
      key: <>{t('OFFERS_USER')}</>,
      value: 'user'
    },
    {
      key: <>{t('O_MERCHANT')}</>,
      value: 'merchant'
    }
  ]
  const statusPage = e => {
    setEarningState({ ...earningState, userType: e.target.value })
    setPage(1)
    setIsReset(false)
  }

  const handleReset = () => {
    setEarningState({
      ...earningState,
      startDate: '',
      endDate: '',
      userType: ''
    })
    setPage(1)
    setIsReset(true)
  }
  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    updatePageName(t('NAV_EARNING_MANAGER'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg'>
            {/* filter section */}
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='col-span-2 flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 flex-wrap mb-2'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={isReset}
                    setIsReset={setIsReset}
                  />
                  <div className='flex items-center  ml-3 mb-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 rounded-lg border-[#DFDFDF]  dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 md:max-w-[200px]  peer'
                      placeholder=' '
                      value={earningState.userType}
                      onChange={e => statusPage(e)}
                    >
                      <option value=''>{t('SELECT_USER_TYPE')}</option>
                      {transactionTypeOptions.map(({ key, value }) => (
                        <option key={key} value={value}>
                          {key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type='button'
                    onClick={handleReset}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center'>
                <label
                  for='default-search'
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                >
                  {t('O_SEARCH')}
                </label>
                <button
                  type='button'
                  className=' text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent hover:bg-DarkBlue sm:w-auto w-1/2'
                  style={{ background: '#e7e7e7', color: '#000' }}
                >
                  {t('TOTAL_EARNING')}: {t('O_LEK')}{' '}
                  <CountUp duration={0.6} decimals={2} end={totalEarning} />
                </button>
              </div>
            </form>
            <Table
              earningManager={earningManager}
              page={page}
              setSort={setSort}
              sort={sort}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarningManager
